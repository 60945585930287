import React,{useState,useEffect} from 'react';
import "../Styles/User/Dashboard.css";
import "../Styles/User/Responsive.css";
import logo from "../images/hpc-logo-alt.svg";
import * as jQuery from "jquery";
import Swal from 'sweetalert2';
// import logo from "../images/hpc-logo-alt.svg";
import {useNavigate} from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';


const Login = () => {
    const [loading, setLoading] = useState(false); // New state to handle loading
    jQuery(document).ready(function ($) {
        console.log("Custom JS loaded!");
        
        if($(".passeye").length > 0) {
            $(".passeye").on("click", function(e){
                e.preventDefault();
    
                var targetPassEl = $(this).parents(".input-group").find("input");
                
                if(targetPassEl.attr("type") == "password") {
                    targetPassEl.attr("type", "text");
                } else {
                    targetPassEl.attr("type", "password");
                }
    
                $(this).find(".passeye-icon").toggleClass("passeye-hide");
    
                $(targetPassEl).focus();
            })
        }
    
        //Login Signup Toggle
        if($(".tabs-link").length > 0) {
            $(".tabs-link").on("click", function(e){
                e.preventDefault();
                var getTabHash = $(this).attr("href");
    
                if(!$(getTabHash).is(":visible")) {
                    $(".tabs-link").removeClass("active");
                    $(this).addClass("active");
    
                    $(".panels-item").hide().removeClass("active");
                    $(getTabHash).fadeIn(200).addClass("active");
                }
            });
        }
    });


    const navigate=useNavigate();




    const [login, setLogin] = useState({
        userName:"",
        password:""
    })

    const handleChange=(e)=>{
        const newdata={...login};
        newdata[e.target.name]=e.target.value;
        setLogin(newdata);
        console.log(newdata);
    }

    const handleSubmit=(e)=>{
        e.preventDefault();
        setLoading(true); // Activate loader on button click

        const loginUrl=`https://84ro5mdxeg.execute-api.us-east-2.amazonaws.com/prod/admin?login`;

        fetch(loginUrl,{
            method:"POST",
            headers:{
              Accept: "application/json",
              'Content-Type': 'application/json'
            },
            body: JSON.stringify(login)
            }).then((res)=>res.json())
            .then((loginRes)=>{
                console.log(loginRes.Data);
                setLoading(false); // Deactivate loader when the response is received
                localStorage.setItem("adminID", loginRes.Data.id);
                localStorage.setItem("UID", loginRes.Data.deviceID);
                if(loginRes.Status===200){
                    Swal.fire({
                        icon:"success",
                        title:"Logged-in successfully!"
                    })

                    if(loginRes.Data.isAdmin=="1"){
                        navigate("/admin")
                    }else{
                        navigate("/dashboard")

                    }
                }else{
                    Swal.fire({
                        icon:"error",
                        title:"Something went wrong!"
                    })
                }
            })
    }


    const [signup, setSignup] = useState({
        userName:"",
        loginID:"",
        password:"",
        wifissid: "",
        wifipsk: "",
        deviceID: "",
        status:1
    })


    const handleChangeASignup=(e)=>{
        const newdata={...signup};
        newdata[e.target.name]=e.target.value;
        setSignup(newdata);
        
        let s4 = () => {
            return Math.floor((1 + Math.random()) * 0x10000)
                .toString(16)
                .substring(1);
          }
          
          console.log(s4());
          setSignup((pre)=>{
              return{
                  ...pre,
                  loginID:s4()
                }
            })
            //282b
            console.log(signup);
    }



    const handleSignupSubmit=(e)=>{
        e.preventDefault();
        const url=`https://84ro5mdxeg.execute-api.us-east-2.amazonaws.com/prod/admin`;
          //publish message
          const messageUrl=`https://84ro5mdxeg.execute-api.us-east-2.amazonaws.com/prod/message/publish`;

          //publish msg request obj
          const publishPayload = {
              payload_type:"init_setup",
              UID: signup.deviceID,
              WIFI_SSID:signup.wifissid,
              WIFI_PSK:signup.wifipsk,
              action:"set"     
            };
            const publishData = {
              topic: "hex/fromapp/setup/"+signup.deviceID,
              payload: JSON.stringify(publishPayload)
            };


        fetch(url,{
            method:"POST",
            headers:{
              Accept: "application/json",
              'Content-Type': 'application/json'
            },
            body: JSON.stringify(signup)
        }).then(((res)=>res.json()))
        .then((signupRes)=>{
            console.log(signupRes);
            if(signupRes.Status===200){
                Swal.fire({
                    icon:"success",
                    title:"Registered successfuly! login to continue."
                })
                setTimeout(() => {
                    window.location.reload();
                }, 2000);
            }else if(signupRes.Status===401){
                Swal.fire({
                    icon:"error",
                    title:`${signupRes.Message}`
                })
            }
            else{
                Swal.fire({
                    icon:"warning",
                    title:"Something went wrong!"
                })

            }
        })
    }

  return (
   <>
    <div class="login-row">
        <div class="login-left">
            <div class="login-leftwrap">
                <img class="login-logo" src={logo} width="322" height="500" alt="Hex Pool Cover" />
            </div>
        </div>
        <div class="login-right">
            <div class="showmob">
                <img class="login-logo" src={logo} width="322" height="500" alt="Hex Pool Cover" />
            </div>

            <div class="loginbox">
                <ul class="tabs">
                    <li class="tabs-tab">
                        <a class="tabs-link active" href="#login-form">Log In</a>
                    </li>
                    <li class="tabs-tab">
                        <a class="tabs-link" href="#signup-form">Sign Up</a>
                    </li>
                </ul>

                <div class="panels">
                    <div class="panels-item active" id="login-form">
                        <form name="login" onSubmit={handleSubmit}>
                            <div class="input-group">
                                <label for="login-user">Username</label>
                                <input class="input-control" id="login-user" type="text" name="userName" placeholder="Enter your username" onChange={handleChange}/>
                            </div>

                            <div class="input-group">
                                <label for="login-pass">Password</label>
                                <input class="input-control" id="login-pass" type="password" name="password" placeholder="Enter your password" onChange={handleChange}/>
                                <button type="button" class="passeye">
                                    <i class="passeye-icon icon-eye"></i>
                                    <i class="passeye-icon icon-alt-eye passeye-hide"></i>
                                </button>
                            </div>
                            
                            <div class="form-action">
                                <input class="bttn bttn-primary bttn-md" type="submit" name="submit" value="Log In"  disabled={loading} />
                            </div>
                        </form>
                    </div>

                    <div class="panels-item" id="signup-form">
                        <form onSubmit={handleSignupSubmit} name="signup">
                            <div class="input-group">
                                <label for="signup-user">Username</label>
                                <input class="input-control" id="signup-user" type="text" name="userName" placeholder="Enter your username" onChange={handleChangeASignup}/>
                            </div>
                            <div class="input-group">
                                <label for="signup-pass">Password</label>
                                <input class="input-control" id="signup-pass" type="password" name="password" placeholder="Enter your password" onChange={handleChangeASignup}/>
                                <button type="button" class="passeye">
                                    <i class="passeye-icon icon-eye"></i>
                                    <i class="passeye-icon icon-alt-eye passeye-hide"></i>
                                </button>
                            </div>
                            <div class="input-group">
                                <label for="signup-user">Device ID</label>
                                <input class="input-control" id="signup-deviceid" type="text" name="deviceID" placeholder="Enter Device/UID provided to you" onChange={handleChangeASignup}/>
                            </div>
                            <div class="input-group">
                                <label for="signup-user">WIFI ID</label>
                                <input class="input-control" id="signup-wifi_ssid" type="text" name="wifissid" placeholder="Enter your WIFI ID" onChange={handleChangeASignup}/>
                            </div>
                            <div class="input-group">
                                <label for="signup-user">WIFI Password</label>
                                <input class="input-control" id="signup-wifi_psk" type="password" name="wifipsk" placeholder="Enter your WIFI password" onChange={handleChangeASignup}/>
                                <button type="button" class="passeye">
                                    <i class="passeye-icon icon-eye"></i>
                                    <i class="passeye-icon icon-alt-eye passeye-hide"></i>
                                </button>
                            </div>

                            <div class="form-action">
                                <input class="bttn bttn-primary bttn-md" type="submit" name="submit" value="Sign Up" />
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
                {loading && (
            <div className="loader">
                <FontAwesomeIcon icon={faSpinner} spin size="3x" style={{ color: '#173F5F', marginRight: '10px' }} />
                 <p style={{ color: '#173F5F' }}>  Loading...</p>
            </div>
            )}
    </div>
   </>
  )
}

export default Login
