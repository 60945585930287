import React,{useState,useEffect, useCallback, useRef} from 'react';
import {Modal,Form,Button,Row,Col,Container} from "react-bootstrap";
import { Link } from 'react-router-dom';
import "../Styles/User/Dashboard.css";
import Swal from 'sweetalert2';
import "../Styles/User/Responsive.css";
import * as $ from "jquery";
import logo from "../images/hpc-logo-alt.svg";
import ring from "../images/lighting-ring.svg";
import bulb from "../images/lighting-bulb.png";
import pump from "../images/water-pump.png";
import pumpsm from "../images/water-pump.svg";
import bulbsm from "../images/lighting-bulb-sm.svg";
import {useNavigate} from "react-router-dom";
import Select, { StylesConfig } from "react-select";

const Dashboard = () => {
   // If the session is not set, redirect the user to the login page
   const isSessionNotSet = !localStorage.getItem("UID");
   // Get the navigate function from the hook
  const navigate = useNavigate();
   if (isSessionNotSet) {
    navigate("/");
  }
  const [adminID, setAdminID] = useState(localStorage.getItem("adminID")); // Set the initial value of adminID
  const [UID, setUID] = useState(localStorage.getItem("UID")); // Set the initial value of adminID
  const coverontotalDurationRef = useRef(0); // Use a ref to store the value
  const pastModeRef = useRef(0); // Use a ref to store the value
  const currentModeRef = useRef(0); // Use a ref to store the value
  const coveronminutesCompletedRef = useRef(0); // Use a ref to store the value
  const coveronminutesRemainingRef = useRef(0); // Use a ref to store the value
  const coverofftotalDurationRef = useRef(0); // Use a ref to store the value
  const coveroffminutesCompletedRef = useRef(0); // Use a ref to store the value
  const coveroffminutesRemainingRef = useRef(0); // Use a ref to store the value
  const currentColor = useRef(1);
  const hexLightRef = useRef(null);
  const hexArtPathRef = useRef(null);
  const hexLightButtonRef = useRef(null);
  const hexStatusLightRef = useRef(null);
  const currentPumpColor = useRef(0);
  const hexPumpRef = useRef(null);
  const hexPumpArtPathRef = useRef(null);
  const hexPumpButtonRef = useRef(null);
  const hexStatusPumpRef = useRef(null);
  const [CD, setCD] = useState([]);
  const [LD, setLD] = useState([]);
  const [PD, setPD] = useState([]);
  const [Noti, setNoti] = useState([]);
  const clickCallsRef = useRef(0);

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [show1, setShow1] = useState(false);

  const handleClose1 = () => setShow1(false);
  const handleShow1 = () => setShow1(true);
  const [showModalCoverOff, setShowModalCoverOff] = useState(false);

  const openModalCoverOff = () => {
    setShowModalCoverOff(true);
  };

  const closeModalCoverOff = () => {
    setShowModalCoverOff(false);
  };

  const [showModalCoverOn, setShowModalCoverOn] = useState(false);

  const openModalCoverOn = () => {
    setShowModalCoverOn(true);
  };

  const closeModalCoverOn = () => {
    setShowModalCoverOn(false);
  };

  const [showModalNoti, setShowModalNoti] = useState(false);

  const openModalNoti = () => {
    setShowModalNoti(true);
  };

  const closeModalNoti = () => {
    setShowModalNoti(false);
  };

    // useEffect(()=>{

    // },[])

    const [schedule, setSchedule] = useState({
        color:"",
        name:"",
        startDate:"",
        startTime:"",
        endDate:"",
        endTime:"",
        repeatType:"",
        daysOfWeek:[],
        dayOfMonth:"",
        // months:"",
        monthRepeatYearly:"",
        weekDayOrdinal:"",
        weekDay:"",
        repeatScheduleEndDate:null,
        scheduleType:"",
        repeatInterval:"",
        adminID:adminID,
        deviceID:UID
        
    })

    const handlecheck = (event) => {
        const value = event.target.value;
        const daysOfWeek = [...schedule.daysOfWeek]; // Create a copy of the daysOfWeek array
    
        if (daysOfWeek.includes(value)) {
          daysOfWeek.splice(daysOfWeek.indexOf(value), 1); // Remove value from the array
        } else {
          daysOfWeek.push(value); // Add value to the array
        }
    
        setSchedule({
          ...schedule,
          daysOfWeek: daysOfWeek
        });
    
        console.log(schedule)
      };





      

    const handleChangeSchedule=(e)=>{
        const newdata={...schedule};
        newdata[e.target.name]=e.target.value;
        setSchedule(newdata);
        console.log(newdata);



        
        let monthly=document.getElementById("monthOn");
        let monthlyint=document.getElementById("mInt");


        let monthD=document.getElementById("monthDay");
        let monthV=document.getElementById("monthOnVals");
        let monthV1=document.getElementById("monthOnVals1");

        if(monthly?.checked){
            monthlyint.setAttribute("disabled","")
            console.log("test");
        }else{
            monthlyint?.removeAttribute("disabled")
        }


        if(monthD?.checked){
            monthV.setAttribute("disabled","")
            monthV1.setAttribute("disabled","")
        }else{
            monthV?.removeAttribute("disabled")
            monthV1?.removeAttribute("disabled")
        }



        let yearly=document.getElementById("yearOn");
        let yealyint=document.getElementById("yInt");


        let yearD=document.getElementById("yearDay");
        let yearV1=document.getElementById("yearVals1");
        let yearV2=document.getElementById("yearVals2");
        let yearV3=document.getElementById("yearVals3");




      if(yearly?.checked){
          yealyint.setAttribute("disabled","")
      }else{
          yealyint?.removeAttribute("disabled")
      }


      if(yearD?.checked){
          yearV1.setAttribute("disabled","");
          yearV2.setAttribute("disabled","");
          yearV3.setAttribute("disabled","")
      }else{
          yearV1?.removeAttribute("disabled");
          yearV2?.removeAttribute("disabled");
          yearV3?.removeAttribute("disabled")
      }
    }

const schedUrl=`https://84ro5mdxeg.execute-api.us-east-2.amazonaws.com/prod/schedule`;


    const handleScheduleSubmit=(e)=>{
        e.preventDefault();


        let startTime=schedule.startTime;
        let newStartTime=startTime+":"+"00";
        // console.log(startTime);
        console.log(newStartTime);
        
        
        let endTime=schedule.endTime;
        let newEndTime=endTime+":"+"00";
        // console.log(endTime);
        console.log(newEndTime);


        let n={
            ...schedule,
            daysOfWeek:schedule.daysOfWeek.toString(),
            repeatInterval:parseInt(schedule.repeatInterval?schedule.repeatInterval:0),
            weekDay:parseInt(schedule.weekDay?schedule.weekDay:0),
            weekDayOrdinal:parseInt(schedule.weekDayOrdinal?schedule.weekDayOrdinal:0),
            dayOfMonth:parseInt(schedule.dayOfMonth?schedule.dayOfMonth:0),
            monthRepeatYearly:parseInt(schedule.monthRepeatYearly?schedule.monthRepeatYearly:0),
            repeatType:parseInt(schedule.repeatType),
            color:parseInt(schedule.color),
            startTime:newStartTime,
            endTime:newEndTime,
            deviceID : UID


        }

        console.log(n);

        fetch(schedUrl,{
            method:"POST",
            headers:{
              Accept: "application/json",
              'Content-Type': 'application/json'
            },
            body: JSON.stringify(n)
        })
        .then((res)=>res.json())
        .then((result)=>{
            console.log(result);
            if(result.Status===200){
                Swal.fire({
                    icon:"success",
                    title:"Added successfully!",
                    showConfirmButton:false,
                    timer:2000
                })
                getSchedule();
                handleClose();
            }
        })

    }


    


// console.log(coverData);


//     const filteredDataModule5 = notifyData.filter((item) => item.module === 5 && item.isRead === 0);

// console.log(filteredDataModule5);


    const [viewSched, setViewSched] = useState([]);


    const [vESched, setVESched] = useState([]);

    
const [editSched, setEditSched] = useState({
    color:"",
    name:"",
    startDate:"",
    startTime:"",
    endDate:"",
    endTime:"",
    repeatType:"",
    daysOfWeek:[],
    dayOfMonth:"",
    // months:"",
    monthRepeatYearly:"",
    weekDayOrdinal:"",
    weekDay:"",
    repeatScheduleEndDate:null,
    scheduleType:"",
    repeatInterval:"",
    adminID:adminID,
    deviceID:UID
})




const handlecheck1 = (event) => {
    const value = event.target.value;
    const daysOfWeek = [...editSched.daysOfWeek]; // Create a copy of the daysOfWeek array

    if (daysOfWeek.includes(value)) {
      daysOfWeek.splice(daysOfWeek.indexOf(value), 1); // Remove value from the array
    } else {
      daysOfWeek.push(value); // Add value to the array
    }

    setEditSched({
      ...editSched,
      daysOfWeek: daysOfWeek
    });

    console.log(editSched)
  };

    const handleChangeEdit=(e)=>{
        const newdata1={...editSched};
        newdata1[e.target.name]=e.target.value;
        setEditSched(newdata1);
        console.log(newdata1);






        let monthly=document.getElementById("emonthOn");
        let monthlyint=document.getElementById("emInt");


        let monthD=document.getElementById("emonthDay");
        let monthV=document.getElementById("emonthOnVals");
        let monthV1=document.getElementById("emonthOnVals1");

        if(monthly?.checked){
            monthlyint.setAttribute("disabled","")
            console.log("test");
        }else{
            monthlyint?.removeAttribute("disabled")
        }


        if(monthD?.checked){
            monthV.setAttribute("disabled","")
            monthV1.setAttribute("disabled","")
        }else{
            monthV?.removeAttribute("disabled")
            monthV1?.removeAttribute("disabled")
        }



        let yearly=document.getElementById("eyearOn");
        let yealyint=document.getElementById("eyInt");
        let yealyint1=document.getElementById("eyInt1");


        let yearD=document.getElementById("eyearDay");
        let yearV1=document.getElementById("eyearVals1");
        let yearV2=document.getElementById("eyearVals2");
        let yearV3=document.getElementById("eyearVals3");




        if(yearly?.checked){
            yealyint.setAttribute("disabled","")
            yealyint1.setAttribute("disabled","")
            console.log("test");

        }else{
            yealyint?.removeAttribute("disabled")
            yealyint1?.removeAttribute("disabled")
        }


        if(yearD?.checked){
            yearV1.setAttribute("disabled","");
            yearV2.setAttribute("disabled","");
            yearV3.setAttribute("disabled","")
        }else{
            yearV1?.removeAttribute("disabled");
            yearV2?.removeAttribute("disabled");
            yearV3?.removeAttribute("disabled")
        }
    }

    //get schedule data
    useEffect(() => {
      getSchedule();

      const interval = setInterval(getSchedule, 1000); // Fetch every 1 sec
  
      return () => clearInterval(interval); // Cleanup interval on component unmount
    }, []);
  
    const getSchedule = () => {
      fetch(`https://84ro5mdxeg.execute-api.us-east-2.amazonaws.com/prod/schedule/getallschedulesforadmin/${adminID}`)
        .then((response) => response.json())
        .then((result) => {

          // Set the state values
          setCD(result.Data?.map((item) => item.schedule).filter((schedule) => schedule.scheduleType === 1) || []);
          setLD(result.Data?.map((item) => item.schedule).filter((schedule) => schedule.scheduleType === 2) || []);
          setPD(result.Data?.map((item) => item.schedule).filter((schedule) => schedule.scheduleType === 3) || []);

   
      
          // Find the current time
          const currentTime = new Date();

          // Filter the schedules of type 2 and status 0
          const lightSchedules = result.Data?.filter((item) => item.schedule.scheduleType === 2 && item.schedule.status === 0) || [];

          // Filter the schedules of type 3 and status 0
          const pumpSchedules = result.Data?.filter((item) => item.schedule.scheduleType === 3 && item.schedule.status === 0) || [];

          // Filter the schedules of type 1 and status 0
          const coverSchedules = result.Data?.filter((item) => item.schedule.scheduleType === 1 && item.schedule.status === 0) || [];


          // Check if the current time falls within any repeatSchedules for coverSchedules (with 30 minutes additional condition)
          const activeCoverOnSchedule = coverSchedules.find((item) => {
            const repeatSchedules = item.repeatSchedules || [];
            return repeatSchedules.some((repeatSchedule) => {
              const startDate = new Date(repeatSchedule.startDateTime);
              const endDate = new Date(repeatSchedule.endDateTime);
              // Check if current time is within 60 minutes after the repeat schedule start date time
              return startDate <= currentTime && currentTime <= endDate && repeatSchedule.status === 0 &&
                currentTime <= new Date(startDate.getTime() + 60 * 60000);
            });
          });

          // Check if the current time falls within any repeatSchedules for coverSchedules (with 60 minutes additional condition)
          const activeCoverOffSchedule = coverSchedules.find((item) => {
            const repeatSchedules = item.repeatSchedules || [];
            return repeatSchedules.some((repeatSchedule) => {
              const startDate = new Date(repeatSchedule.startDateTime);
              const endDate = new Date(repeatSchedule.endDateTime);
              // Check if current time is within 120  minutes before the repeat schedule end date time
              return startDate <= currentTime && currentTime <= endDate && (repeatSchedule.status === 1 || repeatSchedule.status === 0) &&
                currentTime >= new Date(endDate.getTime() - 120 * 60000);
            });
          });

          // Check if the current time is past the endDateTime and status is 1 for coverSchedules
          const expiredCoverSchedules = coverSchedules.find((item) => {
            const repeatSchedules = item.repeatSchedules || [];
            return repeatSchedules.some((repeatSchedule) => {
              const startDate = new Date(repeatSchedule.startDateTime);
              const endDate = new Date(repeatSchedule.endDateTime);
              const currentTime = new Date();
              return currentTime > endDate && repeatSchedule.status === 1;
            });
          });

          // Check if the current time falls within any repeatSchedules for lightSchedules
          const activeLightSchedule = lightSchedules.find((item) => {
            const repeatSchedules = item.repeatSchedules || [];
            return repeatSchedules.some((repeatSchedule) => {
              const startDate = new Date(repeatSchedule.startDateTime);
              const endDate = new Date(repeatSchedule.endDateTime);
              return startDate <= currentTime && currentTime <= endDate && repeatSchedule.status === 0;
            });
          });

          // Check if the current time is past the endDateTime and status is 1 for lightSchedules
          const expiredLightSchedules = lightSchedules.find((item) => {
            const repeatSchedules = item.repeatSchedules || [];
            return repeatSchedules.some((repeatSchedule) => {
              const endDate = new Date(repeatSchedule.endDateTime);
              const currentTime = new Date();
              return currentTime > endDate && repeatSchedule.status === 1;
            });
          });

          // Check if the current time falls within any repeatSchedules for pumpSchedules
          const activePumpSchedule = pumpSchedules.find((item) => {
            const repeatSchedules = item.repeatSchedules || [];
            return repeatSchedules.some((repeatSchedule) => {
              const startDate = new Date(repeatSchedule.startDateTime);
              const endDate = new Date(repeatSchedule.endDateTime);
              return startDate <= currentTime && currentTime <= endDate && repeatSchedule.status === 0;
            });
          });
          
          // Check if the current time is past the endDateTime and status is 1 for pumpSchedules
          const expiredPumpSchedules = pumpSchedules.find((item) => {
            const repeatSchedules = item.repeatSchedules || [];
            return repeatSchedules.some((repeatSchedule) => {
              const startDate = new Date(repeatSchedule.startDateTime);
              const endDate = new Date(repeatSchedule.endDateTime);
              const currentTime = new Date();

              return currentTime > endDate && repeatSchedule.status === 1;
            });
          });


          // If an active schedule is found, update the color based on the selected color in the schedule
          if (activeLightSchedule) {
            const lightColorsSched = {
              1: {  poolColorState: 4, color: "#0000FF", rgb_red: 0, rgb_green: 0, rgb_blue: 100 },
              2: {  poolColorState: 3, color: "#00FF00", rgb_red: 0, rgb_green: 100, rgb_blue: 0 },
              3: {  poolColorState: 2, color: "#FF0000", rgb_red: 100, rgb_green: 0, rgb_blue: 0 },
              4: {  poolColorState: 5, color: "#FF00FF", rgb_blue: 100, rgb_green: 0, rgb_red: 100 },
              5: {  poolColorState: 6, color: "#FFFF00", rgb_red: 100, rgb_green: 100, rgb_blue: 0 },
              6: {  poolColorState: 7, color: "#00FFFF", rgb_blue: 100, rgb_green: 100, rgb_red: 0 },
              7: {  poolColorState: 8, color: "#FFFFFF", rgb_red: 100, rgb_green: 100, rgb_blue: 100 },
            };

            const colorData = lightColorsSched[activeLightSchedule.schedule.color];
            currentColor.current = colorData.poolColorState;
            hexStatusLightRef.current.textContent = "ON";
            
            $("#hexlight").attr("data-lightstate", colorData.poolColorState);
            $(".hexart-bs path").css("fill", colorData.color);
            $(".hexlt-btn").css("background", colorData.color);

            const data = {
              start_datetime: "",
              end_datetime: "",
              rgb_red: colorData.rgb_red,
              rgb_green: colorData.rgb_green,
              rgb_blue: colorData.rgb_blue,
            };

            const putUrl = `https://84ro5mdxeg.execute-api.us-east-2.amazonaws.com/prod/rgb/${adminID}`;
            fetch(putUrl, {
              method: "PUT",
              headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
              },
              body: JSON.stringify(data),
            });

            const activeLightScheduleId = activeLightSchedule.repeatSchedules.find((repeatSchedule) => {
              const startDate = new Date(repeatSchedule.startDateTime);
              const endDate = new Date(repeatSchedule.endDateTime);
              return startDate <= currentTime && currentTime <= endDate;
            }).id;

            const statusData= {
              status : 1
            };

            const putScheduleUrl = `https://84ro5mdxeg.execute-api.us-east-2.amazonaws.com/prod/schedule/changetstatus/${activeLightScheduleId}`;
            fetch(putScheduleUrl, {
              method: "PUT",
              headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
              },
              body: JSON.stringify(statusData),
            });

          }

          if(expiredLightSchedules){
              hexStatusLightRef.current.textContent = "OFF";
              $("#hexlight").attr("data-lightstate", 1);
              $(".hexart-bs path").css("fill", '#cccccc');
              $(".hexlt-btn").css("background", '#cccccc');


              const data = {
                start_datetime: "",
                end_datetime: "",
                rgb_red: 0,
                rgb_green: 0,
                rgb_blue: 0,
              };

              const putUrl = `https://84ro5mdxeg.execute-api.us-east-2.amazonaws.com/prod/rgb/${adminID}`;
              fetch(putUrl, {
                method: "PUT",
                headers: {
                  Accept: "application/json",
                  "Content-Type": "application/json",
                },
                body: JSON.stringify(data),
              });

              const expiredLightSchedulesId = expiredLightSchedules.repeatSchedules.find((repeatSchedule) => {
                const startDate = new Date(repeatSchedule.startDateTime);
                const endDate = new Date(repeatSchedule.endDateTime);
                const currentTime = new Date();
                return currentTime > endDate && repeatSchedule.status === 1;
              }).id;

              const statusData= {
                status : 2
              };

              const putScheduleUrl = `https://84ro5mdxeg.execute-api.us-east-2.amazonaws.com/prod/schedule/changetstatus/${expiredLightSchedulesId}`;
              fetch(putScheduleUrl, {
                method: "PUT",
                headers: {
                  Accept: "application/json",
                  "Content-Type": "application/json",
                },
                body: JSON.stringify(statusData),
              });

          }

          if(activePumpSchedule){
            currentPumpColor.current = 2;
            hexStatusPumpRef.current.textContent = "ON";
            
              $("#hexpump").attr("data-pumplightstate", 2);
              $(".hexartp-bs path").css("fill", '#00FF00');
              $(".hexp-btn").css("background", '#00FF00');

              const data = {
                start_datetime: "",
                end_datetime: "",
                mode: 2
              };
              const putUrl = `https://84ro5mdxeg.execute-api.us-east-2.amazonaws.com/prod/pumping/${adminID}`;
          
              fetch(putUrl, {
                method: "PUT",
                headers: {
                  Accept: "application/json",
                  "Content-Type": "application/json",
                },
                body: JSON.stringify(data),
              });

              const statusData= {
                status : 1
              };

              const activePumpScheduleId = activePumpSchedule.repeatSchedules.find((repeatSchedule) => {
                const startDate = new Date(repeatSchedule.startDateTime);
                const endDate = new Date(repeatSchedule.endDateTime);
                return startDate <= currentTime && currentTime <= endDate;
              }).id;
  
              const putScheduleUrl = `https://84ro5mdxeg.execute-api.us-east-2.amazonaws.com/prod/schedule/changetstatus/${activePumpScheduleId}`;
              fetch(putScheduleUrl, {
                method: "PUT",
                headers: {
                  Accept: "application/json",
                  "Content-Type": "application/json",
                },
                body: JSON.stringify(statusData),
              });

          }

          if(expiredPumpSchedules){
            hexStatusPumpRef.current.textContent = "OFF";

            $("#hexpump").attr("data-pumplightstate", 1);
            $(".hexartp-bs path").css("fill", '#cccccc');
            $(".hexp-btn").css("background", '#cccccc');

            const pumpData = {
              start_datetime: "",
              end_datetime: "",
              mode: 0
            };

            const putUrl = `https://84ro5mdxeg.execute-api.us-east-2.amazonaws.com/prod/pumping/${adminID}`;
          
            fetch(putUrl, {
              method: "PUT",
              headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
              },
              body: JSON.stringify(pumpData),
            });

            const statusData= {
              status : 2
            };

            const expiredPumpScheduleId = expiredPumpSchedules.repeatSchedules.find((repeatSchedule) => {
              const startDate = new Date(repeatSchedule.startDateTime);
              const endDate = new Date(repeatSchedule.endDateTime);
              const currentTime = new Date();
              return currentTime > endDate && repeatSchedule.status === 1;
            }).id;

            const putScheduleUrl = `https://84ro5mdxeg.execute-api.us-east-2.amazonaws.com/prod/schedule/changetstatus/${expiredPumpScheduleId}`;
            fetch(putScheduleUrl, {
              method: "PUT",
              headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
              },
              body: JSON.stringify(statusData),
            });

          }

          if (activeCoverOnSchedule) {
            const activeCoverOnScheduleId = activeCoverOnSchedule.repeatSchedules.find((repeatSchedule) => {
              const startDate = new Date(repeatSchedule.startDateTime);
              const endDate = new Date(repeatSchedule.endDateTime);
              return startDate <= currentTime && currentTime <= endDate;
            }).id;
          
            const statusData = {
              status: 1
            };
          
            const putScheduleUrl = `https://84ro5mdxeg.execute-api.us-east-2.amazonaws.com/prod/schedule/changetstatus/${activeCoverOnScheduleId}`;
            fetch(putScheduleUrl, {
              method: "PUT",
              headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
              },
              body: JSON.stringify(statusData),
            });
          
            // Calculate remaining time in minutes and seconds
            const repeatScheduleData = activeCoverOnSchedule.repeatSchedules.find((repeatSchedule) => {
              const startDate = new Date(repeatSchedule.startDateTime);
              return startDate <= currentTime;
            });
          
            const startDate = new Date(repeatScheduleData.startDateTime);
            const completedTimeInMilliseconds = currentTime.getTime() - startDate.getTime();
            // Calculate remaining minutes and seconds
            const completedMinutes = (completedTimeInMilliseconds / 60000);
            coveronminutesRemainingRef.current = 60 - completedMinutes;

            //cover api data req obj
            const coverData = {
              coverStartTime: formatDate(repeatScheduleData.startDateTime),
              coverPauseTime: "",
              coverEndTime: formatDate(new Date(startDate.getTime() + 60 * 60000)),
              mode: 1,
              subMode: 1,
              pastMode: 0,
              duration: 60
            };
            


             //update cover info
            const coverUrl = `https://84ro5mdxeg.execute-api.us-east-2.amazonaws.com/prod/cover/${adminID}`;
          
            fetch(coverUrl, {
              method: "PUT",
              headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
              },
              body: JSON.stringify(coverData),
            });
            handleSchedDBCoverOn();
          }

          if(activeCoverOffSchedule ){
            const activeCoverOffScheduleId = activeCoverOffSchedule.repeatSchedules.find((repeatSchedule) => {
              const startDate = new Date(repeatSchedule.startDateTime);
              const endDate = new Date(repeatSchedule.endDateTime);
              return startDate <= currentTime && currentTime <= endDate;
            }).id;
          
            const statusData = {
              status: 2
            };
          
            const putScheduleUrl = `https://84ro5mdxeg.execute-api.us-east-2.amazonaws.com/prod/schedule/changetstatus/${activeCoverOffScheduleId}`;
            fetch(putScheduleUrl, {
              method: "PUT",
              headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
              },
              body: JSON.stringify(statusData),
            });
          
            // Calculate remaining time in minutes and seconds
            const repeatScheduleData = activeCoverOffSchedule.repeatSchedules.find((repeatSchedule) => {
              const startDate = new Date(repeatSchedule.startDateTime);
              return startDate <= currentTime;
            });
          
            const endDate = new Date(repeatScheduleData.endDateTime);
            const remainingTimeInMilliseconds = endDate.getTime() - currentTime.getTime();
            // Calculate remaining minutes and seconds
            const remainingMinutes = (remainingTimeInMilliseconds / 60000);
            coveroffminutesRemainingRef.current = remainingMinutes;
          
            //cover api data req obj
            const coverData = {
              coverStartTime: formatDate(new Date(endDate.getTime() - 120 * 60000)), // subtracting 120 minutes in milliseconds
              coverPauseTime: "",
              coverEndTime: formatDate(repeatScheduleData.endDateTime),
              mode: 1,
              subMode: 1,
              pastMode: 0,
              duration: 120
            };
            
            console.log('helllooooo');


             //update cover info
            const coverUrl = `https://84ro5mdxeg.execute-api.us-east-2.amazonaws.com/prod/cover/${adminID}`;
          
            fetch(coverUrl, {
              method: "PUT",
              headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
              },
              body: JSON.stringify(coverData),
            });
            handleSchedDBCoverOff();
          }

          if(expiredCoverSchedules){
            const expiredCoverOnScheduleId = expiredCoverSchedules.repeatSchedules.find((repeatSchedule) => {
              const endDate = new Date(repeatSchedule.endDateTime);
              const currentTime = new Date();
              return currentTime > endDate && repeatSchedule.status === 1;
            }).id;
          
            const statusData = {
              status: 2
            };
          
            const putScheduleUrl = `https://84ro5mdxeg.execute-api.us-east-2.amazonaws.com/prod/schedule/changetstatus/${expiredCoverOnScheduleId}`;
            fetch(putScheduleUrl, {
              method: "PUT",
              headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
              },
              body: JSON.stringify(statusData),
            });

            const pastMode = 0;
            fetch(`https://84ro5mdxeg.execute-api.us-east-2.amazonaws.com/prod/cover/getcoversbyadmin/${adminID}`)
            .then((response) => response.json())
              .then((result) => {
                const { mode } = result.Data; 
                if(mode == 1)
                {
                  currentModeRef.current = 0;
                  pastModeRef.current = 1;
                  pastMode = 1;
                  $(".hexbars-over").addClass("on");
                  setTimeout(() => {
                    // Add the paused class to pause the animation at 20% height
                    $(".hexbars-over").addClass("paused");
                  }, 3200); 
                }
                if(mode == 2)
                {
                  currentModeRef.current = 0;
                  pastModeRef.current = 0;
                  pastMode = 2;
                }
              });


            //cover module setting
            $(".progfill").stop();
            $(".panelcard-pc").attr("data-pcstate", 'off');
            $(".panelcard-pc").attr("data-pconoff", 'on');
            $(".coverprog, .covertime").fadeOut();
            $(".panelcard-pc").attr("data-pcinprog", '0'); // Set data-pcinprog value to 0
            $("#hexcover-state").text("OFF");
            $(".hexbars-over").removeClass("on");
            $(".hexbars-over").removeClass("on paused");
            $("#poolcover-on").find(".coverbtn-pl").removeClass('enable');
            $("#poolcover-on").find(".coverbtn-pa").removeClass('enable');
            $(".hexbars-over").removeClass("off");
            $(".hexbars-over").removeClass("off paused");
            $("#poolcover-off").find(".coverbtn-pl").removeClass('enable');
            $("#poolcover-off").find(".coverbtn-pa").removeClass('enable');
            $(".panelcard-pc").attr("data-pcprog", 0);
            // Reset progress bar to zero
            $(".progfill").css("width", '0%'); 
            $(".progcount").text('0%');

            //cover api data req obj
            const coverData = {
              coverStartTime: "",
              coverPauseTime: "",
              coverEndTime: "",
              mode: 0,
              subMode: 0,
              pastMode: pastMode,
              duration: 0
            };
          
            //update cover info
            const coverUrl = `https://84ro5mdxeg.execute-api.us-east-2.amazonaws.com/prod/cover/${adminID}`;
          
            fetch(coverUrl, {
              method: "PUT",
              headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
              },
              body: JSON.stringify(coverData),
            });

          }
        })
        .catch((error) => {
          console.error(error);
        });
    };

    const formatDate = (dateTimeString) => {
      const dateTime = new Date(dateTimeString);
      const year = dateTime.getFullYear();
      const month = String(dateTime.getMonth() + 1).padStart(2, '0');
      const day = String(dateTime.getDate()).padStart(2, '0');
      const hours = String(dateTime.getHours()).padStart(2, '0');
      const minutes = String(dateTime.getMinutes()).padStart(2, '0');
      const seconds = String(dateTime.getSeconds()).padStart(2, '0');
    
      return `${month}/${day}/${year} ${hours}:${minutes}:${seconds}`;
    };

    //Unread messages
    useEffect(() => {
      fetchUnreadMessages();
      const interval = setInterval(fetchUnreadMessages, 2000); // Fetch every 10 seconds
  
      return () => clearInterval(interval); // Cleanup interval on component unmount
    }, []);
  
    const fetchUnreadMessages = () => {
      fetch(`https://84ro5mdxeg.execute-api.us-east-2.amazonaws.com/prod/message/${UID}`)
        .then((response) => response.json())
        .then((data) => {
          if (data.Status === 200) {

            const filteredDataSensorTempModule = data.Data.filter((item) => item.module === 4 && item.isRead === 0);
            const filteredDataNotiGeneralModule = data.Data.filter((item) => item.module === 5 && item.isRead === 0 && item.payload.payload_type === "notification_general");
            const filteredDataNotiWarnModule = data.Data.filter((item) => item.module === 5 && item.isRead === 0 && item.payload.payload_type === "notification_warning");
            const filteredDataPopUpModule = data.Data.filter((item) => item.module === 5 && item.isRead === 0 && item.payload.pop_up === 1);
            const filteredDataCoverModule = data.Data.filter((item) => item.module === 5 && item.isRead === 0 && item.payload.payload_type === "notification_mode_status");
            const filteredDataRGBModule = data.Data.filter((item) => item.module === 5 && item.isRead === 0 && item.payload.payload_type === "notification_rgb_status");
            const filteredDataSystemModule = data.Data.filter((item) => item.module === 5 && item.isRead === 0 && item.payload.payload_type === "notification_system_status");

            //set pool tempurature
            if (filteredDataSensorTempModule.length > 0) {
              const latestDataSensorTempModule = filteredDataSensorTempModule[filteredDataSensorTempModule.length - 1];
              const { id, payload } = latestDataSensorTempModule;
              const { temperature } = payload;
             
              const poolTempElement = document.querySelector(".pool-tempCover");
              poolTempElement.textContent = temperature + "° F";
              const lightpoolTempElement = document.querySelector(".pool-tempLight");
              lightpoolTempElement.textContent = temperature + "° F";
              const pumppoolTempElement = document.querySelector(".pool-tempPump");
              pumppoolTempElement.textContent = temperature + "° F";
              const messageIds = filteredDataSensorTempModule.map((item) => item.id).join(',');
              markMessagesAsRead(messageIds , 'pool_temp');
            }

            //general noti
            if(filteredDataNotiGeneralModule.length > 0){
              const latestDataNotiGeneralModule = filteredDataNotiGeneralModule[filteredDataNotiGeneralModule.length - 1];
              const { id, payload } = latestDataNotiGeneralModule;
              const { pop_up, payload_type, message } = payload;
              setNoti(
                data.Data.filter(
                  (item) =>
                    item.module === 5 &&
                    item.isRead === 0 &&
                    item.payload.payload_type === "notification_general"
                )
              );
            }

            //sensor failure alert
            if(filteredDataNotiWarnModule.length > 0){
              const latestDataNotiWarnModule = filteredDataNotiWarnModule[filteredDataNotiWarnModule.length - 1];
              const { id, payload } = latestDataNotiWarnModule;
              const { pop_up, payload_type, message } = payload;
  
              let sensor = document.getElementById("sensor-failure");
              sensor.style.display = "block";
              const h3Element = sensor.querySelector("h3");
              h3Element.textContent = message;
              const messageIds = filteredDataNotiWarnModule.map((item) => item.id).join(',');
              markMessagesAsRead(messageIds, 'sensor_failure');
            }

            //open pop up
            if(filteredDataPopUpModule.length > 0){
              const latestDataPopUpModule = filteredDataPopUpModule[filteredDataPopUpModule.length - 1];
              const { id, payload } = latestDataPopUpModule;
              const { pop_up, payload_type, message } = payload
              openModalNoti();
              const messageIds = filteredDataPopUpModule.map((item) => item.id).join(',');
              markMessagesAsRead(messageIds, 'pop_up');
            }

            //system noti
            if(filteredDataSystemModule.length > 0){
              const latestDataSystemModule = filteredDataSystemModule[filteredDataSystemModule.length - 1];
              const { id, payload } = latestDataSystemModule;
              const { pop_up, payload_type, message } = payload
              if (message === 'System_on') {
                $(".dashstatus-cover").text(' ON');
                $(".dashstatus-light").text(' ON');
                $(".dashstatus-pumping").text(' ON');
                //devise status api data req obj
                const deviceStatusData = {
                  status : 1
                };

                //update device status info
                const deviseStatusUrl = `https://84ro5mdxeg.execute-api.us-east-2.amazonaws.com/prod/devicestatus/${adminID}`;
               
                fetch(deviseStatusUrl, {
                  method: "PUT",
                  headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                  },
                  body: JSON.stringify(deviceStatusData),
                });

                //cover module setting
                $(".progfill").stop();
                $(".panelcard-pc").attr("data-pcstate", 'off');
                $(".panelcard-pc").attr("data-pconoff", 'on');
                $(".coverprog, .covertime").fadeOut();
                $(".panelcard-pc").attr("data-pcinprog", '0'); // Set data-pcinprog value to 0
                $("#hexcover-state").text("OFF");
                $(".hexbars-over").removeClass("on");
                $(".hexbars-over").removeClass("on paused");
                $("#poolcover-on").find(".coverbtn-pl").removeClass('enable');
                $("#poolcover-on").find(".coverbtn-pa").removeClass('enable');
                $(".hexbars-over").removeClass("off");
                $(".hexbars-over").removeClass("off paused");
                $("#poolcover-off").find(".coverbtn-pl").removeClass('enable');
                $("#poolcover-off").find(".coverbtn-pa").removeClass('enable');
                $(".panelcard-pc").attr("data-pcprog", 0);
                // Reset progress bar to zero
                $(".progfill").css("width", '0%');
                $(".progcount").text('0%');

                currentModeRef.current = 0;
                pastModeRef.current = 0;
               
                var pastModeObj = 0;
                fetch(`https://84ro5mdxeg.execute-api.us-east-2.amazonaws.com/prod/cover/getcoversbyadmin/${adminID}`)
                .then((response) => response.json())
                  .then((result) => {
                    const {  mode , pastMode  } = result.Data;
                    if(mode == 0){
                      pastModeObj = pastMode;
                      if(pastMode == 1){
                        pastModeRef.current = 1;

                        // Additional code to be executed after animation complete
                        $("#hexcover-state").text("On");
                        $(".hexbars-over").addClass("on");
                  
                        setTimeout(() => {
                          $(".hexbars-over").addClass("paused");
                        }, 3200);
                      }  
                    }
                    if(mode == 1 || mode == 2){
                      pastModeObj = 2;
                    }
                    

                     //cover api data req obj
                    const coverData = {
                      coverStartTime: "",
                      coverPauseTime: "",
                      coverEndTime: "",
                      mode: 0,
                      subMode: 0,
                      pastMode: pastModeObj,
                      duration: 0
                    };
                  
                    //update cover info
                    const coverUrl = `https://84ro5mdxeg.execute-api.us-east-2.amazonaws.com/prod/cover/${adminID}`;
                  
                    fetch(coverUrl, {
                      method: "PUT",
                      headers: {
                        Accept: "application/json",
                        "Content-Type": "application/json",
                      },
                      body: JSON.stringify(coverData),
                    });
                  })

               
                
                //pump module setting
                $("#hexpump").attr("data-pumplightstate", 1);
                $(".hexartp-bs path").css("fill", '#cccccc');
                $(".hexp-btn").css("background", '#cccccc');

                const pumpData = {
                  start_datetime: "",
                  end_datetime: "",
                  mode: 0
                };
                const putPumpUrl = `https://84ro5mdxeg.execute-api.us-east-2.amazonaws.com/prod/pumping/${adminID}`;
            
                fetch(putPumpUrl, {
                  method: "PUT",
                  headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                  },
                  body: JSON.stringify(pumpData),
                })

                //rgb module settings
                $("#hexlight").attr("data-lightstate", 1);
                $(".hexart-bs path").css("fill", '#cccccc');
                $(".hexlt-btn").css("background", '#cccccc');

                const rgbData = {
                  start_datetime: "",
                  end_datetime: "",
                  rgb_red: 0,
                  rgb_green: 0,
                  rgb_blue: 0,
                };
  
                const putRGBUrl = `https://84ro5mdxeg.execute-api.us-east-2.amazonaws.com/prod/rgb/${adminID}`;
                fetch(putRGBUrl, {
                  method: "PUT",
                  headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                  },
                  body: JSON.stringify(rgbData),
                });

                const messageIds = filteredDataSystemModule.map((item) => item.id).join(',');
                markMessagesAsRead(messageIds, 'system_noti');  
                clickCallsRef.current = 1;
                // window.location.reload();
              
                navigate("/dashboard");
              } else if (message === 'System_off') {
                $(".dashstatus-cover").text(' OFF');
                $(".dashstatus-light").text(' OFF');
                $(".dashstatus-pumping").text(' OFF');

                // Disable the coverbtns div by applying a class to it
                const coverBtnsDiv = document.querySelector('.coverbtns');
                if (coverBtnsDiv) {
                  coverBtnsDiv.classList.add('disabled');
                }

                //devise status api data req obj
                const deviceStatusData = {
                  status : 0
                };

                //update device status info
                const deviseStatusUrl = `https://84ro5mdxeg.execute-api.us-east-2.amazonaws.com/prod/devicestatus/${adminID}`;
               
                fetch(deviseStatusUrl, {
                  method: "PUT",
                  headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                  },
                  body: JSON.stringify(deviceStatusData),
                });

                //cover module setting
                $(".progfill").stop();
                $(".panelcard-pc").attr("data-pcstate", 'off');
                $(".panelcard-pc").attr("data-pconoff", 'on');
                $(".coverprog, .covertime").fadeOut();
                $(".panelcard-pc").attr("data-pcinprog", '0'); // Set data-pcinprog value to 0
                $("#hexcover-state").text("OFF");
                $(".hexbars-over").removeClass("on");
                $("#poolcover-on").find(".coverbtn-pl").removeClass('enable');
                $("#poolcover-on").find(".coverbtn-pa").removeClass("enable");
                $(".hexbars-over").removeClass("on paused");
                $(".hexbars-over").removeClass("off");
                $("#poolcover-off").find(".coverbtn-pl").removeClass('enable');
                $(".panelcard-pc").attr("data-pcprog", 0);
                $("#poolcover-off").find(".coverbtn-pa").removeClass("enable");
                $(".hexbars-over").removeClass("off paused");
                // Reset progress bar to zero
                $(".progfill").css("width", '0%');
                $(".progcount").text('0%');

                currentModeRef.current = 0;
                pastModeRef.current = 0;

                var pastModeObj = 0;
                fetch(`https://84ro5mdxeg.execute-api.us-east-2.amazonaws.com/prod/cover/getcoversbyadmin/${adminID}`)
                .then((response) => response.json())
                  .then((result) => {
                    const {  mode , pastMode  } = result.Data;
                    console.log('result data' + pastMode);
                    if(mode == 0){
                      pastModeObj = pastMode;

                      if(pastMode == 1){
                          pastModeRef.current = 1;
                          // Additional code to be executed after animation complete
                          $(".hexbars-over").addClass("on");
                          $("#hexcover-state").text("On");
                          setTimeout(() => {
                            $(".hexbars-over").addClass("paused");
                          }, 3200);
                  
                      }
                    }
                    if(mode == 1 || mode == 2){
                      pastModeObj = 2;
                    }
                   

                    //cover api data req obj
                    const coverData = {
                      coverStartTime: "",
                      coverPauseTime: "",
                      coverEndTime: "",
                      mode: 0,
                      subMode: 0,
                      pastMode: pastModeObj,
                      duration: 0
                    };

                    
                  
                    //update cover info
                    const coverUrl = `https://84ro5mdxeg.execute-api.us-east-2.amazonaws.com/prod/cover/${adminID}`;
                  
                    fetch(coverUrl, {
                      method: "PUT",
                      headers: {
                        Accept: "application/json",
                        "Content-Type": "application/json",
                      },
                      body: JSON.stringify(coverData),
                    });


                  })

                
                
                
                //pump module setting
                $("#hexpump").attr("data-pumplightstate", 1);
                $(".hexartp-bs path").css("fill", '#cccccc');
                $(".hexp-btn").css("background", '#cccccc');

                const pumpData = {
                  start_datetime: "",
                  end_datetime: "",
                  mode: 0
                };
                const putPumpUrl = `https://84ro5mdxeg.execute-api.us-east-2.amazonaws.com/prod/pumping/${adminID}`;
            
                fetch(putPumpUrl, {
                  method: "PUT",
                  headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                  },
                  body: JSON.stringify(pumpData),
                })

                //rgb module settings
                $("#hexlight").attr("data-lightstate", 1);
                $(".hexart-bs path").css("fill", '#cccccc');
                $(".hexlt-btn").css("background", '#cccccc');

                const rgbData = {
                  start_datetime: "",
                  end_datetime: "",
                  rgb_red: 0,
                  rgb_green: 0,
                  rgb_blue: 0,
                };
  
                const putRGBUrl = `https://84ro5mdxeg.execute-api.us-east-2.amazonaws.com/prod/rgb/${adminID}`;
                fetch(putRGBUrl, {
                  method: "PUT",
                  headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                  },
                  body: JSON.stringify(rgbData),
                });
                const messageIds = filteredDataSystemModule.map((item) => item.id).join(',');
                markMessagesAsRead(messageIds, 'system_noti');
                clickCallsRef.current = 0;
              }
              
              //navigate("/dashboard");
            }

            //start cover on/off
            if(filteredDataCoverModule.length > 0){
              const latestDataCoverModule = filteredDataCoverModule[filteredDataCoverModule.length - 1];
              const { id, payload } = latestDataCoverModule;
              const { pop_up, payload_type, message,time_stamp } = payload
              console.log(message);
              if(message == "cover_on_start")
              {
                clickCallsRef.current = 1;
                handleCoverOnBtnDefNoti(time_stamp);
                //window.location.reload();
                navigate("/dashboard");
              }
              else if(message == 'cover_on_pause'){
                clickCallsRef.current = 1;
                handleCoverOnBtnPlClick('cover_only');
                //window.location.reload();
                navigate("/dashboard");
              }
              else if(message == 'cover_on_stop'){
                clickCallsRef.current = 1;
                handleCoverOnBtnStopCLick();
                //window.location.reload();
                navigate("/dashboard");
              }
              else if(message == 'cover_off_start'){
                clickCallsRef.current = 1;
                handleCoverOffBtnDefNoti(time_stamp);
                //window.location.reload();
                navigate("/dashboard");
              }
              else if(message == 'cover_off_pause'){
                clickCallsRef.current = 1;
                handleCoverOffBtnPlClick('cover_only');
               // window.location.reload();
               navigate("/dashboard");
              }
              else if(message == 'cover_off_stop'){
                clickCallsRef.current = 1;
                handleCoverOffBtnStopClick();
                //window.location.reload();
                navigate("/dashboard");
              }
              const messageIds = filteredDataCoverModule.map((item) => item.id).join(',');
              markMessagesAsRead(messageIds, 'cover');
            }

            //set RGB 
            if(filteredDataRGBModule.length > 0){
              const latestDataRGBModule = filteredDataRGBModule[filteredDataRGBModule.length - 1];
              const { id, payload } = latestDataRGBModule;
              const { pop_up, payload_type, message } = payload
              const colorMap = {
                off: { poolColorState: 1, color: '#cccccc', rgb_blue: 0, rgb_green: 0, rgb_red: 0 },
                red: { poolColorState: 2, color: '#ff0000', rgb_blue: 0, rgb_green: 0, rgb_red: 100 },
                green: { poolColorState: 3, color: '#00ff00', rgb_blue: 0, rgb_green: 100, rgb_red: 0 },
                blue: { poolColorState: 4, color: '#0000ff', rgb_blue: 100, rgb_green: 0, rgb_red: 0 },
                pink: { poolColorState: 5, color: '#ff00ff', rgb_blue: 100, rgb_green: 0, rgb_red: 100 },
                yellow: { poolColorState: 6, color: '#ffff00', rgb_blue: 0, rgb_green: 100, rgb_red: 100 },
                aqua: { poolColorState: 7, color: '#00ffff', rgb_blue: 100, rgb_green: 100, rgb_red: 0 },
                white: { poolColorState: 8, color: '#ffffff', rgb_blue: 100, rgb_green: 100, rgb_red: 100 }
              };
  
              const colorData = colorMap[message];
              if(colorData.poolColorState == 1)
              {
                hexStatusLightRef.current.textContent = "OFF";
              }
              else{
                hexStatusLightRef.current.textContent = "ON";
              }
              currentColor.current = colorData.poolColorState;
              $("#hexlight").attr("data-lightstate", colorData.poolColorState);
              $(".hexart-bs path").css("fill", colorData.color);
              $(".hexlt-btn").css("background", colorData.color);

              const data = {
                start_datetime: "",
                end_datetime: "",
                rgb_red: colorData.rgb_red,
                rgb_green: colorData.rgb_green,
                rgb_blue: colorData.rgb_blue,
              };

              const putUrl = `https://84ro5mdxeg.execute-api.us-east-2.amazonaws.com/prod/rgb/${adminID}`;
              fetch(putUrl, {
                method: "PUT",
                headers: {
                  Accept: "application/json",
                  "Content-Type": "application/json",
                },
                body: JSON.stringify(data),
              });
              const messageIds = filteredDataRGBModule.map((item) => item.id).join(',');
              markMessagesAsRead(messageIds, 'rgb');
            }
           
          }
        })
        .catch((error) => {
          console.error(error);
        });
    };
  
    const markMessagesAsRead = (messageIds, type) => {
      fetch(`https://84ro5mdxeg.execute-api.us-east-2.amazonaws.com/prod/message/markAsRead?messageIds=${messageIds}`, {
        method: 'PUT'
      })
        .then((response) => response.json())
        .then((data) => {
          console.log('Messages marked as read:', data);
          // if(type == 'notification_general')
          // {
          //   // Refresh the page
          //   window.location.reload();
          // }
          navigate("/dashboard");
        })
        .catch((error) => {
          console.error('Error marking messages as read:', error);
        });
    };

    // Function to handle clearing all notifications
    const handleClearNotifications = () => {
      // Get all message IDs
      const messageIds = Noti.map((n) => n.id);
      // Mark messages as read
      markMessagesAsRead(messageIds, 'notification_general');
    };

    useEffect(() => {
      fetchInitialDeviceStatus();
    },[]);

    const fetchInitialDeviceStatus = () => {
      fetch(`https://84ro5mdxeg.execute-api.us-east-2.amazonaws.com/prod/devicestatus/getdevicestatusbyadmin/${adminID}`)
      .then((response) => response.json())
        .then((result) => {
          const {status} = result.Data;
          console.log('hereeeeee device status');
          if(status == 0){
            clickCallsRef.current = 0;
            $(".dashstatus-cover").text(' OFF');
            $(".dashstatus-light").text(' OFF');
            $(".dashstatus-pumping").text(' OFF');
          }
          else{
            clickCallsRef.current = 1;
            $(".dashstatus-cover").text(' ON');
            $(".dashstatus-light").text(' ON');
            $(".dashstatus-pumping").text(' ON');
          }
          
        });
    }

    useEffect(() => {
      fetchInitialCover();
  }, []);

  const fetchInitialCover = () =>{
    fetch(`https://84ro5mdxeg.execute-api.us-east-2.amazonaws.com/prod/cover/getcoversbyadmin/${adminID}`)
    .then((response) => response.json())
      .then((result) => {
        const { coverStartTime, coverPauseTime, coverEndTime, mode , subMode, pastMode, duration } = result.Data;
        const currentTime = new Date();
        var pastModeObj = 0;
        if (coverEndTime && currentTime > new Date(coverEndTime)) {
          //cover module setting
          $(".progfill").stop();
          $(".panelcard-pc").attr("data-pcstate", 'off');
          $(".panelcard-pc").attr("data-pconoff", 'on');
          $(".coverprog, .covertime").fadeOut();
          $(".panelcard-pc").attr("data-pcinprog", '0'); // Set data-pcinprog value to 0
          $("#hexcover-state").text("OFF");
          $(".hexbars-over").removeClass("on");
          $(".hexbars-over").removeClass("on paused");
          $("#poolcover-on").find(".coverbtn-pl").removeClass('enable');
          $("#poolcover-on").find(".coverbtn-pa").removeClass('enable');
          $(".hexbars-over").removeClass("off");
          $(".hexbars-over").removeClass("off paused");
          $("#poolcover-off").find(".coverbtn-pl").removeClass('enable');
          $("#poolcover-off").find(".coverbtn-pa").removeClass('enable');
          $(".panelcard-pc").attr("data-pcprog", 0);
          // Reset progress bar to zero
          $(".progfill").css("width", '0%');
          $(".progcount").text('0%');
          //cover api data req obj
          if(mode == 1)
          {
            currentModeRef.current = 0;
            pastModeRef.current = 1;
            pastModeObj = 1;
            $(".hexbars-over").addClass("on");
            setTimeout(() => {
              // Add the paused class to pause the animation at 20% height
              $(".hexbars-over").addClass("paused");
            }, 3200); 
          }
          if(mode == 2)
          {
            currentModeRef.current = 0;
            pastModeRef.current = 0;
            pastModeObj = 2;
          }
          const coverData = {
            coverStartTime: "",
            coverPauseTime: "",
            coverEndTime: "",
            mode: 0,
            subMode: 0,
            pastMode: pastModeObj,
            duration: 0
          };
        
          //update cover info
          const coverUrl = `https://84ro5mdxeg.execute-api.us-east-2.amazonaws.com/prod/cover/${adminID}`;
        
          fetch(coverUrl, {
            method: "PUT",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
            },
            body: JSON.stringify(coverData),
          });
          
        }
        if(mode == 1){
          if(subMode == 1){
            const startDate = new Date(coverStartTime);
           
            const completedTimeInMilliseconds = currentTime.getTime() - startDate.getTime();
            // Calculate remaining minutes and seconds
            const completedMinutes = (completedTimeInMilliseconds / 60000);
            coveronminutesRemainingRef.current = 60 - completedMinutes;
            handleSchedDBCoverOn();
          }
          else if(subMode == 3)
          {
           
            coveronminutesRemainingRef.current = duration;
            handleSchedDBCoverOn();
          }
          else if(subMode == 2){
            currentModeRef.current = 1;
            pastModeRef.current = 0;
            // Progress bar
            $(".coverprog, .covertime").fadeIn();

            $(".panelcard-pc").attr("data-pcinprog", '1');
            $(".panelcard-pc").attr("data-pcstate", 'pause');
            $("#hexcover-state").text("Covering Paused");
            $("#poolcover-on").find(".coverbtn-pl").removeClass("enable");
            $("#poolcover-on").find(".coverbtn-pa").addClass("enable");
            $(".hexbars-over").addClass("on");
            setTimeout(() => {
                $(".hexbars-over").addClass("paused");
            }, 1100);
           
            coverontotalDurationRef.current = 60;

            coveronminutesCompletedRef.current = (coverontotalDurationRef.current - duration);

            var initialProgress = (coveronminutesCompletedRef.current / coverontotalDurationRef.current) * 100;
            
            $(".panelcard-pc").attr("data-pcprog", initialProgress.toFixed(0));
            $(".progfill").css("width", initialProgress + "%");  // Set the initial progress bar width
            $(".progcount").text(initialProgress.toFixed(0) + "%"); // Set the initial progress percentage
            $("#cover-livetime").text(duration.toFixed(0) + ' min.');
            $(".progfill").stop();

            // Calculate remaining minutes
            coveronminutesRemainingRef.current = duration;
          }
        }
        else if(mode == 2)
        {
          if(subMode == 1){
            const startDate = new Date(coverStartTime);
            const completedTimeInMilliseconds = currentTime.getTime() - startDate.getTime();
            // Calculate remaining minutes and seconds
            const completedMinutes = (completedTimeInMilliseconds / 60000);
            coveroffminutesRemainingRef.current = 120 - completedMinutes;
            handleSchedDBCoverOff(); 
          }
          else if(subMode == 3){
            coveroffminutesRemainingRef.current = duration;
            handleSchedDBCoverOff();
          }
          else if(subMode == 2){
            currentModeRef.current = 0;
            pastModeRef.current = 0;
            // Progress bar
            $(".coverprog, .covertime").fadeIn();

            $(".panelcard-pc").attr("data-pcinprog", '1');
            $(".panelcard-pc").attr("data-pcstate", 'pause');
            $("#hexcover-state").text("Removal Paused");
            $("#poolcover-off").find(".coverbtn-pl").removeClass("enable");
            $("#poolcover-off").find(".coverbtn-pa").addClass("enable");
            $(".hexbars-over").addClass("off paused");
          
            coverofftotalDurationRef.current = 120;

            coveroffminutesCompletedRef.current = (coverofftotalDurationRef.current - duration);

            var initialProgress = (coveroffminutesCompletedRef.current / coverofftotalDurationRef.current) * 100;
            
            $(".panelcard-pc").attr("data-pcprog", initialProgress.toFixed(0));
            $(".progfill").css("width", initialProgress + "%");  // Set the initial progress bar width
            $(".progcount").text(initialProgress.toFixed(0) + "%"); // Set the initial progress percentage
            $(".progfill").stop();

            // Calculate remaining minutes
            coveroffminutesRemainingRef.current = duration;
          }
        }
        else if(mode == 0){
          if(pastMode == 0){
            currentModeRef.current = 0;
            pastModeRef.current = 0;
            $("#hexcover-state").text("OFF");
           
          }
          if(pastMode == 1)
          {
            currentModeRef.current = 0;
            pastModeRef.current = 1;
            $("#hexcover-state").text("ON");
            $(".hexbars-over").addClass("on");
            setTimeout(() => {
              // Add the paused class to pause the animation at 20% height
              $(".hexbars-over").addClass("paused");
            }, 3200); 
          }
          if(pastMode == 2)
          {
            currentModeRef.current = 0;
            pastModeRef.current = 0;
            $("#hexcover-state").text("OFF");
          }
          
        }
      });
  }

    //covering
    const CoverDBMsgUpdate = (coverData, publishData) => {
        return new Promise((resolve, reject) => {
          //update cover info
          const coverUrl = `https://84ro5mdxeg.execute-api.us-east-2.amazonaws.com/prod/cover/${adminID}`;
          //publish message
          const messageUrl = `https://84ro5mdxeg.execute-api.us-east-2.amazonaws.com/prod/message/publish`;
      
          fetch(coverUrl, {
            method: "PUT",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
            },
            body: JSON.stringify(coverData),
          })
            .then((res) => res.json())
            .then((covOn) => {
              if (covOn.Status === 200) {
                return fetch(messageUrl, {
                  method: "POST",
                  headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                  },
                  body: JSON.stringify(publishData),
                });
              } else {
                throw new Error("Cover update failed");
              }
            })
            .then((res) => res.json())
            .then((covOn) => {
              if (covOn.Status === 200) {
                resolve(); // Resolve the promise if both calls are successful
              } else {
                throw new Error("Publish message failed");
              }
            })
            .catch((error) => {
              reject(error); // Reject the promise if any error occurs
            });
        });
      };

    const  handleTurnOffNoti = useCallback((e) => {
      $(".progfill").stop();
      $(".panelcard-pc").attr("data-pcstate", 'off');
      $(".panelcard-pc").attr("data-pconoff", 'on');
      //$(".coverprog, .covertime").delay(2000).fadeOut();
      $(".panelcard-pc").attr("data-pcinprog", '0'); // Set data-pcinprog value to 0
      $(".panelcard-pc").attr("data-pcprog", '0'); // Set data-pcinprog value to 0
      $("#hexcover-state").text("OFF");
      $(".hexbars-over").removeClass("on");
      $(".hexbars-over").removeClass("on paused");
      $("#poolcover-on").find(".coverbtn-pl").removeClass('enable');
      $("#poolcover-on").find(".coverbtn-pa").removeClass('enable');
      $(".hexbars-over").removeClass("off");
      $(".hexbars-over").removeClass("off paused");
      $("#poolcover-off").find(".coverbtn-pl").removeClass('enable');
      $("#poolcover-off").find(".coverbtn-pa").removeClass('enable');
      // Reset progress bar to zero
      $(".progfill").css("width", '0%');
      $(".progcount").text('0%');
      currentModeRef.current = 0;
      pastModeRef.current = 0;

      const payload = {
          UID : UID,
          mode:"off",
          duration_min:60,
          action: "start"
      };

      const publishPayload = {
          topic: "hex/fromapp/mode/"+UID,
          payload: JSON.stringify(payload),
          };

        const publishUrl = "https://84ro5mdxeg.execute-api.us-east-2.amazonaws.com/prod/message/publish";

      fetch(publishUrl, {
          method: "POST",
          headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
          },
          body: JSON.stringify(publishPayload),
          });

          closeModalNoti();
    }, []);  

   const handleRestartNoti = useCallback((e) => {

      $(".progfill").stop();
      $(".panelcard-pc").attr("data-pcstate", 'off');
      $(".panelcard-pc").attr("data-pconoff", 'on');
      //$(".coverprog, .covertime").delay(2000).fadeOut();
      $(".panelcard-pc").attr("data-pcinprog", '0'); // Set data-pcinprog value to 0
      $(".panelcard-pc").attr("data-pcprog", '0'); // Set data-pcinprog value to 0
      $("#hexcover-state").text("OFF");
      $(".hexbars-over").removeClass("on");
      $(".hexbars-over").removeClass("on paused");
      $("#poolcover-on").find(".coverbtn-pl").removeClass('enable');
      $("#poolcover-on").find(".coverbtn-pa").removeClass('enable');
      $(".hexbars-over").removeClass("off");
      $(".hexbars-over").removeClass("off paused");
      $("#poolcover-off").find(".coverbtn-pl").removeClass('enable');
      $("#poolcover-off").find(".coverbtn-pa").removeClass('enable');
      // Reset progress bar to zero
      $(".progfill").css("width", '0%');
      $(".progcount").text('0%');

      $("#poolcover-on").find(".coverbtn-pl").addClass("enable");
      $("#hexcover-state").text("Covering");
      $(".panelcard-pc").attr("data-pconoff", 'on');
    
      coverontotalDurationRef.current = 60;
      coveronminutesCompletedRef.current = 0;
      coveronminutesRemainingRef.current = 0;
      currentModeRef.current = 0;
      pastModeRef.current = 0;

      // Reset progress bar to zero
      $(".progfill").css("width", '0%');
      $(".progcount").text('0%');

      // Covering On Continuous Animation

      // Progress bar
      $(".coverprog, .covertime").fadeIn();

      $(".progfill").stop().animate({
        width: '100%'
      }, {
        duration: parseFloat(coverontotalDurationRef.current * 1000 * 60),
        easing: "linear",
        progress: function(promise, progress, ms) {
          var percentage = Math.floor(progress * 100); // Floor the progress value to the nearest integer
          $(".progcount").text(percentage + '%');
          $(".panelcard-pc").attr("data-pcprog", percentage + '%');
          // Update HTML Configs
          $(".panelcard-pc").attr("data-pcinprog", '1');
          $(".panelcard-pc").attr("data-pcstate", 'play');
          // Update UI
          $("#poolcover-off").removeClass("disabled");

          coveronminutesCompletedRef.current =(coverontotalDurationRef.current * progress); // Remove Math.floor() to keep it as a floating-point value
          coveronminutesRemainingRef.current =(coverontotalDurationRef.current - coveronminutesCompletedRef.current);
          var calcRealTime = Math.ceil(coveronminutesRemainingRef.current);
          $("#cover-livetime").text(calcRealTime.toFixed(0) + ' min.');
        },
        complete: function() {
          $(".panelcard-pc").attr("data-pcstate", 'off');
          $(".panelcard-pc").attr("data-pconoff", 'on');
          $(".coverprog, .covertime").delay(2000).fadeOut();
          $(".panelcard-pc").attr("data-pcinprog", '0'); // Set data-pcinprog value to 0
          $("#hexcover-state").text("ON");
          $(".hexbars-over").removeClass("on");
          $("#poolcover-on").find(".coverbtn-pl").removeClass('enable');
         
          //cover api data req obj
           const coverData = {
            coverStartTime: "",
            coverPauseTime: "",
            coverEndTime: "",
            mode: 0,
            subMode: 0,
            pastMode: 1,
            duration: 0
          };
        
           //update cover info
          const coverUrl = `https://84ro5mdxeg.execute-api.us-east-2.amazonaws.com/prod/cover/${adminID}`;
        
          fetch(coverUrl, {
            method: "PUT",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
            },
            body: JSON.stringify(coverData),
          }).then(response => {
            if (response.ok) {
              // API call was successful
              currentModeRef.current = 0;
              pastModeRef.current = 1;
        
              // Additional code to be executed after animation complete
              $(".hexbars-over").addClass("on");
        
              setTimeout(() => {
                $(".hexbars-over").addClass("paused");
              }, 3200);
            }
          });
        }
      });

      //publish msg request obj
      const publishPayload = {
        UID: UID,
        mode: "cover_on",
        duration_min: 60,
        action: "start"
      };
      const publishData = {
        topic: "hex/fromapp/mode/"+UID,
        payload: JSON.stringify(publishPayload)
      };

      //24 hours time format 
      const startTime = new Date();
      const startTimeFormatted = startTime.toLocaleString('en-US', {
        hour12: false,
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit'
      }).replace(',', '');
      const endTime = new Date(startTime.getTime() + 60 * 60000); // Adding 30 minutes in milliseconds
      const endTimeFormatted = endTime.toLocaleString('en-US', {
        hour12: false,
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit'
      }).replace(',', '');

      //cover api data req obj
      const coverData = {
        coverStartTime: startTimeFormatted,
        coverPauseTime: "",
        coverEndTime: endTimeFormatted,
        mode: 1,
        subMode: 1,
        pastMode: 0,
        duration: 60
      };

      CoverDBMsgUpdate(coverData, publishData)
        .then(() => {
          $(".hexbars-over").removeClass("off paused").addClass("on");
          $("#poolcover-off").find(".coverbtn-pl").removeClass('enable');
          $("#poolcover-off").find(".coverbtn-pa").removeClass('enable');
          $("#poolcover-off").removeClass('disabled');
        })
        .catch((error) => {
          // Handle error here
          console.error(error);
        });
      closeModalNoti();
  },[]);

  const handleSchedDBCoverOn = useCallback((e) => {
    
    $(".progfill").stop();
    $(".panelcard-pc").attr("data-pcstate", 'off');
    $(".panelcard-pc").attr("data-pconoff", 'on');
    //$(".coverprog, .covertime").fadeOut();
    $(".panelcard-pc").attr("data-pcinprog", '0'); // Set data-pcinprog value to 0
    $(".panelcard-pc").attr("data-pcprog", '0'); // Set data-pcinprog value to 0
    $("#hexcover-state").text("OFF");
    $(".hexbars-over").removeClass("on");
    $(".hexbars-over").removeClass("on paused");
    $("#poolcover-on").find(".coverbtn-pl").removeClass('enable');
    $("#poolcover-on").find(".coverbtn-pa").removeClass('enable');
    $(".hexbars-over").removeClass("off");
    $(".hexbars-over").removeClass("off paused");
    $("#poolcover-off").find(".coverbtn-pl").removeClass('enable');
    $("#poolcover-off").find(".coverbtn-pa").removeClass('enable');


     // Reset progress bar to zero
     $(".progfill").css("width", '0%');
     $(".progcount").text('0%');

    $("#hexcover-state").text("Covering on");
    $("#poolcover-on").find(".coverbtn-pl").addClass("enable");

    // Progress bar
    $(".coverprog, .covertime").fadeIn();
    $(".hexbars-over").addClass("on");
    $("#poolcover-off").removeClass('disabled');

    currentModeRef.current = 0;
    pastModeRef.current = 0;
  
    coverontotalDurationRef.current = 60; // Total duration in minutes
    
    coveronminutesCompletedRef.current = (coverontotalDurationRef.current - coveronminutesRemainingRef.current);

    console.log(coveronminutesCompletedRef.current);
    
    var initialProgress = (coveronminutesCompletedRef.current / coverontotalDurationRef.current) * 100;
    var initialTimeRemaining = coverontotalDurationRef.current - coveronminutesCompletedRef.current;
    
    $(".progfill").css("width", initialProgress + "%");  // Set the initial progress bar width
    $(".progcount").text(initialProgress.toFixed(0) + "%"); // Set the initial progress percentage
    
    $(".progfill").stop().animate({
        width: '100%'
    }, {
        duration: parseFloat(coveronminutesRemainingRef.current * 1000 * 60),
        easing: "linear",
        progress: function(promise, progress, ms) {
            var currentProgress = initialProgress + (progress * (100 - initialProgress));
            var progressPercentage = Math.floor(currentProgress); // Round down the progress percentage
            $(".progcount").text(progressPercentage + '%');
            $(".panelcard-pc").attr("data-pcprog", progressPercentage + '%');
             //Update HTML Configs
             $(".panelcard-pc").attr("data-pcinprog", '1');
             $(".panelcard-pc").attr("data-pcstate", 'play');
             //Update UI
             $("#poolcover-on").removeClass("disabled");
            coveronminutesRemainingRef.current = ((initialTimeRemaining - (initialTimeRemaining * progress))); // Round up the time remaining
            coveronminutesCompletedRef.current = coverontotalDurationRef.current - coveronminutesRemainingRef.current;
            var adjustedTimeRemaining = Math.ceil(initialTimeRemaining - (initialTimeRemaining * progress)); // Round up the time remaining
            $("#cover-livetime").text(adjustedTimeRemaining.toFixed(0) + ' min.');
        },
        complete: function() {
            $(".panelcard-pc").attr("data-pcstate", 'off');
            $(".panelcard-pc").attr("data-pconoff", 'on');
            $(".coverprog, .covertime").delay(2000).fadeOut();
            $(".panelcard-pc").attr("data-pcinprog", '0'); // Set data-pcinprog value to 0
            $("#hexcover-state").text("ON");
            $(".hexbars-over").removeClass("on");
            $("#poolcover-on").find(".coverbtn-pl").removeClass('enable');
            //cover api data req obj
           const coverData = {
            coverStartTime: "",
            coverPauseTime: "",
            coverEndTime: "",
            mode: 0,
            subMode: 0,
            pastMode: 1,
            duration: 0
          };
        
           //update cover info
          const coverUrl = `https://84ro5mdxeg.execute-api.us-east-2.amazonaws.com/prod/cover/${adminID}`;
        
          fetch(coverUrl, {
            method: "PUT",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
            },
            body: JSON.stringify(coverData),
          }).then(response => {
            if (response.ok) {
              // API call was successful
              currentModeRef.current = 0;
              pastModeRef.current = 1;
        
              // Additional code to be executed after animation complete
              $(".hexbars-over").addClass("on");
        
              setTimeout(() => {
                $(".hexbars-over").addClass("paused");
              }, 3200);
            }
          });
        }
    })
  }, []);

  const handleSchedDBCoverOff = useCallback((e) => {
    
    $(".progfill").stop();
    $(".panelcard-pc").attr("data-pcstate", 'off');
    $(".panelcard-pc").attr("data-pconoff", 'on');
    //$(".coverprog, .covertime").fadeOut();
    $(".panelcard-pc").attr("data-pcinprog", '0'); // Set data-pcinprog value to 0
    $(".panelcard-pc").attr("data-pcprog", '0'); // Set data-pcinprog value to 0
    $("#hexcover-state").text("OFF");
    $(".hexbars-over").removeClass("on");
    $(".hexbars-over").removeClass("on paused");
    $("#poolcover-on").find(".coverbtn-pl").removeClass('enable');
    $("#poolcover-on").find(".coverbtn-pa").removeClass('enable');
    $(".hexbars-over").removeClass("off");
    $(".hexbars-over").removeClass("off paused");
    $("#poolcover-off").find(".coverbtn-pl").removeClass('enable');
    $("#poolcover-off").find(".coverbtn-pa").removeClass('enable');

    // Reset progress bar to zero
    $(".progfill").css("width", '0%');
    $(".progcount").text('0%');



    $("#hexcover-state").text("Removing Cover");
    $("#poolcover-off").find(".coverbtn-pl").addClass("enable");
    // Progress bar
    $(".coverprog, .covertime").fadeIn();
    $(".hexbars-over").addClass("off");
    $("#poolcover-on").removeClass('disabled');
  
    coverofftotalDurationRef.current = 120; // Total duration in minutes
    
    coveroffminutesCompletedRef.current = (coverofftotalDurationRef.current - coveroffminutesRemainingRef.current);
    
    var initialProgress = (coveroffminutesCompletedRef.current / coverofftotalDurationRef.current) * 100;
    var initialTimeRemaining = coverofftotalDurationRef.current - coveroffminutesCompletedRef.current;
    currentModeRef.current = 0;
    pastModeRef.current = 0;
    
    $(".progfill").css("width", initialProgress + "%");  // Set the initial progress bar width
    $(".progcount").text(initialProgress.toFixed(0) + "%"); // Set the initial progress percentage
    
    $(".progfill").stop().animate({
        width: '100%'
    }, {
        duration: parseFloat(coveroffminutesRemainingRef.current * 1000 * 60),
        easing: "linear",
        progress: function(promise, progress, ms) {
            var currentProgress = initialProgress + (progress * (100 - initialProgress));
            var progressPercentage = Math.floor(currentProgress); // Round down the progress percentage
            $(".progcount").text(progressPercentage + '%');
            $(".panelcard-pc").attr("data-pcprog", progressPercentage + '%');
             //Update HTML Configs
             $(".panelcard-pc").attr("data-pcinprog", '1');
             $(".panelcard-pc").attr("data-pcstate", 'play');
             //Update UI
             $("#poolcover-on").removeClass("disabled");
            coveroffminutesRemainingRef.current = ((initialTimeRemaining - (initialTimeRemaining * progress))); // Round up the time remaining
            coveroffminutesCompletedRef.current = coverofftotalDurationRef.current - coveroffminutesRemainingRef.current;
            var adjustedTimeRemaining = Math.ceil(initialTimeRemaining - (initialTimeRemaining * progress)); // Round up the time remaining
            $("#cover-livetime").text(adjustedTimeRemaining.toFixed(0) + ' min.');
        },
        complete: function() {
            currentModeRef.current = 0;
            pastModeRef.current = 0;
            $(".panelcard-pc").attr("data-pcstate", 'off');
            $(".panelcard-pc").attr("data-pconoff", 'on');
            $(".coverprog, .covertime").delay(2000).fadeOut();
            $(".panelcard-pc").attr("data-pcinprog", '0'); // Set data-pcinprog value to 0
            $("#hexcover-state").text("OFF");
            $(".hexbars-over").removeClass("off");
            $("#poolcover-on").find(".coverbtn-pl").removeClass('enable');
            //cover api data req obj
            const coverData = {
              coverStartTime: "",
              coverPauseTime: "",
              coverEndTime: "",
              mode: 0,
              subMode: 0,
              pastMode: 2,
              duration: 0
            };
          
            //update cover info
            const coverUrl = `https://84ro5mdxeg.execute-api.us-east-2.amazonaws.com/prod/cover/${adminID}`;
          
            fetch(coverUrl, {
              method: "PUT",
              headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
              },
              body: JSON.stringify(coverData),
            });
        }
    });
  }, []);


    const handleConfirmationCoverOff = useCallback((e) => {
      if(clickCallsRef.current == 1){

        $(".progfill").stop();
        $(".panelcard-pc").attr("data-pcstate", 'off');
        $(".panelcard-pc").attr("data-pconoff", 'on');
        //$(".coverprog, .covertime").fadeOut();
        $(".panelcard-pc").attr("data-pcinprog", '0'); // Set data-pcinprog value to 0
        $(".panelcard-pc").attr("data-pcprog", '0'); // Set data-pcinprog value to 0
        $("#hexcover-state").text("OFF");
        $(".hexbars-over").removeClass("on");
        $(".hexbars-over").removeClass("on paused");
        $("#poolcover-on").find(".coverbtn-pl").removeClass('enable');
        $("#poolcover-on").find(".coverbtn-pa").removeClass('enable');
        $(".hexbars-over").removeClass("off");
        $(".hexbars-over").removeClass("off paused");
        $("#poolcover-off").find(".coverbtn-pl").removeClass('enable');
        $("#poolcover-off").find(".coverbtn-pa").removeClass('enable');
        // Reset progress bar to zero
        $(".progfill").css("width", '0%');
        $(".progcount").text('0%');
        
        // Close the modal
        closeModalCoverOff();
        
        $("#hexcover-state").text("Removing Cover");
        $("#poolcover-off").find(".coverbtn-pl").addClass("enable");
        
        currentModeRef.current = 0;
        pastModeRef.current = 0;

        coverofftotalDurationRef.current = 120; // Total duration in minutes
        coveroffminutesCompletedRef.current = 0;
        coveroffminutesRemainingRef.current = coverofftotalDurationRef.current;

        // Reset progress bar to zero
        $(".progfill").css("width", '0%');
        $(".progcount").text('0%');

        // Covering On Continuous Animation

        // Progress bar
        $(".coverprog, .covertime").fadeIn();

        $(".progfill").stop().animate({
          width: '100%'
        }, {
          duration: parseFloat(coverofftotalDurationRef.current * 1000 * 60),
          easing: "linear",
          progress: function(promise, progress, ms) {
            var percentage = Math.floor(progress * 100); // Floor the progress value to the nearest integer
            $(".progcount").text(percentage + '%');
            $(".panelcard-pc").attr("data-pcprog", percentage + '%');
            // Update HTML Configs
            $(".panelcard-pc").attr("data-pcinprog", '1');
            $(".panelcard-pc").attr("data-pcstate", 'play');
            // Update UI
            $("#poolcover-on").removeClass("disabled");

            coveroffminutesCompletedRef.current = (coverofftotalDurationRef.current * progress); // Remove Math.floor() to keep it as a floating-point value
            coveroffminutesRemainingRef.current = (coverofftotalDurationRef.current - coveroffminutesCompletedRef.current);

            var calcRealTime = Math.ceil(coveroffminutesRemainingRef.current);
            $("#cover-livetime").text(calcRealTime.toFixed(0) + ' min.');
          },
          complete: function() {
            currentModeRef.current = 0;
            pastModeRef.current = 0;
            $(".panelcard-pc").attr("data-pcstate", 'off');
            $(".panelcard-pc").attr("data-pconoff", 'on');
            $(".coverprog, .covertime").delay(2000).fadeOut();
            $(".panelcard-pc").attr("data-pcinprog", '0'); // Set data-pcinprog value to 0
            $("#hexcover-state").text("OFF");
            $(".hexbars-over").removeClass("off");
            $("#poolcover-off").find(".coverbtn-pl").removeClass('enable');
            //cover api data req obj
            const coverData = {
              coverStartTime: "",
              coverPauseTime: "",
              coverEndTime: "",
              mode: 0,
              subMode: 0,
              pastMode: 2,
              duration: 0
            };
          
            //update cover info
            const coverUrl = `https://84ro5mdxeg.execute-api.us-east-2.amazonaws.com/prod/cover/${adminID}`;
          
            fetch(coverUrl, {
              method: "PUT",
              headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
              },
              body: JSON.stringify(coverData),
            });
          }
        });

        //publish msg request obj
        const publishPayload = {
          UID: UID,
          mode: "cover_off",
          duration_min: 120,
          action: "start"
        };
        const publishData = {
          topic: "hex/fromapp/mode/"+UID,
          payload: JSON.stringify(publishPayload)
        };

        //24 hours time format 
        const startTime = new Date();
        const startTimeFormatted = startTime.toLocaleString('en-US', {
          hour12: false,
          year: 'numeric',
          month: '2-digit',
          day: '2-digit',
          hour: '2-digit',
          minute: '2-digit',
          second: '2-digit'
        }).replace(',', '');
        const endTime = new Date(startTime.getTime() + 120 * 60000); // Adding 30 minutes in milliseconds
        const endTimeFormatted = endTime.toLocaleString('en-US', {
          hour12: false,
          year: 'numeric',
          month: '2-digit',
          day: '2-digit',
          hour: '2-digit',
          minute: '2-digit',
          second: '2-digit'
        }).replace(',', '');

        //cover api data req obj
        const coverData = {
          coverStartTime: startTimeFormatted,
          coverPauseTime: "",
          coverEndTime: endTimeFormatted,
          mode: 1,
          subMode: 1,
          pastMode: 0,
          duration: 120
        };

        CoverDBMsgUpdate(coverData, publishData)
        .then(() => {
            $(".hexbars-over").removeClass("on paused").addClass("off");
            $("#poolcover-on").find(".coverbtn-pl").removeClass('enable');
            $("#poolcover-on").find(".coverbtn-pa").removeClass('enable');
            $("#poolcover-on").removeClass('disabled');
        })
        .catch((error) => {
            // Handle error here
            console.error(error);
        });
      }
       
    }, []);

      

    const handleConfirmationCoverOn = useCallback((e) => {
       if(clickCallsRef.current == 1)
       {  
        
        $(".progfill").stop();
        $(".panelcard-pc").attr("data-pcstate", 'off');
        $(".panelcard-pc").attr("data-pconoff", 'on');
       // $(".coverprog, .covertime").delay(2000).fadeOut();
        $(".panelcard-pc").attr("data-pcinprog", '0'); // Set data-pcinprog value to 0
        $(".panelcard-pc").attr("data-pcprog", '0'); // Set data-pcinprog value to 0
        $("#hexcover-state").text("OFF");
        $(".hexbars-over").removeClass("on");
        $(".hexbars-over").removeClass("on paused");
        $("#poolcover-on").find(".coverbtn-pl").removeClass('enable');
        $("#poolcover-on").find(".coverbtn-pa").removeClass('enable');
        $(".hexbars-over").removeClass("off");
        $(".hexbars-over").removeClass("off paused");
        $("#poolcover-off").find(".coverbtn-pl").removeClass('enable');
        $("#poolcover-off").find(".coverbtn-pa").removeClass('enable');
      
        $("#poolcover-on").find(".coverbtn-pl").addClass("enable");
        $("#hexcover-state").text("Covering");
    
        coverontotalDurationRef.current = 60; // Total duration in minutes
        coveronminutesCompletedRef.current = 0;
        coveronminutesRemainingRef.current = coverontotalDurationRef.current;

        currentModeRef.current = 0;
        pastModeRef.current = 0;
    
        // Reset progress bar to zero
        $(".progfill").css("width", "0%");
        $(".progcount").text("0%");
    
        // Covering On Continuous Animation
    
        // Progress bar
        $(".coverprog, .covertime").fadeIn();
    
        $(".progfill").stop().animate(
          {
            width: "100%",
          },
          {
            duration: parseFloat(coverontotalDurationRef.current * 1000 * 60),
            easing: "linear",
            progress: function (promise, progress, ms) {
              const percentage = Math.floor(progress * 100); // Floor the progress value to the nearest integer
              $(".progcount").text(percentage + "%");
              $(".panelcard-pc").attr("data-pcprog", percentage + "%");
              // Update HTML Configs
              $(".panelcard-pc").attr("data-pcinprog", "1");
              $(".panelcard-pc").attr("data-pcstate", "play");
    
              // Update UI
              $("#poolcover-off").removeClass("disabled");
    
              coveronminutesCompletedRef.current = (coverontotalDurationRef.current * progress); // Remove Math.floor() to keep it as a floating-point value
              coveronminutesRemainingRef.current = (coverontotalDurationRef.current - coveronminutesCompletedRef.current);
    
              const calcRealTime = Math.ceil(coveronminutesRemainingRef.current);
              $("#cover-livetime").text(calcRealTime.toFixed(0) + " min.");
            },
            complete: function () {
              $(".panelcard-pc").attr("data-pcstate", "off");
              $(".panelcard-pc").attr("data-pconoff", "on");
              $(".coverprog, .covertime").delay(2000).fadeOut();
              $(".panelcard-pc").attr("data-pcinprog", "0"); // Set data-pcinprog value to 0
              $("#hexcover-state").text("ON");
              $(".hexbars-over").removeClass("on");
              $("#poolcover-on").find(".coverbtn-pl").removeClass("enable");
              //cover api data req obj
              const coverData = {
               coverStartTime: "",
               coverPauseTime: "",
               coverEndTime: "",
               mode: 0,
               subMode: 0,
               pastMode: 1,
               duration: 0
             };
           
              //update cover info
              const coverUrl = `https://84ro5mdxeg.execute-api.us-east-2.amazonaws.com/prod/cover/${adminID}`;
            
              fetch(coverUrl, {
                method: "PUT",
                headers: {
                  Accept: "application/json",
                  "Content-Type": "application/json",
                },
                body: JSON.stringify(coverData),
              }).then(response => {
                if (response.ok) {
                  // API call was successful
                  currentModeRef.current = 0;
                  pastModeRef.current = 1;
            
                  // Additional code to be executed after animation complete
                  $(".hexbars-over").addClass("on");
            
                  setTimeout(() => {
                    $(".hexbars-over").addClass("paused");
                  }, 3200);
                }
              });
            }
          });
    
        //publish msg request obj
        const publishPayload = {
          UID: UID,
          mode: "cover_on",
          duration_min: 60,
          action: "start",
        };
        const publishData = {
          topic: "hex/fromapp/mode/"+UID,
          payload: JSON.stringify(publishPayload),
        };
    
        //24 hours time format
        const startTime = new Date();
        const startTimeFormatted = startTime.toLocaleString("en-US", {
          hour12: false,
          year: "numeric",
          month: "2-digit",
          day: "2-digit",
          hour: "2-digit",
          minute: "2-digit",
          second: "2-digit",
        }).replace(",", "");
        const endTime = new Date(startTime.getTime() + 60 * 60000); // Adding 30 minutes in milliseconds
        const endTimeFormatted = endTime.toLocaleString("en-US", {
          hour12: false,
          year: "numeric",
          month: "2-digit",
          day: "2-digit",
          hour: "2-digit",
          minute: "2-digit",
          second: "2-digit",
        }).replace(",", "");
    
        //cover api data req obj
        const coverData = {
          coverStartTime: startTimeFormatted,
          coverPauseTime: "",
          coverEndTime: endTimeFormatted,
          mode: 1,
          subMode: 1,
          pastMode: 0,
          duration: 60,
        };
    
        CoverDBMsgUpdate(coverData, publishData)
          .then(() => {
            $(".hexbars-over").removeClass("off paused").addClass("on");
            $("#poolcover-off").find(".coverbtn-pl").removeClass("enable");
            $("#poolcover-off").find(".coverbtn-pa").removeClass("enable");
            $("#poolcover-off").removeClass("disabled");
          })
          .catch((error) => {
            // Handle error here
            console.error(error);
          });
        $("#poolcover-on .coverbtn-pl").off("click");
        closeModalCoverOn();
       }
      }, []);
    
      const handleCoverOnBtnStopCLick = useCallback((e) => {
        $(".progfill").stop();
        $(".panelcard-pc").attr("data-pcstate", 'off');
        $(".panelcard-pc").attr("data-pconoff", 'on');
        //$(".coverprog, .covertime").delay(2000).fadeOut();
        $(".panelcard-pc").attr("data-pcinprog", '0'); // Set data-pcinprog value to 0
        $(".panelcard-pc").attr("data-pcprog", 0);
        $("#hexcover-state").text("OFF");
        $(".hexbars-over").removeClass("on");
        $("#poolcover-on").find(".coverbtn-pl").removeClass('enable');
        $("#poolcover-on").find(".coverbtn-pa").removeClass("enable");
        $(".hexbars-over").removeClass("on paused");
        // Reset progress bar to zero
        $(".progfill").css("width", "0%");
        $(".progcount").text("0%");

        // $(".hexbars-over").addClass("on");
        // setTimeout(() => {
        //   // Add the paused class to pause the animation at 20% height
        //   $(".hexbars-over").addClass("paused");
        // }, 3200); 
        
        currentModeRef.current = 0;
        pastModeRef.current = 0;

        //cover api data req obj
        const coverData = {
          coverStartTime: "",
          coverPauseTime: "",
          coverEndTime: "",
          mode: 0,
          subMode: 0,
          pastMode: 1,
          duration: 0
        };
      
        //update cover info
        const coverUrl = `https://84ro5mdxeg.execute-api.us-east-2.amazonaws.com/prod/cover/${adminID}`;
      
        fetch(coverUrl, {
          method: "PUT",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify(coverData),
        });
      },[]);

      const handleCoverOnBtnDefClick = useCallback((value) => {
        if(clickCallsRef.current == 1)
        {
          
          if ($(".panelcard-pc").attr("data-pcinprog") == '0') {

            $(".progfill").stop();
            $(".panelcard-pc").attr("data-pcstate", 'off');
            $(".panelcard-pc").attr("data-pconoff", 'on');
            //$(".coverprog, .covertime").fadeOut();
            $(".panelcard-pc").attr("data-pcinprog", '0'); // Set data-pcinprog value to 0
            $("#hexcover-state").text("OFF");
            $(".hexbars-over").removeClass("on");
            $(".hexbars-over").removeClass("on paused");
            $("#poolcover-on").find(".coverbtn-pl").removeClass('enable');
            $("#poolcover-on").find(".coverbtn-pa").removeClass('enable');
            $(".hexbars-over").removeClass("off");
            $(".hexbars-over").removeClass("off paused");
            $("#poolcover-off").find(".coverbtn-pl").removeClass('enable');
            $("#poolcover-off").find(".coverbtn-pa").removeClass('enable');
            $(".panelcard-pc").attr("data-pcprog", 0);

            
            $("#poolcover-on").find(".coverbtn-pl").addClass("enable");
            $("#hexcover-state").text("Covering");

            
            
            coverontotalDurationRef.current = 60;
            coveronminutesCompletedRef.current = 0;
            coveronminutesRemainingRef.current = 0;
            currentModeRef.current = 0;
            pastModeRef.current = 0;

            // Reset progress bar to zero
            $(".progfill").css("width", '0%');
            $(".progcount").text('0%');
    
            // Covering On Continuous Animation
    
            // Progress bar
            $(".coverprog, .covertime").fadeIn();
    
            $(".progfill").stop().animate({
              width: '100%'
            }, {
              duration: parseFloat(coverontotalDurationRef.current * 1000 * 60),
              easing: "linear",
              progress: function(promise, progress, ms) {
                var percentage = Math.floor(progress * 100); // Floor the progress value to the nearest integer
                $(".progcount").text(percentage + '%');
                $(".panelcard-pc").attr("data-pcprog", percentage + '%');
                // Update HTML Configs
                $(".panelcard-pc").attr("data-pcinprog", '1');
                $(".panelcard-pc").attr("data-pcstate", 'play');
                // Update UI
                $("#poolcover-off").removeClass("disabled");
    
                coveronminutesCompletedRef.current =(coverontotalDurationRef.current * progress); // Remove Math.floor() to keep it as a floating-point value
                coveronminutesRemainingRef.current =(coverontotalDurationRef.current - coveronminutesCompletedRef.current);
                var calcRealTime = Math.ceil(coveronminutesRemainingRef.current);
                $("#cover-livetime").text(calcRealTime.toFixed(0) + ' min.');
              },
              complete: function() {
                $(".panelcard-pc").attr("data-pcstate", 'off');
                $(".panelcard-pc").attr("data-pconoff", 'on');
                $(".coverprog, .covertime").delay(2000).fadeOut();
                $(".panelcard-pc").attr("data-pcinprog", '0'); // Set data-pcinprog value to 0
                $("#hexcover-state").text("ON");
                $(".hexbars-over").removeClass("on");
                $("#poolcover-on").find(".coverbtn-pl").removeClass('enable');
               
                //cover api data req obj
                const coverData = {
                  coverStartTime: "",
                  coverPauseTime: "",
                  coverEndTime: "",
                  mode: 0,
                  subMode: 0,
                  pastMode: 1,
                  duration: 0
                };
              
                //update cover info
                const coverUrl = `https://84ro5mdxeg.execute-api.us-east-2.amazonaws.com/prod/cover/${adminID}`;
              
                fetch(coverUrl, {
                  method: "PUT",
                  headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                  },
                  body: JSON.stringify(coverData),
                }).then(response => {
                  if (response.ok) {
                    // API call was successful
                    currentModeRef.current = 0;
                    pastModeRef.current = 1;
              
                    // Additional code to be executed after animation complete
                    $(".hexbars-over").addClass("on");
              
                    setTimeout(() => {
                      $(".hexbars-over").addClass("paused");
                    }, 3200);
                  }
                });
              }
            });
    
            //publish msg request obj
            const publishPayload = {
              UID: UID,
              mode: "cover_on",
              duration_min: 60,
              action: "start"
            };
            const publishData = {
              topic: "hex/fromapp/mode/"+UID,
              payload: JSON.stringify(publishPayload)
            };
    
            //24 hours time format 
            const startTime = new Date();
            const startTimeFormatted = startTime.toLocaleString('en-US', {
              hour12: false,
              year: 'numeric',
              month: '2-digit',
              day: '2-digit',
              hour: '2-digit',
              minute: '2-digit',
              second: '2-digit'
            }).replace(',', '');
            const endTime = new Date(startTime.getTime() + 60 * 60000); // Adding 30 minutes in milliseconds
            const endTimeFormatted = endTime.toLocaleString('en-US', {
              hour12: false,
              year: 'numeric',
              month: '2-digit',
              day: '2-digit',
              hour: '2-digit',
              minute: '2-digit',
              second: '2-digit'
            }).replace(',', '');
    
            //cover api data req obj
            const coverData = {
              coverStartTime: startTimeFormatted,
              coverPauseTime: "",
              coverEndTime: endTimeFormatted,
              mode: 1,
              subMode: 1,
              pastMode: 0,
              duration: 60
            };
            CoverDBMsgUpdate(coverData, publishData)
            .then(() => {
              $(".hexbars-over").removeClass("off paused").addClass("on");
              $("#poolcover-off").find(".coverbtn-pl").removeClass('enable');
              $("#poolcover-off").find(".coverbtn-pa").removeClass('enable');
              $("#poolcover-off").removeClass('disabled');
            })
            .catch((error) => {
              // Handle error here
              console.error(error);
            });
           
          } else {
            openModalCoverOn();
          }
        }
          
        }, []);


        const handleCoverOnBtnDefNoti = useCallback((time_stamp) => {
          if ($(".panelcard-pc").attr("data-pcinprog") == '0') {
         
            // Get the current timestamp in milliseconds
            const currentTimestamp = new Date().getTime();

            // Parse the provided timestamp to a Date object
            const timestampDate = new Date(time_stamp);

            // Calculate the time difference in milliseconds
            const timeDifference = currentTimestamp - timestampDate.getTime();

            // Convert the time difference to minutes
            const minutesCompleted = Math.floor(timeDifference / (1000 * 60));

            if(minutesCompleted <= 60){

              $("#poolcover-on").find(".coverbtn-pl").addClass("enable");
              $("#hexcover-state").text("Covering");
          
              coverontotalDurationRef.current = 60;
              coveronminutesCompletedRef.current = minutesCompleted;
              coveronminutesRemainingRef.current = 60 - minutesCompleted;
              handleSchedDBCoverOn();

            }
    
            //24 hours time format 
            const startTimeFormatted = timestampDate.toLocaleString('en-US', {
              hour12: false,
              year: 'numeric',
              month: '2-digit',
              day: '2-digit',
              hour: '2-digit',
              minute: '2-digit',
              second: '2-digit'
            }).replace(',', '');
            const endTime = new Date(timestampDate.getTime() + 60 * 60000); // Adding 60 minutes in milliseconds
            const endTimeFormatted = endTime.toLocaleString('en-US', {
              hour12: false,
              year: 'numeric',
              month: '2-digit',
              day: '2-digit',
              hour: '2-digit',
              minute: '2-digit',
              second: '2-digit'
            }).replace(',', '');
    
            //cover api data req obj
            const coverData = {
              coverStartTime: startTimeFormatted,
              coverPauseTime: "",
              coverEndTime: endTimeFormatted,
              mode: 1,
              subMode: 1,
              pastMode: 0,
              duration: 60
            };
            const coverUrl = `https://84ro5mdxeg.execute-api.us-east-2.amazonaws.com/prod/cover/${adminID}`;
        
            fetch(coverUrl, {
              method: "PUT",
              headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
              },
              body: JSON.stringify(coverData),
            })
              .then((res) => res.json())
              .then((covOn) => {
                if (covOn.Status === 200) {
                  $(".hexbars-over").removeClass("off paused").addClass("on");
                  $("#poolcover-off").find(".coverbtn-pl").removeClass('enable');
                  $("#poolcover-off").find(".coverbtn-pa").removeClass('enable');
                  $("#poolcover-off").removeClass('disabled');
                }
              });
            
           
          } else {

             // Get the current timestamp in milliseconds
             const currentTimestamp = new Date().getTime();

             // Parse the provided timestamp to a Date object
             const timestampDate = new Date(time_stamp);
 
             // Calculate the time difference in milliseconds
             const timeDifference = currentTimestamp - timestampDate.getTime();
 
             // Convert the time difference to minutes
             const minutesCompleted = Math.floor(timeDifference / (1000 * 60));
 
             if(minutesCompleted <= 60){
 
               $("#poolcover-on").find(".coverbtn-pl").addClass("enable");
               $("#hexcover-state").text("Covering");
           
               coverontotalDurationRef.current = 60;
               coveronminutesCompletedRef.current = minutesCompleted;
               coveronminutesRemainingRef.current = 60 - minutesCompleted;
               handleSchedDBCoverOn();
 
             }
     
             //24 hours time format 
             const startTimeFormatted = timestampDate.toLocaleString('en-US', {
               hour12: false,
               year: 'numeric',
               month: '2-digit',
               day: '2-digit',
               hour: '2-digit',
               minute: '2-digit',
               second: '2-digit'
             }).replace(',', '');
             const endTime = new Date(timestampDate.getTime() + 60 * 60000); // Adding 60 minutes in milliseconds
             const endTimeFormatted = endTime.toLocaleString('en-US', {
               hour12: false,
               year: 'numeric',
               month: '2-digit',
               day: '2-digit',
               hour: '2-digit',
               minute: '2-digit',
               second: '2-digit'
             }).replace(',', '');
     
             //cover api data req obj
             const coverData = {
               coverStartTime: startTimeFormatted,
               coverPauseTime: "",
               coverEndTime: endTimeFormatted,
               mode: 1,
               subMode: 1,
               pastMode: 0,
               duration: 60
             };
             const coverUrl = `https://84ro5mdxeg.execute-api.us-east-2.amazonaws.com/prod/cover/${adminID}`;
         
             fetch(coverUrl, {
               method: "PUT",
               headers: {
                 Accept: "application/json",
                 "Content-Type": "application/json",
               },
               body: JSON.stringify(coverData),
             })
               .then((res) => res.json())
               .then((covOn) => {
                 if (covOn.Status === 200) {
                   $(".hexbars-over").removeClass("off paused").addClass("on");
                   $("#poolcover-off").find(".coverbtn-pl").removeClass('enable');
                   $("#poolcover-off").find(".coverbtn-pa").removeClass('enable');
                   $("#poolcover-off").removeClass('disabled');
                 }
               });
            $("#poolcover-on .coverbtn-pl").off("click");
          }
        
        }, []);
    
        const handleCoverOnBtnPlClick = useCallback((value) => {
          if(clickCallsRef.current == 1)
          {
            // Fetch cover data for the admin
            fetch(`https://84ro5mdxeg.execute-api.us-east-2.amazonaws.com/prod/cover/getcoversbyadmin/${adminID}`)
              .then((response) => response.json())
              .then((data) => {
                const { coverStartTime, duration } = data.Data;
                // Format the dates and times
                const startTime = coverStartTime;
                 
                const currentTime = new Date().toLocaleString('en-US', {
                  hour12: false,
                  year: 'numeric',
                  month: '2-digit',
                  day: '2-digit',
                  hour: '2-digit',
                  minute: '2-digit',
                  second: '2-digit',
                }).replace(',', '');
    
                // Calculate the new duration in minutes
                const spentDuration = ((new Date(currentTime) - new Date(startTime)) / (1000 * 60));
                const remainingDuration = duration - spentDuration;
                // Prepare the data for the PUT request
                const coverData = {
                  coverStartTime: "",
                  coverPauseTime: currentTime,
                  coverEndTime: "",
                  mode: 1,
                  subMode: 2,
                  pastMode: 0,
                  duration: remainingDuration,
                };
                const publishPayload = {
                  UID: UID,
                  mode: "cover_on",
                  duration_min: 0,
                  action: "pause"
                };
                const publishData = {
                  topic: "hex/fromapp/mode/"+UID,
                  payload: JSON.stringify(publishPayload)
                };
                if(value == 'cover_only'){
                  const coverUrl = `https://84ro5mdxeg.execute-api.us-east-2.amazonaws.com/prod/cover/${adminID}`;
         
                  fetch(coverUrl, {
                    method: "PUT",
                    headers: {
                      Accept: "application/json",
                      "Content-Type": "application/json",
                    },
                    body: JSON.stringify(coverData),
                  })
                    .then((res) => res.json())
                    .then((covOn) => {
                      if (covOn.Status === 200) {
                        currentModeRef.current = 1;
                        pastModeRef.current = 0;

                        $(".panelcard-pc").attr("data-pcinprog", '1');
                        $(".panelcard-pc").attr("data-pcstate", 'pause');
                        $("#hexcover-state").text("Covering Paused");
                        $("#poolcover-on").find(".coverbtn-pl").removeClass("enable");
                        $("#poolcover-on").find(".coverbtn-pa").addClass("enable");
                        $(".hexbars-over").addClass("paused");
                        $(".progfill").stop();
        
                        // Calculate remaining minutes
                        coveronminutesRemainingRef.current = (coverontotalDurationRef.current - coveronminutesCompletedRef.current);
                      }
                    });
                }
                else{
                  CoverDBMsgUpdate(coverData, publishData)
                  .then(() => {
                    // Update UI and perform necessary actions
                    $(".panelcard-pc").attr("data-pcinprog", '1');
                    $(".panelcard-pc").attr("data-pcstate", 'pause');
                    $("#hexcover-state").text("Covering Paused");
                    $("#poolcover-on").find(".coverbtn-pl").removeClass("enable");
                    $("#poolcover-on").find(".coverbtn-pa").addClass("enable");
                    $(".hexbars-over").addClass("paused");
                    currentModeRef.current = 1;
                    pastModeRef.current = 0;

                    $(".progfill").stop();
    
                    // Calculate remaining minutes
                    coveronminutesRemainingRef.current = (coverontotalDurationRef.current - coveronminutesCompletedRef.current);
                  })
                  .catch((error) => {
                    // Handle error here
                    console.error(error);
                  });
                }

                
              })
              .catch((error) => {
                // Handle error here
                console.error(error);
              });
          }
            
        }, []);
    
        const handleCoverOnBtnPaClick = useCallback((e) => {
          if(clickCallsRef.current == 1){

              $(".panelcard-pc").attr("data-pcstate", 'off');
              $(".panelcard-pc").attr("data-pconoff", 'on');
              $(".panelcard-pc").attr("data-pcinprog", '0'); // Set data-pcinprog value to 0
              $(".hexbars-over").removeClass("on");
              $(".hexbars-over").removeClass("on paused");
              $("#poolcover-on").find(".coverbtn-pl").removeClass('enable');
              $("#poolcover-on").find(".coverbtn-pa").removeClass('enable');
              $(".hexbars-over").removeClass("off");
              $(".hexbars-over").removeClass("off paused");
              $("#poolcover-off").find(".coverbtn-pl").removeClass('enable');
              $("#poolcover-off").find(".coverbtn-pa").removeClass('enable');
              
              // Fetch cover data for the admin
              fetch(`https://84ro5mdxeg.execute-api.us-east-2.amazonaws.com/prod/cover/getcoversbyadmin/${adminID}`)
              .then((response) => response.json())
              .then((data) => {
                const { duration } = data.Data;
      
                // Calculate new coverStartTime and coverEndTime
                //24 hours time format 
                const startTime = new Date();
                const startTimeFormatted = startTime.toLocaleString('en-US', {
                  hour12: false,
                  year: 'numeric',
                  month: '2-digit',
                  day: '2-digit',
                  hour: '2-digit',
                  minute: '2-digit',
                  second: '2-digit'
                }).replace(',', '');
                const endTime = new Date(startTime.getTime() + duration * 60000); // Adding 30 minutes in milliseconds
                const endTimeFormatted = endTime.toLocaleString('en-US', {
                  hour12: false,
                  year: 'numeric',
                  month: '2-digit',
                  day: '2-digit',
                  hour: '2-digit',
                  minute: '2-digit',
                  second: '2-digit'
                }).replace(',', '');
      
                const coverData = {
                  coverStartTime: startTimeFormatted,
                  coverPauseTime: "",
                  coverEndTime: endTimeFormatted,
                  mode: 1,
                  subMode: 3,
                  pastMode: 0,
                  duration: duration
                };
      
                const publishPayload = {
                  UID: UID,
                  mode: "cover_on",
                  duration_min: 0,
                  action: "start"
                };
                const publishData = {
                  topic: "hex/fromapp/mode/"+UID,
                  payload: JSON.stringify(publishPayload)
                };
      
                CoverDBMsgUpdate(coverData, publishData);
      
              })
              .catch((error) => {
                // Handle error here
                console.error(error);
              });
            // Update HTML Configs
            $(".panelcard-pc").attr("data-pcinprog", '1');
            $(".panelcard-pc").attr("data-pcstate", 'play');
      
            // Update UI
            $("#hexcover-state").text("Covering");
            $("#poolcover-on").find(".coverbtn-pl").addClass("enable");
            $("#poolcover-on").find(".coverbtn-pa").removeClass("enable");
            $(".hexbars-over").removeClass("on paused").addClass("on");
      
            // Calculate remaining milliseconds
            var coveronremainingMilliseconds = coveronminutesRemainingRef.current * 60 * 1000;

            currentModeRef.current = 0;
            pastModeRef.current = 0;
      
            // Continue Cover Progress
            $(".progfill").animate(
              {
                width: "100%"
              },
              {
                duration: coveronremainingMilliseconds,
                easing: "linear",
                step: function(now, fx) {
                  var progress = now / fx.end;
                  var progressPercentage = Math.floor(progress * 100);
                  var coveronelapsedTime = progress * coverontotalDurationRef.current;
                  var coveronremainingTime = coverontotalDurationRef.current - coveronelapsedTime;
                  coveronminutesCompletedRef.current = coveronelapsedTime;
                  coveronminutesRemainingRef.current = coveronremainingTime;
                  $(".progcount").text(progressPercentage + "%");
                  $(".panelcard-pc").attr("data-pcprog", progressPercentage + '%');
                  $("#cover-livetime").text(coveronremainingTime.toFixed(0) + ' min.');
                },
                complete: function() {
                  $(".panelcard-pc").attr("data-pcstate", 'off');
                  $(".panelcard-pc").attr("data-pconoff", 'on');
                  $(".coverprog, .covertime").delay(2000).fadeOut();
                  $(".panelcard-pc").attr("data-pcinprog", '0'); // Set data-pcinprog value to 0
                  $("#hexcover-state").text("ON");
                  $(".hexbars-over").removeClass("on");
                  $("#poolcover-on").find(".coverbtn-pl").removeClass('enable');
                  //cover api data req obj
                  const coverData = {
                    coverStartTime: "",
                    coverPauseTime: "",
                    coverEndTime: "",
                    mode: 0,
                    subMode: 0,
                    pastMode: 1,
                    duration: 0
                  };
                
                  //update cover info
                  const coverUrl = `https://84ro5mdxeg.execute-api.us-east-2.amazonaws.com/prod/cover/${adminID}`;
                
                  fetch(coverUrl, {
                    method: "PUT",
                    headers: {
                      Accept: "application/json",
                      "Content-Type": "application/json",
                    },
                    body: JSON.stringify(coverData),
                  }) .then(response => {
                    if (response.ok) {
                      // API call was successful
                      currentModeRef.current = 0;
                      pastModeRef.current = 1;
                
                      // Additional code to be executed after animation complete
                      $(".hexbars-over").addClass("on");
                
                      setTimeout(() => {
                        $(".hexbars-over").addClass("paused");
                      }, 3200);
                    } 
                  })
                }
              }
            )
          }
         
        }, []);

        //cover off
        const handleCoverOffBtnDefClick = useCallback((value) => {
          if(clickCallsRef.current == 1){
              if ($(".panelcard-pc").attr("data-pcinprog") == '0') {

                $(".progfill").stop();
                $(".panelcard-pc").attr("data-pcstate", 'off');
                $(".panelcard-pc").attr("data-pconoff", 'on');
                //$(".coverprog, .covertime").fadeOut();
                $(".panelcard-pc").attr("data-pcinprog", '0'); // Set data-pcinprog value to 0
                $("#hexcover-state").text("OFF");
                $(".hexbars-over").removeClass("on");
                $(".hexbars-over").removeClass("on paused");
                $("#poolcover-on").find(".coverbtn-pl").removeClass('enable');
                $("#poolcover-on").find(".coverbtn-pa").removeClass('enable');
                $(".hexbars-over").removeClass("off");
                $(".hexbars-over").removeClass("off paused");
                $("#poolcover-off").find(".coverbtn-pl").removeClass('enable');
                $("#poolcover-off").find(".coverbtn-pa").removeClass('enable');
                $(".panelcard-pc").attr("data-pcprog", 0);

                $("#poolcover-off").find(".coverbtn-pl").addClass("enable");
                $("#hexcover-state").text("Removing Cover");
        
                coverofftotalDurationRef.current = 120; // Total duration in minutes
                coveroffminutesCompletedRef.current = 0;
                coveroffminutesRemainingRef.current = coverofftotalDurationRef.current;
                currentModeRef.current = 0;
                pastModeRef.current = 0;
        
                // Reset progress bar to zero
                $(".progfill").css("width", '0%');
                $(".progcount").text('0%');
        
                // Covering On Continuous Animation
        
                // Progress bar
                $(".coverprog, .covertime").fadeIn();
        
                $(".progfill").stop().animate({
                  width: '100%'
                }, {
                  duration: parseFloat(coverofftotalDurationRef.current * 1000 * 60),
                  easing: "linear",
                  progress: function(promise, progress, ms) {
                    var percentage = Math.floor(progress * 100); // Floor the progress value to the nearest integer
                    $(".progcount").text(percentage + '%');
                    $(".panelcard-pc").attr("data-pcprog", percentage + '%');
                    // Update HTML Configs
                    $(".panelcard-pc").attr("data-pcinprog", '1');
                    $(".panelcard-pc").attr("data-pcstate", 'play');
                    // Update UI
                    $("#poolcover-on").removeClass("disabled");
        
                    coveroffminutesCompletedRef.current = (coverofftotalDurationRef.current * progress); // Remove Math.floor() to keep it as a floating-point value
                    coveroffminutesRemainingRef.current = (coverofftotalDurationRef.current - coveroffminutesCompletedRef.current);
        
                    var calcRealTime = Math.ceil(coveroffminutesRemainingRef.current);
                    $("#cover-livetime").text(calcRealTime.toFixed(0) + ' min.');
                  },
                  complete: function() {
                    currentModeRef.current = 0;
                    pastModeRef.current = 0;
                    $(".panelcard-pc").attr("data-pcstate", 'off');
                    $(".panelcard-pc").attr("data-pconoff", 'on');
                    $(".coverprog, .covertime").delay(2000).fadeOut();
                    $(".panelcard-pc").attr("data-pcinprog", '0'); // Set data-pcinprog value to 0
                    $("#hexcover-state").text("OFF");
                    $(".hexbars-over").removeClass("off");
                    $("#poolcover-off").find(".coverbtn-pl").removeClass('enable');
                    //cover api data req obj
                    const coverData = {
                      coverStartTime: "",
                      coverPauseTime: "",
                      coverEndTime: "",
                      mode: 0,
                      subMode: 0,
                      pastMode: 2,
                      duration: 0
                    };
                  
                    //update cover info
                    const coverUrl = `https://84ro5mdxeg.execute-api.us-east-2.amazonaws.com/prod/cover/${adminID}`;
                  
                    fetch(coverUrl, {
                      method: "PUT",
                      headers: {
                        Accept: "application/json",
                        "Content-Type": "application/json",
                      },
                      body: JSON.stringify(coverData),
                    });
                  }
                });
        
                //publish msg request obj
                const publishPayload = {
                  UID: UID,
                  mode: "cover_off",
                  duration_min: 120,
                  action: "start"
                };
                const publishData = {
                  topic: "hex/fromapp/mode/"+UID,
                  payload: JSON.stringify(publishPayload)
                };
        
                //24 hours time format 
                const startTime = new Date();
                const startTimeFormatted = startTime.toLocaleString('en-US', {
                  hour12: false,
                  year: 'numeric',
                  month: '2-digit',
                  day: '2-digit',
                  hour: '2-digit',
                  minute: '2-digit',
                  second: '2-digit'
                }).replace(',', '');
                const endTime = new Date(startTime.getTime() + 120 * 60000); // Adding 30 minutes in milliseconds
                const endTimeFormatted = endTime.toLocaleString('en-US', {
                  hour12: false,
                  year: 'numeric',
                  month: '2-digit',
                  day: '2-digit',
                  hour: '2-digit',
                  minute: '2-digit',
                  second: '2-digit'
                }).replace(',', '');
        
                //cover api data req obj
                const coverData = {
                  coverStartTime: startTimeFormatted,
                  coverPauseTime: "",
                  coverEndTime: endTimeFormatted,
                  mode: 2,
                  subMode: 1,
                  pastMode: 0,
                  duration: 120
                };
                CoverDBMsgUpdate(coverData, publishData)
                .then(() => {
                  $(".hexbars-over").removeClass("on paused").addClass("off");
                  $("#poolcover-on").find(".coverbtn-pl").removeClass('enable');
                  $("#poolcover-on").find(".coverbtn-pa").removeClass('enable');
                  $("#poolcover-on").removeClass('disabled');
                })
                .catch((error) => {
                  // Handle error here
                  console.error(error);
                });
                
                
              } else {
                openModalCoverOff();
              }
            }
          } , {});

          const handleCoverOffBtnDefNoti = useCallback((time_stamp) => {
            if(clickCallsRef.current == 1){
              if ($(".panelcard-pc").attr("data-pcinprog") == '0') {

                  // Get the current timestamp in milliseconds
                  const currentTimestamp = new Date().getTime();

                  // Parse the provided timestamp to a Date object
                  const timestampDate = new Date(time_stamp);

                  // Calculate the time difference in milliseconds
                  const timeDifference = currentTimestamp - timestampDate.getTime();

                  // Convert the time difference to minutes
                  const minutesCompleted = Math.floor(timeDifference / (1000 * 60));

                  if(minutesCompleted <= 120){

                    $("#poolcover-off").find(".coverbtn-pl").addClass("enable");
                    $("#hexcover-state").text("Removing Cover");
                
                    coverofftotalDurationRef.current = 120;
                    coveroffminutesCompletedRef.current = minutesCompleted;
                    coveroffminutesRemainingRef.current = 120 - minutesCompleted;
                    handleSchedDBCoverOff();

                  }
          
                  //24 hours time format 
                  const startTimeFormatted = timestampDate.toLocaleString('en-US', {
                    hour12: false,
                    year: 'numeric',
                    month: '2-digit',
                    day: '2-digit',
                    hour: '2-digit',
                    minute: '2-digit',
                    second: '2-digit'
                  }).replace(',', '');
                  const endTime = new Date(timestampDate.getTime() + 120 * 60000); // Adding 60 minutes in milliseconds
                  const endTimeFormatted = endTime.toLocaleString('en-US', {
                    hour12: false,
                    year: 'numeric',
                    month: '2-digit',
                    day: '2-digit',
                    hour: '2-digit',
                    minute: '2-digit',
                    second: '2-digit'
                  }).replace(',', '');
          
                  //cover api data req obj
                  const coverData = {
                    coverStartTime: startTimeFormatted,
                    coverPauseTime: "",
                    coverEndTime: endTimeFormatted,
                    mode: 2,
                    subMode: 1,
                    pastMode: 0,
                    duration: 120
                  };
                  const coverUrl = `https://84ro5mdxeg.execute-api.us-east-2.amazonaws.com/prod/cover/${adminID}`;
              
                  fetch(coverUrl, {
                    method: "PUT",
                    headers: {
                      Accept: "application/json",
                      "Content-Type": "application/json",
                    },
                    body: JSON.stringify(coverData),
                  })
                    .then((res) => res.json())
                    .then((covOn) => {
                      if (covOn.Status === 200) {
                        $(".hexbars-over").removeClass("on paused").addClass("off");
                        $("#poolcover-on").find(".coverbtn-pl").removeClass('enable');
                        $("#poolcover-on").find(".coverbtn-pa").removeClass('enable');
                        $("#poolcover-on").removeClass('disabled');
                      }
                    });
                
              } else{

                 // Get the current timestamp in milliseconds
                 const currentTimestamp = new Date().getTime();

                 // Parse the provided timestamp to a Date object
                 const timestampDate = new Date(time_stamp);

                 // Calculate the time difference in milliseconds
                 const timeDifference = currentTimestamp - timestampDate.getTime();

                 // Convert the time difference to minutes
                 const minutesCompleted = Math.floor(timeDifference / (1000 * 60));

                 if(minutesCompleted <= 120){

                   $("#poolcover-off").find(".coverbtn-pl").addClass("enable");
                   $("#hexcover-state").text("Removing Cover");
               
                   coverofftotalDurationRef.current = 120;
                   coveroffminutesCompletedRef.current = minutesCompleted;
                   coveroffminutesRemainingRef.current = 120 - minutesCompleted;
                   handleSchedDBCoverOff();

                 }
         
                 //24 hours time format 
                 const startTimeFormatted = timestampDate.toLocaleString('en-US', {
                   hour12: false,
                   year: 'numeric',
                   month: '2-digit',
                   day: '2-digit',
                   hour: '2-digit',
                   minute: '2-digit',
                   second: '2-digit'
                 }).replace(',', '');
                 const endTime = new Date(timestampDate.getTime() + 120 * 60000); // Adding 60 minutes in milliseconds
                 const endTimeFormatted = endTime.toLocaleString('en-US', {
                   hour12: false,
                   year: 'numeric',
                   month: '2-digit',
                   day: '2-digit',
                   hour: '2-digit',
                   minute: '2-digit',
                   second: '2-digit'
                 }).replace(',', '');
         
                 //cover api data req obj
                 const coverData = {
                   coverStartTime: startTimeFormatted,
                   coverPauseTime: "",
                   coverEndTime: endTimeFormatted,
                   mode: 2,
                   subMode: 1,
                   pastMode: 0,
                   duration: 120
                 };
                 const coverUrl = `https://84ro5mdxeg.execute-api.us-east-2.amazonaws.com/prod/cover/${adminID}`;
             
                 fetch(coverUrl, {
                   method: "PUT",
                   headers: {
                     Accept: "application/json",
                     "Content-Type": "application/json",
                   },
                   body: JSON.stringify(coverData),
                 })
                   .then((res) => res.json())
                   .then((covOn) => {
                     if (covOn.Status === 200) {
                       $(".hexbars-over").removeClass("on paused").addClass("off");
                       $("#poolcover-on").find(".coverbtn-pl").removeClass('enable');
                       $("#poolcover-on").find(".coverbtn-pa").removeClass('enable');
                       $("#poolcover-on").removeClass('disabled');
                     }
                   });
              }
            } 
          } , {});
      
          const handleCoverOffBtnPlClick = useCallback((value) => {
            if(clickCallsRef.current == 1){
                // Fetch cover data for the admin
                fetch(`https://84ro5mdxeg.execute-api.us-east-2.amazonaws.com/prod/cover/getcoversbyadmin/${adminID}`)
                .then((response) => response.json())
                .then((data) => {
                  const { coverStartTime, duration } = data.Data;
                  // Format the dates and times
                  const startTime = coverStartTime;
                  const currentTime = new Date().toLocaleString('en-US', {
                    hour12: false,
                    year: 'numeric',
                    month: '2-digit',
                    day: '2-digit',
                    hour: '2-digit',
                    minute: '2-digit',
                    second: '2-digit',
                  }).replace(',', '');
      
                  // Calculate the new duration in minutes
                  const spentDuration = ((new Date(currentTime) - new Date(startTime)) / (1000 * 60));
                  const remainingDuration = duration - spentDuration;
                  // Prepare the data for the PUT request
                  const coverData = {
                    coverStartTime: "",
                    coverPauseTime: currentTime,
                    coverEndTime: "",
                    mode: 2,
                    subMode: 2,
                    pastMode: 0,
                    duration: remainingDuration,
                  };
                  const publishPayload = {
                    UID: UID,
                    mode: "cover_off",
                    duration_min: 0,
                    action: "pause"
                  };
                  const publishData = {
                    topic: "hex/fromapp/mode/"+UID,
                    payload: JSON.stringify(publishPayload)
                  };
                  if(value == 'cover_only') {
                    const coverUrl = `https://84ro5mdxeg.execute-api.us-east-2.amazonaws.com/prod/cover/${adminID}`;
              
                    fetch(coverUrl, {
                      method: "PUT",
                      headers: {
                        Accept: "application/json",
                        "Content-Type": "application/json",
                      },
                      body: JSON.stringify(coverData),
                    })
                      .then((res) => res.json())
                      .then((covOn) => {
                        if (covOn.Status === 200) {
                          // Update UI and perform necessary actions
                          $(".panelcard-pc").attr("data-pcinprog", '1');
                          $(".panelcard-pc").attr("data-pcstate", 'pause');
                          $("#hexcover-state").text("Removal Paused");
                          $("#poolcover-off").find(".coverbtn-pl").removeClass("enable");
                          $("#poolcover-off").find(".coverbtn-pa").addClass("enable");
                          $(".hexbars-over").removeClass("on paused").addClass("paused");
                          $(".progfill").stop();

                          currentModeRef.current = 0;
                          pastModeRef.current = 0;

          
                          // Calculate remaining minutes
                          coveroffminutesRemainingRef.current = (coverofftotalDurationRef.current - coveroffminutesCompletedRef.current);
                        }
                      });
                  }
                  else{
                    CoverDBMsgUpdate(coverData, publishData)
                    .then(() => {
                      // Update UI and perform necessary actions
                      $(".panelcard-pc").attr("data-pcinprog", '1');
                      $(".panelcard-pc").attr("data-pcstate", 'pause');
                      $("#hexcover-state").text("Removal Paused");
                      $("#poolcover-off").find(".coverbtn-pl").removeClass("enable");
                      $("#poolcover-off").find(".coverbtn-pa").addClass("enable");
                      $(".hexbars-over").removeClass("on paused").addClass("paused");
                      $(".progfill").stop();

                      currentModeRef.current = 0;
                      pastModeRef.current = 0;

      
                      // Calculate remaining minutes
                      coveroffminutesRemainingRef.current = (coverofftotalDurationRef.current - coveroffminutesCompletedRef.current);
                    })
                    .catch((error) => {
                      // Handle error here
                      console.error(error);
                    });
                  }
                
                })
                .catch((error) => {
                  // Handle error here
                  console.error(error);
                });
              }
            }, []);

            const handleCoverOffBtnStopClick = useCallback((e) => {
              if(clickCallsRef.current == 1){
                $(".progfill").stop();
                $(".panelcard-pc").attr("data-pcstate", 'off');
                $(".panelcard-pc").attr("data-pconoff", 'on');
                //$(".coverprog, .covertime").delay(2000).fadeOut();
                $(".panelcard-pc").attr("data-pcinprog", '0'); // Set data-pcinprog value to 0
                $(".panelcard-pc").attr("data-pcprog", 0);
                $("#hexcover-state").text("OFF");
                $(".hexbars-over").removeClass("off");
                $("#poolcover-off").find(".coverbtn-pl").removeClass('enable');
                $("#poolcover-off").find(".coverbtn-pa").removeClass("enable");
                $(".hexbars-over").removeClass("off paused");
                // Reset progress bar to zero
                $(".progfill").css("width", "0%");
                $(".progcount").text("0%");

                currentModeRef.current = 0;
                pastModeRef.current = 0;

                
                //cover api data req obj
                const coverData = {
                  coverStartTime: "",
                  coverPauseTime: "",
                  coverEndTime: "",
                  mode: 0,
                  subMode: 0,
                  pastMode: 2,
                  duration: 0
                };
              
                //update cover info
                const coverUrl = `https://84ro5mdxeg.execute-api.us-east-2.amazonaws.com/prod/cover/${adminID}`;
              
                fetch(coverUrl, {
                  method: "PUT",
                  headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                  },
                  body: JSON.stringify(coverData),
                });
              }
            },[]);
        
            const handleCoverOffBtnPaClick = useCallback((e) => {
              if(clickCallsRef.current == 1){
                  // Fetch cover data for the admin
                fetch(`https://84ro5mdxeg.execute-api.us-east-2.amazonaws.com/prod/cover/getcoversbyadmin/${adminID}`)
                .then((response) => response.json())
                .then((data) => {
                  const { duration } = data.Data;
        
                  // Calculate new coverStartTime and coverEndTime
                  //24 hours time format 
                  const startTime = new Date();
                  const startTimeFormatted = startTime.toLocaleString('en-US', {
                    hour12: false,
                    year: 'numeric',
                    month: '2-digit',
                    day: '2-digit',
                    hour: '2-digit',
                    minute: '2-digit',
                    second: '2-digit'
                  }).replace(',', '');
                  const endTime = new Date(startTime.getTime() + duration * 60000); // Adding 30 minutes in milliseconds
                  const endTimeFormatted = endTime.toLocaleString('en-US', {
                    hour12: false,
                    year: 'numeric',
                    month: '2-digit',
                    day: '2-digit',
                    hour: '2-digit',
                    minute: '2-digit',
                    second: '2-digit'
                  }).replace(',', '');
        
                  const coverData = {
                    coverStartTime: startTimeFormatted,
                    coverPauseTime: "",
                    coverEndTime: endTimeFormatted,
                    mode: 2,
                    subMode: 3,
                    pastMode: 0,
                    duration: duration
                  };
        
                  const publishPayload = {
                    UID: UID,
                    mode: "cover_off",
                    duration_min: 0,
                    action: "start"
                  };
                  const publishData = {
                    topic: "hex/fromapp/mode/"+UID,
                    payload: JSON.stringify(publishPayload)
                  };
        
                  CoverDBMsgUpdate(coverData, publishData);
        
                })
                .catch((error) => {
                  // Handle error here
                  console.error(error);
                });
              // Update HTML Configs
              $(".panelcard-pc").attr("data-pcinprog", '1');
              $(".panelcard-pc").attr("data-pcstate", 'play');
        
              // Update UI
              $("#hexcover-state").text("Removing Cover");
              $("#poolcover-off").find(".coverbtn-pl").addClass("enable");
              $("#poolcover-off").find(".coverbtn-pa").removeClass("enable");
              $(".hexbars-over").removeClass("on paused").removeClass("off paused").addClass("off");
        
              // Calculate remaining milliseconds
              var coveroffremainingMilliseconds = coveroffminutesRemainingRef.current * 60 * 1000;
              currentModeRef.current = 0;
              pastModeRef.current = 0;
        
              // Continue Cover Progress
              $(".progfill").animate(
                {
                  width: "100%"
                },
                {
                  duration: coveroffremainingMilliseconds,
                  easing: "linear",
                  step: function(now, fx) {
                    var progress = now / fx.end;
                    var progressPercentage = Math.floor(progress * 100);
                    var coveroffelapsedTime = progress * coverofftotalDurationRef.current;
                    var coveroffremainingTime = coverofftotalDurationRef.current - coveroffelapsedTime;
                    coveroffminutesCompletedRef.current = (coveroffelapsedTime);
                    coveroffminutesRemainingRef.current = (coveroffremainingTime);
                    $(".progcount").text(progressPercentage + "%");
                    $(".panelcard-pc").attr("data-pcprog", progressPercentage + '%');
                    $("#cover-livetime").text(coveroffremainingTime.toFixed(0) + ' min.');
                  },
                  complete: function() {

                    currentModeRef.current = 0;
                    pastModeRef.current = 0;

                    $(".panelcard-pc").attr("data-pcstate", 'off');
                    $(".panelcard-pc").attr("data-pconoff", 'on');
                    $(".coverprog, .covertime").delay(2000).fadeOut();
                    $(".panelcard-pc").attr("data-pcinprog", '0'); // Set data-pcinprog value to 0
                    $("#hexcover-state").text("OFF");
                    $(".hexbars-over").removeClass("off");
                    $("#poolcover-off").find(".coverbtn-pl").removeClass('enable');
                    //cover api data req obj
                    const coverData = {
                      coverStartTime: "",
                      coverPauseTime: "",
                      coverEndTime: "",
                      mode: 0,
                      subMode: 0,
                      pastMode: 2,
                      duration: 0
                    };
                  
                    //update cover info
                    const coverUrl = `https://84ro5mdxeg.execute-api.us-east-2.amazonaws.com/prod/cover/${adminID}`;
                  
                    fetch(coverUrl, {
                      method: "PUT",
                      headers: {
                        Accept: "application/json",
                        "Content-Type": "application/json",
                      },
                      body: JSON.stringify(coverData),
                    });
                  }
                }
              );
            }
            
          }, []);

          //lighting 
          const RGBMsgDbUpdate = (data, payload) => {
            const putUrl = `https://84ro5mdxeg.execute-api.us-east-2.amazonaws.com/prod/rgb/${adminID}`;
            fetch(putUrl, {
              method: "PUT",
              headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
              },
              body: JSON.stringify(data),
            })
              .then((res) => {
                if (res.ok) {
                  const publishPayload = {
                    topic: "hex/fromapp/rgb/"+UID,
                    payload: JSON.stringify(payload),
                  };
        
                  const publishUrl = "https://84ro5mdxeg.execute-api.us-east-2.amazonaws.com/prod/message/publish";
        
                  return fetch(publishUrl, {
                    method: "POST",
                    headers: {
                      Accept: "application/json",
                      "Content-Type": "application/json",
                    },
                    body: JSON.stringify(publishPayload),
                  });
                } else {
                  throw new Error("RGB API call failed");
                }
              })
              .then((res) => {
                if (res.ok) {
                  return res.json();
                } else {
                  throw new Error("Publish API call failed");
                }
              })
              .then((response) => {
                console.log(response);
              })
              .catch((error) => {
                console.error(error);
              });
          };

          useEffect(() => {
              fetchInitialColor();
          }, []);

        
          const lightColors = {
            1: { color: "#cccccc", rgb_red: 0, rgb_green: 0, rgb_blue: 0 },
            2: { color: "#ff0000", rgb_red: 100, rgb_green: 0, rgb_blue: 0 },
            3: { color: "#00ff00", rgb_red: 0, rgb_green: 100, rgb_blue: 0 },
            4: { color: "#0000ff", rgb_red: 0, rgb_green: 0, rgb_blue: 100 },
            5: { color: "#ff00ff", rgb_red: 100, rgb_green: 0, rgb_blue: 100 },
            6: { color: "#ffff00", rgb_red: 100, rgb_green: 100, rgb_blue: 0 },
            7: { color: "#00ffff", rgb_red: 0, rgb_green: 100, rgb_blue: 100 },
            8: { color: "#ffffff", rgb_red: 100, rgb_green: 100, rgb_blue: 100 },
          };

          const fetchInitialColor = () => {
              fetch(`https://84ro5mdxeg.execute-api.us-east-2.amazonaws.com/prod/rgb/getrgbbyadmin/${adminID}`)
                .then((response) => response.json())
                .then((data) => {
                  const { rgb_red, rgb_green, rgb_blue } = data.Data;
                  setInitialColor(rgb_red, rgb_green, rgb_blue);
                  setPoolColor(currentColor.current);
                  setLightState();
                })
                .catch((error) => {
                  console.error(error);
                });
          };

          const setPoolColor = (poolColorState) => {
            const poolColorStateInt = parseInt(poolColorState);
            const currentColorInt = parseInt(hexLightRef.current.getAttribute("data-lightstate"));
        
            if (poolColorStateInt !== currentColorInt) {
              hexLightRef.current.setAttribute("data-lightstate", poolColorStateInt);
              hexArtPathRef.current.style.fill = lightColors[poolColorStateInt].color;
              hexLightButtonRef.current.style.background = lightColors[poolColorStateInt].color;
            }
            else{
              hexArtPathRef.current.style.fill = lightColors[poolColorStateInt].color;
              hexLightButtonRef.current.style.background = lightColors[poolColorStateInt].color;
            }
          };
        
          const setLightState = () => {
            const getLightState = hexLightRef.current.getAttribute("data-lightstate");
        
            if (getLightState === "1") {
              hexStatusLightRef.current.textContent = "OFF";
            } else {
              hexStatusLightRef.current.textContent = "ON";
            }
          };

          const setInitialColor = (rgb_red, rgb_green, rgb_blue) => {
            
            for (const key in lightColors) {
              if (
                lightColors[key].rgb_red === rgb_red &&
                lightColors[key].rgb_green === rgb_green &&
                lightColors[key].rgb_blue === rgb_blue
              ) {
                currentColor.current =parseInt(key);
                break;
              }
            }
        
            // If the color is not found in the lightColors object, set it to the default color (white)
            if (!currentColor) {
              currentColor.current = 8;
            }
          };

          const handleLightButtonClick = (e) => {
            if(clickCallsRef.current == 1){
              let nextColor = currentColor.current + 1;
              if (nextColor > Object.keys(lightColors).length) {
                nextColor = 1;
              }
          
              currentColor.current= nextColor;
              setPoolColor(nextColor);
              setLightState();
          
              const { rgb_red, rgb_green, rgb_blue } = lightColors[nextColor];
              const payload = {
                UID: UID,
                action: "switching",
                start_datetime: [],
                end_datetime: [],
                rgb_red,
                rgb_green,
                rgb_blue,
              };
          
              const data = {
                start_datetime: payload.start_datetime.toString(),
                end_datetime: payload.end_datetime.toString(),
                rgb_red: payload.rgb_red,
                rgb_green: payload.rgb_green,
                rgb_blue: payload.rgb_blue,
              };
          
              RGBMsgDbUpdate(data, payload);
            }
          };
        
      //pumping
      var pumpLightColors = {
        '1': '#CCCCCC',
        '2': '#00FF00'
      };

      useEffect(() => {
        fetchPumpInitialColor();
      }, []);

      const fetchPumpInitialColor = () => {
        fetch(`https://84ro5mdxeg.execute-api.us-east-2.amazonaws.com/prod/pumping/getpumpingbyadmin/${adminID}`)
          .then((response) => response.json())
          .then((data) => {
            const { mode } = data.Data;
            var dbcolor;
            if(mode == 1){
                dbcolor = 2;
            }
            else{
                dbcolor = 1;
            }
            setPumpInitialColor(dbcolor);
            setPumpColor(currentPumpColor.current);
            setPumpLightState();
          })
          .catch((error) => {
            console.error(error);
          });
      };

      const setPumpColor = (pumpColorState) => {
        var pumpColorState = parseInt(pumpColorState);
        var pumpcurrentColor = parseInt(hexPumpRef.current.getAttribute("data-pumplightstate"));

        if (pumpColorState !== pumpcurrentColor && pumpLightColors[pumpColorState]) {
          hexPumpRef.current.setAttribute("data-pumplightstate", pumpColorState);
          hexPumpArtPathRef.current.style.fill = pumpLightColors[pumpColorState];
          hexPumpButtonRef.current.style.background = pumpLightColors[pumpColorState];
        }
      };

      const setPumpLightState = ()  =>{
        let getPumpLightState = hexPumpRef.current.getAttribute("data-pumplightstate");
        if (getPumpLightState === '1') {
          hexStatusPumpRef.current.textContent = "OFF";
        } else {
          hexStatusPumpRef.current.textContent = "ON";
        }
      }
    
      const setPumpInitialColor = (dbcolor) =>{
         
        currentPumpColor.current = parseInt(dbcolor);
          
        
        // If the color is not found in the lightColors object, set it to the default color (white)
        if (!currentPumpColor) {
          currentPumpColor = 1;
        }
      }
    
      const handlePumpButtonClick = (e) => {
        if(clickCallsRef.current == 1){
          let nextColor = currentPumpColor.current + 1;
          if (nextColor > Object.keys(pumpLightColors).length) {
            nextColor = 1;
          }
  
          currentPumpColor.current= nextColor;
          setPumpColor(nextColor);
  
          setPumpLightState();
          var msgMode;
          var dbMode;
          var action;
          if(currentPumpColor.current == 2)
          {
            msgMode="pumping_on";
            dbMode = 1;
            action = "start";
          }
          else{
            msgMode="pumping_off";
            dbMode = 0;   
            action = "stop";
          }
          const payload = {
            UID: UID,
            mode:"pumping",
            duration_min:0,
            action: action
          };
      
          const data = {
            start_datetime: "",
            end_datetime: "",
            mode: dbMode
          };
          const putUrl = `https://84ro5mdxeg.execute-api.us-east-2.amazonaws.com/prod/pumping/${adminID}`;
      
          fetch(putUrl, {
            method: "PUT",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
            },
            body: JSON.stringify(data),
          })
            .then((res) => {
              if (res.ok) {
                const publishPayload = {
                  topic: "hex/fromapp/mode/"+UID,
                  payload: JSON.stringify(payload),
                };
      
                const publishUrl = "https://84ro5mdxeg.execute-api.us-east-2.amazonaws.com/prod/message/publish";
      
                return fetch(publishUrl, {
                  method: "POST",
                  headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                  },
                  body: JSON.stringify(publishPayload),
                });
              } else {
                throw new Error("RGB API call failed");
              }
            })
            .then((res) => {
              if (res.ok) {
                return res.json();
              } else {
                throw new Error("Publish API call failed");
              }
            })
            .then((response) => {
              console.log(response);
            })
            .catch((error) => {
              console.error(error);
            });
         }
        
        };


    $(document).ready(function () {
        console.log("Custom JS loaded!");
        //Elements
       
        /*Popup*/
        if($(".dpopup").length > 0) {
            function closePopup(thisPop) {
                $("html").removeClass("dpopup-on");
                $(thisPop).fadeOut(200);
            }
    
            function openPopup(thisPop) {
                $("html").addClass("dpopup-on");
                $(thisPop).fadeIn(200);
            }
    
            //Close or Cancel Button
            $(".dpopup-close").on("click", function(e){
                e.preventDefault();
                closePopup($(this).parents(".dpopup"));
            });
            
            //Close on clicking outside
            $(document).mouseup(function (e){
                var container = $(".dpopup-wrapper");
    
                if (!container.is(e.target) && container.has(e.target).length === 0 && $("html").hasClass("dpopup-on")) {
                    closePopup($(".dpopup:visible"));
                }
            });
    
            if($(".dpopup-opener").length > 0) {
                $(".dpopup-opener").on("click", function(e){
                    e.preventDefault();
                    var thisPopup = $(this).attr("data-target");
                    openPopup(thisPopup);
                });
            }
        }
    
        //Header Notifications
        if($(".headbell").length >0) {
            function toggleNotification() {
                $(".notify").toggleClass("notify-open");
            }
    
            $(".headbell, .notify-head a").on("click", function(e){
                e.preventDefault();
                toggleNotification();
            });
    
            $(document).mouseup(function (e){
                var container = $(".head-notify");
    
                if (!container.is(e.target) && container.has(e.target).length === 0) {
                    $(".notify").removeClass("notify-open");
                }
            });
        }

           
        
        //Scheduling Listing Toggle
        if($(".sctabs").length > 0) {
            $(".sctab-link").on("click", function(e){
                e.preventDefault();
                var getScHash = $(this).attr("href");
    
                if(!$(getScHash).is(":visible")) {
                    $(".sctab-link").removeClass("active");
                    $(this).addClass("active");
    
                    $(".scpane").hide().removeClass("active");
                    $(getScHash).fadeIn(200).addClass("active");
                }
            });
        }
        
        //Scheduling Listing Toggle
        if($(".sctabs").length > 0) {
            $(".sctab-link").on("click", function(e){
                e.preventDefault();
                var getScHash = $(this).attr("href");
    
                if(!$(getScHash).is(":visible")) {
                    $(".sctab-link").removeClass("active");
                    $(this).addClass("active");
    
                    $(".scpane").hide().removeClass("active");
                    $(getScHash).fadeIn(200).addClass("active");
                }
            });
        }
    
        //Schedules Delete Popup
        if($(".dpopup-schedtrash").length > 0) {
            $(".openpopup").on("click", function(e){
                e.preventDefault();
                var thisPopup = $(this).attr("data-target");
    
                if($(thisPopup).hasClass("dpopup-trash")) {
                    $(thisPopup).find(".popup-action").show();
                }
    
                // openPopup($(thisPopup));
            });
    
            $(".dpopup-trash").on("click", function(e){
                e.preventDefault();
                var thisPopup = $(this).parents(".dpopup");
                $(thisPopup).find(".popup-action").hide();
                $(thisPopup).find(".alert-trashwarn").hide();
                $(thisPopup).find(".alert-trashdone").fadeIn();
    
                setTimeout(function () {
                    // closePopup($(thisPopup));
                    $(thisPopup).find(".alert-trashdone").hide();
                    $(thisPopup).find(".alert-trashwarn").show();
                    $(thisPopup).find(".popup-action").show();
                },2000);
            });
        }
    
        //Add user
        if($(".bttn-adduser").length > 0) {
            $(".bttn-adduser").on("click", function(e){
                var allAreFilled = true;
                $(this).parents('.dpopup').find("input[required]").each(function(i,v) {
                    if (!allAreFilled) return;
                    if ($(v).val() == '') { allAreFilled = false;  return; }
                });
    
                //If all fields are filled
                if (allAreFilled) {
                    e.preventDefault();
                    var thisPopup = $(this).parents(".dpopup");
                    $(thisPopup).find(".popup-action").hide();
                    $(thisPopup).find(".alert-adduser").hide();
                    $(thisPopup).find(".alert-useradded").fadeIn();
                    $(thisPopup).find("form").hide();
    
                    setTimeout(function () {
                        // closePopup($(thisPopup));
                        $(thisPopup).find("form .input-control").val('');
                        $(thisPopup).find(".popup-action").show();
                        $(thisPopup).find(".alert-adduser, form").show();
                        $(thisPopup).find(".alert-useradded").hide();
                    },2000);
                }
            });
        }
    
        //Form Password Hide/Show
        if($(".passeye").length > 0) {
            $(".passeye").on("click", function(e){
                e.preventDefault();
    
                var targetPassEl = $(this).parents(".input-group").find("input");
                
                if(targetPassEl.attr("type") == "password") {
                    targetPassEl.attr("type", "text");
                } else {
                    targetPassEl.attr("type", "password");
                }
    
                $(this).find(".passeye-icon").toggleClass("passeye-hide");
    
                $(targetPassEl).focus();
            })
        }
    
        //Login Signup Toggle
        if($(".tabs-link").length > 0) {
            $(".tabs-link").on("click", function(e){
                e.preventDefault();
                var getTabHash = $(this).attr("href");

                if ($(this).attr("href") === "#navcover") {

                
                  // Trigger cover animation logic here
                  if(pastModeRef.current == 1){
                    $(".hexbars-over").removeClass("on paused");

                    $(".hexbars-over").addClass("on");
                    setTimeout(() => {
                        $(".hexbars-over").addClass("paused");
                    }, 3200);
                  }
                 
                  if(currentModeRef.current == 1)
                  {
                    $(".hexbars-over").removeClass("on paused");

                    $(".hexbars-over").addClass("on");
                    setTimeout(() => {
                        $(".hexbars-over").addClass("paused");
                    }, 1100);
                  }
              }

    
                if(!$(getTabHash).is(":visible")) {
                    $(".tabs-link").removeClass("active");
                    $(this).addClass("active");
    
                    $(".panels-item").hide().removeClass("active");
                    $(getTabHash).fadeIn(200).addClass("active");
                }
            });
        }
    });

    const dot = (color = "transparent") => ({
      alignItems: "center",
      display: "flex",
  
      ":before": {
        backgroundColor: color,
        borderRadius: 10,
        content: '" "',
        display: "block",
        marginRight: 8,
        height: 10,
        width: 10,
      },
    });
  
    const colourStyles = {
      control: (styles) => ({ ...styles, backgroundColor: "white" }),
      // option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      //   const color = chroma(data.color);
      //   return {
      //     ...styles,
      //     backgroundColor: isDisabled
      //       ? undefined
      //       : isSelected
      //       ? data.color
      //       : isFocused
      //       ? color.alpha(0.1).css()
      //       : undefined,
      //     color: isDisabled
      //       ? '#ccc'
      //       : isSelected
      //       ? chroma.contrast(color, 'white') > 2
      //         ? 'white'
      //         : 'black'
      //       : data.color,
      //     cursor: isDisabled ? 'not-allowed' : 'default',
  
      //     ':active': {
      //       ...styles[':active'],
      //       backgroundColor: !isDisabled
      //         ? isSelected
      //           ? data.color
      //           : color.alpha(0.3).css()
      //         : undefined,
      //     },
      //   };
      // },
      input: (styles) => ({ ...styles, ...dot() }),
      placeholder: (styles) => ({ ...styles, ...dot("#ccc") }),
      singleValue: (styles, { data }) => ({ ...styles, ...dot(data.color) }),
    };
  
    const colourOptions = [
      { value: "1", label: "Blue", color: "#0000FF" },
      { value: "2", label: "Green", color: "#00FF00" },
      { value: "3", label: "Red", color: "#FF0000" },
      { value: "4", label: "Pink", color: "#FF00FF" },
      { value: "5", label: "Yellow", color: "#FFFF00" },
      { value: "6", label: "Aqua", color: "#00FFFF" },
      { value: "7", label: "White", color: "#FFFFFF" },
    ];
  
    const handleChange = (selectedOption) => {
      console.log("Selected option:", selectedOption.value);
      setSchedule((pre) => {
        return {
          ...pre,
          color: selectedOption.value,
        };
      });
  
      setEditSched((pre)=>{
        return{
          ...pre,
          color:selectedOption.value
        }
      })
    };



    
  return (
  <>
   <div>
      <Modal show={showModalCoverOff} onHide={closeModalCoverOff}>
        <Modal.Header closeButton>
          <Modal.Title>Confirmation</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Covering on is in progress. Are you sure you want to switch?</p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={closeModalCoverOff}>
            No
          </Button>
          <Button variant="primary" onClick={handleConfirmationCoverOff}>
            Yes
          </Button>
        </Modal.Footer>
      </Modal>
    </div>

    <div>
      

      <Modal show={showModalCoverOn} onHide={closeModalCoverOn}>
        <Modal.Header closeButton>
          <Modal.Title>Confirmation</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Removing Cover is in progress. Are you sure you want to switch?</p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={closeModalCoverOn}>
            No
          </Button>
          <Button variant="primary" onClick={handleConfirmationCoverOn}>
            Yes
          </Button>
        </Modal.Footer>
      </Modal>
    </div>

    <div>
      

      <Modal show={showModalNoti} onHide={closeModalNoti}>
        <Modal.Header closeButton>
          <Modal.Title>Confirmation</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Please select what you want to to do!</p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleRestartNoti}>
            Restart
          </Button>
          <Button variant="primary" onClick={handleTurnOffNoti}>
            Turn off
          </Button>
        </Modal.Footer>
      </Modal>
    </div>

    {/* <!-- Header --> */}
    <header class="sitehead">
        <div class="container container-hpc">
            <div class="head-row">
                <div class="head-left">
                <Link to="/dashboard" className="hpc-logo" title="Hex Pool Cover">
                  <img src={logo} alt="Logo" width="44.8" height="64.7" />
                </Link>
                </div>

                <div class="head-right">
                    <div class="head-alerts">
                        <a href="#sensor-failure" data-target="#sensor-failure" title="Sensor Failure" class="dpopup-opener">
                            <i class="icon-warning"></i>
                        </a>
                    </div>

                    <div class="head-notify">
                        <a class="headbell" href="#notify-open" title="Notifications">
                            <i class="icon-bell"></i>
                            <span class="headbell-count">{Noti.length}</span>
                        </a>

                        <div class="notify">
                          <div class="notify-head">
                            <div class="notif-head--title">You have {Noti.length} new notifications</div>
                              <a class="notify-close" href="#close-notifications" title="Close">
                                <i class="icon-cross"></i>
                              </a>
                          </div>
                            <div class="notify-body">
                            {Noti.map((n, i) => (
                                <div class="notify-item" key={i}>
                                  <i class="icon-info"></i>
                                  <div class="notify-cont">
                                    <div class="notify-text">{n.payload.message}</div>
                                    {/* <div class="notify-datetime">30 min. ago</div> */}
                                  </div>
                                  <button type="button" class="notify-item--close" onClick={() => markMessagesAsRead(n.id, 'notification_general')}>
                                    <i class="icon-close-circle"></i>
                                  </button>
                                </div>
                              ))}
                               
                            </div>


                            <div class="notify-footer">
                                <button type="button" class="bttn-link notify-clear" onClick={handleClearNotifications}>clear all notifications</button>
                            </div>
                        </div>
                    </div>

                    <div class="head-user">
                        <i class="icon-hpc-hex"></i>
                        
                        <ul class="menudrop">
                            <li>
                                <a class="bttn bttn-primary" title="Logout" onClick={()=>{
                                    navigate("/")
                                }}>Logout</a>
                            </li>
                        </ul>   
                    </div>
                </div>
            </div>
        </div>
    </header>
    {/* <!-- Header ENDS--> */}

    <main class="main">
        <div class="container">
            <div class="panels dashpanels">
                {/* <!--Cover--> */}
                <div class="panels-item active" id="navcover">
                    <div class="panelcard panelcardbox panelcard-pc" data-pcinprog="0" data-pcprog="0" data-pctt="0.2" data-pcstate="off" data-pconoff="off">
                        <div class="pooltemp">Pool Temperature : <span class="pool-tempCover">--</span></div>

                        <div class="hexart hexart-cover" id="hexcover">
                            <img class="hexart-rg" src={ring} width="249" height="279.26" alt="Hex Ring" />

                            <div class="hexbars">
                                <div class="hexbars-over" data-hexprog="0%" style={{height: "100%"}}></div>
                                
                                <svg width="231" height="260" viewBox="0 0 231 260" xmlns="http://www.w3.org/2000/svg" fill="#173F5F">
                                    <path d="M121.601 3.45917C118.485 1.63606 116.019 0.203613 116.019 0.203613C115.5 0.203613 115.024 0.333836 114.591 0.59428L38.3999 43.6978C35.2848 45.521 35.6742 46.9534 39.3085 46.9534H191.821C195.326 46.9534 195.715 45.521 192.6 43.6978L121.601 3.45917Z"/>
                                    <path d="M230.371 71.6955C230.371 68.0492 227.905 63.7519 224.79 61.9288L215.445 56.5897C212.329 54.8968 206.878 53.4644 203.244 53.4644H27.7565C24.1222 53.4644 18.6707 54.8968 15.5555 56.5897L6.46966 61.7986C3.3545 63.6217 0.758545 67.919 0.758545 71.5652V93.5728C0.758545 97.219 3.6141 100.084 7.24844 100.084H223.881C227.516 100.084 230.371 97.219 230.371 93.5728V71.6955Z"/>
                                    <path d="M230.371 113.106C230.371 109.59 227.516 106.595 223.881 106.595H7.24844C3.6141 106.595 0.758545 109.59 0.758545 113.106V146.704C0.758545 150.35 3.6141 153.215 7.24844 153.215H223.881C227.386 153.215 230.371 150.35 230.371 146.704V113.106Z"/>
                                    <path d="M224.66 198.011C227.775 196.318 230.371 191.89 230.371 188.374V166.237C230.371 162.721 227.386 159.726 223.881 159.726H7.2486C3.61426 159.726 0.758705 162.721 0.758705 166.237L0.628906 188.244C0.628906 191.76 3.22486 196.188 6.34002 197.881L15.6855 203.22C18.8006 205.043 24.2521 206.475 27.8865 206.475H203.373C206.878 206.475 212.329 205.043 215.445 203.22L224.66 198.011Z"/>
                                    <path d="M192.6 216.112C195.715 214.419 195.456 212.986 191.821 212.986H39.3085C35.6741 212.986 35.4145 214.419 38.5297 216.112L109.399 256.481C112.515 258.173 115.11 259.606 115.11 259.606C115.543 259.606 115.976 259.476 116.408 259.215L192.6 216.112Z"/>
                                </svg>
                            </div>
                        </div>

                        <div class="hexlabel">Pool Cover</div>

                        <div class="hexstatus" id="hexcover-state">OFF</div>

                        <div class="coverbtns">
                            <div class="coverbtn" id="poolcover-on">
                                <div class="coverbtn-pp">
                                    <div class="coverbtn-pl" onClick={handleCoverOnBtnPlClick}><i class="icon-pause"></i></div>
                                    <div class="coverbtn-pa" onClick={handleCoverOnBtnPaClick}><i class="icon-play"></i></div>
                                </div>

                                <div class="coverbtn-def" onClick={handleCoverOnBtnDefClick}><i class="icon-hpc-hex"></i></div>
                                <div class="coverbtn-lbl">Cover On</div>
                            </div>

                            <div class="coverbtn" id="poolcover-off">
                                <div class="coverbtn-pp">
                                    <div class="coverbtn-pl" onClick={handleCoverOffBtnPlClick}><i class="icon-pause"></i></div>
                                    <div class="coverbtn-pa" onClick={handleCoverOffBtnPaClick}><i class="icon-play"></i></div>
                                </div>

                                <div class="coverbtn-def" onClick={handleCoverOffBtnDefClick}>
                                    <img src={ring} width="30px" height="33.1" alt="Cover Off" />
                                </div>
                                <div class="coverbtn-lbl">Cover Off</div>
                            </div>
                        </div>

                        <div class="coverprog">
                            <div class="coverprogwrap">
                                <span class="progtrack">
                                    <span class="progfill" style={{width: "0%"}}>
                                        <span class="progcount"></span>
                                    </span>
                                </span>
                            </div>
                        </div>

                        <div class="covertime text-center text-18 text-md">
                            Time Remaining : <span id="cover-livetime">0.00 min.</span>
                        </div>
                    </div>

                    <div class="dashstatus">
                        Device Status: 
                        <span class="dashstatus-cover"> ON</span>
                    </div>
                </div>

                {/* <!--Lighting--> */}
                <div class="panels-item" id="navlight">
                    <div class="panelcard panelcardbox panelcard-lt">
                        <div class="pooltemp">Pool Temperature : <span class="pool-tempLight">--</span></div>

                        <div class="panelcard-wrap">
                            <div class="hexart hexart-lt" id="hexlight" ref={hexLightRef} data-lightstate="1">
                                <img class="hexart-bb" src={bulb} width="104" height="99" alt="Light" />
                                <svg class="hexart-bs" width="219" height="244" viewBox="0 0 219 244" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M214.442 185.946L113.715 242.412C111.095 243.883 107.904 243.883 105.283 242.412L4.44221 185.833C1.82148 184.362 0.112305 181.533 0.112305 178.477L0.226249 65.7713C0.226249 62.716 1.93542 59.8871 4.55615 58.3029L105.397 1.8367C108.018 0.365633 111.208 0.365633 113.829 1.8367L214.556 58.416C217.291 60.0002 218.886 62.8292 218.886 65.8845V178.59C218.886 181.646 217.177 184.475 214.442 185.946Z" fill="url(#grad)" ref={hexArtPathRef}/>
                                </svg>
                                <img class="hexart-rg" src={ring} width="249" height="279.26" alt="Hex Ring" />
                            </div>
                            <div class="hexlabel">Lighting</div>
                            <div class="hexstatus" id="hexstatus-lt" ref={hexStatusLightRef}>OFF</div>
                            <button type="button" class="hexlt-btn"  onClick={handleLightButtonClick} ref={hexLightButtonRef}>
                                <span>
                                    <img src={bulbsm} width="26" height="26" alt="Lightbulb" />
                                </span>
                            </button>
                        </div>
                    </div>

                    <div class="dashstatus">
                        Device Status: 
                        <span class="dashstatus-light"> ON</span>
                    </div>
                </div>


                {/* ----------------------------Pumping--------------- */}
                <div class="panels-item" id="navpump">
                    <div class="panelcard panelcardbox panelcard-lt">
                        <div class="pooltemp">Pool Temperature : <span class="pool-tempPump">--</span></div>

                        <div class="panelcard-wrap">
                            <div class="hexart hexart-pm" id="hexpump" ref={hexPumpRef} data-pumplightstate="0">
                                <img class="hexartp-bb" src={pump} width="104" height="99" alt="pump" />
                                <svg class="hexartp-bs" width="219" height="244" viewBox="0 0 219 244" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M214.442 185.946L113.715 242.412C111.095 243.883 107.904 243.883 105.283 242.412L4.44221 185.833C1.82148 184.362 0.112305 181.533 0.112305 178.477L0.226249 65.7713C0.226249 62.716 1.93542 59.8871 4.55615 58.3029L105.397 1.8367C108.018 0.365633 111.208 0.365633 113.829 1.8367L214.556 58.416C217.291 60.0002 218.886 62.8292 218.886 65.8845V178.59C218.886 181.646 217.177 184.475 214.442 185.946Z" fill="url(#grad)" ref={hexPumpArtPathRef}/>
                                </svg>
                                <img class="hexart-rg" src={ring} width="249" height="279.26" alt="Hex Ring" />
                            </div>
                            <div class="hexlabel">Pumping</div>
                            <div class="hexstatus" id="hexstatus-p" ref={hexStatusPumpRef}>OFF</div>
                            <button type="button" class="hexp-btn" onClick={handlePumpButtonClick} ref={hexPumpButtonRef}>
                                <span>
                                    <img src={pumpsm} width="26" height="26" alt="Pumpbulb" />
                                </span>
                            </button>
                        </div>
                    </div>

                    <div class="dashstatus">
                        Device Status: 
                        <span class="dashstatus-pumping"> ON</span>
                    </div>
                </div>

                {/* <!--Scheduling--> */}
                <div class="panels-item" id="navsched">
                    <div class="panelcard panelcard-sched">
                        <ul class="sctabs">
                            <li class="sctabs-tab">
                                <a class="sctab-link active" href="#sched-pc">Pool Cover Schedule</a>
                            </li>
                            <li class="sctabs-tab">
                                <a class="sctab-link" href="#sched-lt">Lighting Schedule</a>
                            </li>
                        <li class="sctabs-tab">
                                <a class="sctab-link" href="#sched-p">Pumping Schedule</a>
                                    
                        </li>
                        </ul>

                        <div class="scpanels">
                            <div class="scpane active" id="sched-pc">
                                <div class="addsched">
                                    <button type="button" class="bttn bttn-primary-inverse dpopup-opener" href="#addsched-cover" onClick={()=>{
                                        handleShow();
                                            setSchedule((pre)=>{
                                                return{
                                                    ...pre,
                                                    scheduleType:1
                                                }
                                            })
                                    }}>Add New Schedule</button>
                                </div>

                                <div class="table-responsive">
                                    <table class="page-table">
                                        <tr>
                                            <th><span>Schedule Name</span></th>
                                            <th><span>Start Date</span></th>
                                            <th><span>Start Time</span></th>
                                            <th><span>End Date</span></th>
                                            <th><span>End Time</span></th>
                                            <th><span>Repeat Schedule</span></th>
                                            <th style={{width: "123px"}}><span>Action</span></th>
                                        </tr>
                {

                    
CD && CD !== "" &&  CD?.map((s,i)=>{
                        return(
                            <>
                            <tr>
                                            <td><span>{s?.name}</span></td>
                                            <td><span>{s?.startDate}</span></td>
                                            <td><span>{s?.startTime}</span></td>
                                            <td><span>{s?.endDate}</span></td>
                                            <td><span>{s?.endTime}</span></td>
                                            <td><span>{s?.repeatType=="0"?"Do Not Repeat":s?.repeatType=="1"?"Every Week Day":s?.repeatType=="2"?"Daily":s?.repeatType=="3"?"Weekly":s?.repeatType=="4"?"Monthly":s?.repeatType=="5"?"Yearly":""}</span></td>
                                            <td>
                                                <span>
                                                    <button data-target="#sched-edit" class="openpopup dpopup-opener sched-edit" type="button" onClick={()=>{
                                                        sessionStorage.setItem("updateId",s?.id)
                                                        const viewUrl=`https://84ro5mdxeg.execute-api.us-east-2.amazonaws.com/prod/schedule/${s?.id}`;
                                                        fetch(viewUrl)
                                                        .then((res)=>res.json())
                                                        .then((result)=>{
                                                            console.log(result.Data);
                                                            setEditSched((pre)=>{
                                                                return{
                                                                    ...pre,
                                                                    name:result.Data?.name,
                                                                    startDate:result.Data?.startDate,
                                                                    startTime:result.Data?.startTime,
                                                                    endDate:result.Data?.endDate,
                                                                    endTime:result.Data?.endTime,
                                                                    repeatType:result.Data?.repeatType,
                                                                    daysOfWeek:result.Data?.daysOfWeek,
                                                                    dayOfMonth:result.Data?.dayOfMonth,
                                                                    // months:result.Data?.months,
                                                                    monthRepeatYearly:result.Data?.monthRepeatYearly,
                                                                    weekDayOrdinal:result.Data?.weekDayOrdinal,
                                                                    weekDay:result.Data?.weekDay,
                                                                    repeatScheduleEndDate:result.Data?.repeatScheduleEndDate,
                                                                    scheduleType:result.Data?.scheduleType,
                                                                    repeatInterval:result.Data?.repeatInterval,
                                                                    color:result.Data?.color

                                                                }
                                                            })
                                                        })
                                                        handleShow1();
                                                        }}>
                                                        <i class="icon-edit"></i>
                                                    </button>

                                                    <button data-target="#sched-view" class="openpopup  dpopup-opener sched-view" type="button" onClick={()=>{
                                                        // sessionStorage.setItem("SchedId",s?.id)

                                                        const viewUrl=`https://84ro5mdxeg.execute-api.us-east-2.amazonaws.com/prod/schedule/${s?.id}`;
                                                        fetch(viewUrl)
                                                        .then((res)=>res.json())
                                                        .then((result)=>{
                                                            console.log(result.Data);
                                                            setViewSched(result.Data);
                                                        })
                                                    }}>
                                                        <i class="icon-view"></i>
                                                    </button>
                
                                                    <button data-target="#sched-trash" class="openpopup dpopup-opener sched-trash" type="button" onClick={()=>{
                                                        sessionStorage.setItem("delSId",s?.id)
                                                    }}>
                                                        <i class="icon-trash"></i>
                                                    </button>
                                                </span>
                                            </td>
                                        </tr>
                            </>
                        )
                    })
                }
                                        
                
                                        {/* <tr>
                                            <td><span>XYZ</span></td>
                                            <td><span>12/03/2023</span></td>
                                            <td><span>11:00 PM</span></td>
                                            <td><span>13/03/2023</span></td>
                                            <td><span>7:00 AM</span></td>
                                            <td><span>Repeat Schedule</span></td>
                                            <td>
                                                <span>
                                                    <button data-target="#sched-edit" class="openpopup sched-edit" type="button">
                                                        <i class="icon-edit"></i>
                                                    </button>

                                                    <button data-target="#sched-view" class="openpopup sched-view" type="button">
                                                        <i class="icon-view"></i>
                                                    </button>
                
                                                    <button data-target="#sched-trash" class="openpopup sched-trash" type="button">
                                                        <i class="icon-trash"></i>
                                                    </button>
                                                </span>
                                            </td>
                                        </tr>
                
                                        <tr>
                                            <td><span>PQR</span></td>
                                            <td><span>10/03/2023</span></td>
                                            <td><span>11:50 PM</span></td>
                                            <td><span>15/03/2023</span></td>
                                            <td><span>9:00 AM</span></td>
                                            <td><span>Do not repeat</span></td>
                                            <td>
                                                <span>
                                                <button data-target="#sched-edit" class="openpopup sched-edit" type="button">
                                                        <i class="icon-edit"></i>
                                                    </button>

                                                    <button data-target="#sched-view" class="openpopup sched-view" type="button">
                                                        <i class="icon-view"></i>
                                                    </button>
                
                                                    <button data-target="#sched-trash" class="openpopup sched-trash" type="button">
                                                        <i class="icon-trash"></i>
                                                    </button>
                                                </span>
                                            </td>
                                        </tr> */}
                                    </table>
                                </div>
                            </div>

                            <Modal show={show} onHide={handleClose} centered size='xl'>
        <Modal.Header className='' style={{backgroundColor:"#173F5F",color:"white"}}>
          <Modal.Title>New Schedule</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <Container fluid>
                <Form id="sForm">
                                <Row>
                                    <Col md={6}>
                                    <Form.Group className="mb-3" controlId="formGroupEmail">
        <Form.Label>Schedule Name:</Form.Label>
        <Form.Control type="text" placeholder="" name='name' onChange={handleChangeSchedule}/>
      </Form.Group>
      <Row>
        <Col md={12}>
          <Form.Group className="mb-3" controlId="formGroupEmail">
            <Form.Label><strong>{schedule.scheduleType == "1"
            ? "When do you want the pool cover on?"
            : schedule.scheduleType == "2"
            ? "When do you want the lights on?"
            : schedule.scheduleType == "3"
            ? "When do you want the pumping on?"
            : ""}</strong></Form.Label>
          </Form.Group>
        </Col>
      </Row>
      <Row>
                                <Col md={6}>
                                    <Form.Group className="mb-3" controlId="formGroupEmail">
        <Form.Label>Start Time:</Form.Label>
        <Form.Control type="time" placeholder="" name='startTime' onChange={handleChangeSchedule}/>
      </Form.Group>
                                    </Col>

                                    <Col md={6}>
                                    <Form.Group className="mb-3" controlId="formGroupEmail">
        <Form.Label>Start Date:</Form.Label>
        <Form.Control type="date" placeholder="" name='startDate' onChange={handleChangeSchedule}/>
      </Form.Group>
                                    </Col>
                                    

      </Row>
      <Row>
        <Col md={12}>
          <Form.Group className="mb-3" controlId="formGroupEmail">
            <Form.Label><strong>{schedule.scheduleType == "1"
            ? "When do you want remove the pool cover?"
            : schedule.scheduleType == "2"
            ? "When do you want turn of the lighting?"
            : schedule.scheduleType == "3"
            ? "When do you want turn off pumping?"
            : ""}</strong></Form.Label>
          </Form.Group>
        </Col>
      </Row>

      <Row>
                                <Col md={6}>
                                    <Form.Group className="mb-3" controlId="formGroupEmail">
        <Form.Label>End Time:</Form.Label>
        <Form.Control type="time" placeholder="" name='endTime' onChange={handleChangeSchedule}/>
      </Form.Group>
                                    </Col>
                                    <Col md={6}>
                                    <Form.Group className="mb-3" controlId="formGroupEmail">
        <Form.Label>End Date:</Form.Label>
        <Form.Control type="date" placeholder="" name='endDate' onChange={handleChangeSchedule}/>
      </Form.Group>
                                    </Col>
                                    


                                </Row>
    <Row>
        <Col md={12}>
          <Form.Group className="mb-3" controlId="formGroupEmail">
            <Form.Label><strong>{schedule.scheduleType == "1"
            ? "How often do you want the pool cover to on/off?"
            : schedule.scheduleType == "2"
            ? "How often do you want the pool light to on/off?"
            : schedule.scheduleType == "3"
            ? "How often do you want the pool pump to on/off?"
            : ""}
              </strong></Form.Label>
          </Form.Group>
        </Col>
      </Row>

                                <Row>
                                    <Col md={6}>
                                    <Form.Group className='mb-3' controlId="formGridState">
          <Form.Label>Repeat Schedule:</Form.Label>
          <Form.Select defaultValue="Choose..." name='repeatType'  onChange={handleChangeSchedule}>
            <option></option>
            <option value="0">Do Not Repeat</option>
            <option value="1">Every Week Day </option>
            <option value="2">Daily</option>
            <option value="3">Weekly</option>
            <option value="4">Monthly</option>
            <option value="5">Yearly</option>

          </Form.Select>
        </Form.Group>
                                    </Col>

                                    {schedule?.scheduleType == "2" ? (
                                  <Col md={6} className="clr">
                                    <Form.Group
                                      className="mb-3"
                                      controlId="formGridState"
                                    >
                                      <Form.Label>Color:</Form.Label>
                                      {/* <Form.Select defaultValue="Choose..." name='color'  onChange={handleChangeSchedule}>
                <option></option>
                <option value="1" className='clrbg clrbg1'>Blue</option>
                <option value="2" className='clrbg clrbg2'>Green</option>
                <option value="3" className='clrbg clrbg3'>Red</option>
                <option value="4" className='clrbg clrbg4'>Pink</option>
                <option value="5" className='clrbg clrbg5'>Yellow</option>
                <option value="6" className='clrbg clrbg6'>Aqua</option>
                <option value="7" className='clrbg clrbg7'>White</option>
           
    
              </Form.Select> */}

                                      <Select
                                        // defaultValue={colourOptions[2]}
                                        onChange={handleChange}
                                        options={colourOptions}
                                        styles={colourStyles}
                                      />
                                    </Form.Group>
                                  </Col>
                                ) : (
                                  ""
                                )}
                                  
                                    <Row>
<Col>
</Col>
</Row>
                                </Row>
                                    </Col>
                                </Row>


                                {/* -------------------------------- Every Week Day----------------------*/}
{
    schedule.repeatType==="1"?
    <Row className='mt-4'>

    <Col md={5}>
    {/* <Row>

    <Col >
    <p>Repeat Every : </p>
    </Col>
    <Col md={2}>
    <Form.Control type="text" placeholder="" name='daysOfWeek'/>
    
    </Col>

    <Col>
    <Form.Control type="text" value="Week day" placeholder="" disabled />
    
    </Col>
    </Row>

    
    <Row className='mt-4'>
        <Col>
        <button type="button" class="btn btn-outline-primary" data-bs-toggle="button" autocomplete="off">M</button>
        </Col>
        <Col>
        <button type="button" class="btn btn-outline-primary" data-bs-toggle="button" autocomplete="off">T</button>
        </Col>
        <Col>
        <button type="button" class="btn btn-outline-primary" data-bs-toggle="button" autocomplete="off">W</button>
        </Col>
        <Col>
        <button type="button" class="btn btn-outline-primary" data-bs-toggle="button" autocomplete="off">T</button>
        </Col>
        <Col>
        <button type="button" class="btn btn-outline-primary" data-bs-toggle="button" autocomplete="off">F</button>
        </Col>
        <Col>
        <button type="button" class="btn btn-outline-primary" data-bs-toggle="button" autocomplete="off">S</button>
        </Col>
       
        
        </Row> */}
<Row>
        <Col md={12}>
          <Form.Group className="mb-3" controlId="formGroupEmail">
            <Form.Label><strong>When do you want to end the schedule?</strong></Form.Label>
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Col md={6}>
          <Form.Group className="mb-3" controlId="formGroupSelectedDate">
            <Form.Label>Selected Start Date:</Form.Label>
            <Form.Control
              type="date"
              placeholder=""
              name="selectedDate"
              value={schedule.startDate}
              disabled
            />
          </Form.Group>
        </Col>
      </Row>
  <Row className=''>
        <Col md={5}>
        <Form.Group className="mb-3" controlId="formGroupEmail">
        <Form.Label>End Date:</Form.Label>
        <Form.Control type="date" placeholder="" name='repeatScheduleEndDate' onChange={handleChangeSchedule}/>
      </Form.Group>
        </Col>
        </Row>
    </Col>
   
    </Row>:""
}






                                {/* ------------------------------------------------- */}






                                {/* ===============================================daily======================== */}

{
    schedule.repeatType==="2"?
    <Row className='mt-4'>
    <Col md={5}>
    {/* <Row>
        <Col>
        <p>Repeat Every : </p>
        </Col>
        <Col md={2}>
    <Form.Control type="text" placeholder="" />
        
        </Col>
        <Col>
    <Form.Control type="text" placeholder="Day" disabled />
        
    </Col>
    </Row> */}
<Row>
        <Col md={12}>
          <Form.Group className="mb-3" controlId="formGroupEmail">
            <Form.Label><strong>When do you want to end the schedule?</strong></Form.Label>
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Col md={6}>
          <Form.Group className="mb-3" controlId="formGroupSelectedDate">
            <Form.Label>Selected Start Date:</Form.Label>
            <Form.Control
              type="date"
              placeholder=""
              name="selectedDate"
              value={schedule.startDate}
              disabled
            />
          </Form.Group>
        </Col>
      </Row>
   <Row className=''>
        <Col md={5}>
        <Form.Group className="mb-3" controlId="formGroupEmail">
        <Form.Label>End Date:</Form.Label>
        <Form.Control type="date" placeholder="" name='repeatScheduleEndDate' onChange={handleChangeSchedule}/>
      </Form.Group>
        </Col>
        </Row>
    </Col>
    </Row>:""
}




{/* ================================== */}


{/* --------------------------weekly------------------------------ */}

{
    schedule.repeatType==="3"?

    
    <Row className='mt-4'>
<Row>
        <Col md={12}>
          <Form.Group className="mb-3" controlId="formGroupEmail">
            <Form.Label><strong>When do you want to end the schedule?</strong></Form.Label>
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Col md={6}>
          <Form.Group className="mb-3" controlId="formGroupSelectedDate">
            <Form.Label>Selected Start Date:</Form.Label>
            <Form.Control
              type="date"
              placeholder=""
              name="selectedDate"
              value={schedule.startDate}
              disabled
            />
          </Form.Group>
        </Col>
      </Row>

<Col md={6}>
<Row>

<Col >
<p>Repeat Every : </p>
</Col>
<Col md={2}>
<Form.Control type="text" placeholder="" name='repeatInterval' onChange={handleChangeSchedule}/>

</Col>

<Col>
<Form.Control type="text" value="Week" placeholder="" disabled />

</Col>
</Row>

 
<Row className='mt-4'>
    <Col xs={2} sm={1} className="mb-2">
    <button type="button" class="btn btn-outline-primary" data-bs-toggle="button" name='daysOfWeek' value="0" autocomplete="off" onClick={handlecheck}>S</button>
    </Col>
    <Col xs={2} sm={1} className="mb-2">
    <button type="button" class="btn btn-outline-primary" data-bs-toggle="button" name='daysOfWeek' value="1" autocomplete="off" onClick={handlecheck}>M</button>
    </Col>
    <Col xs={2} sm={1} className="mb-2">
    <button type="button" class="btn btn-outline-primary" data-bs-toggle="button" name='daysOfWeek' value="2" autocomplete="off" onClick={handlecheck}>T</button>
    </Col>
    <Col xs={2} sm={1} className="mb-2">
    <button type="button" class="btn btn-outline-primary" data-bs-toggle="button" name='daysOfWeek' value="3" autocomplete="off" onClick={handlecheck}>W</button>
    </Col>
    <Col xs={2} sm={1} className="mb-2">
    <button type="button" class="btn btn-outline-primary" data-bs-toggle="button" name='daysOfWeek' value="4" autocomplete="off" onClick={handlecheck}>T</button>
    </Col>
    <Col xs={2} sm={1} className="mb-2">
    <button type="button" class="btn btn-outline-primary" data-bs-toggle="button" name='daysOfWeek' value="5" autocomplete="off" onClick={handlecheck}>F</button>
    </Col>
    <Col xs={2} sm={1} className="mb-2">
    <button type="button" class="btn btn-outline-primary" data-bs-toggle="button" name='daysOfWeek' value="6" autocomplete="off" onClick={handlecheck}>S</button>
    </Col>
   
   
</Row>

<Row className='mt-4'>
        <Col md={5}>
        <Form.Group className="mb-3" controlId="formGroupEmail">
        <Form.Label>End Date:</Form.Label>
        <Form.Control type="date" placeholder="" name='repeatScheduleEndDate' onChange={handleChangeSchedule}/>
      </Form.Group>
        </Col>
        </Row>
</Col>

</Row>:""

}




{/* ------------------------------------- */}


{/* ---------------------------------monthly---------------------------- */}
{

    schedule.repeatType==="4"?
    <Row>
    <Col>
    <Row>
        <Col md={12}>
          <Form.Group className="mb-3" controlId="formGroupEmail">
            <Form.Label><strong>When do you want to end the schedule?</strong></Form.Label>
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Col md={6}>
          <Form.Group className="mb-3" controlId="formGroupSelectedDate">
            <Form.Label>Selected Start Date:</Form.Label>
            <Form.Control
              type="date"
              placeholder=""
              name="selectedDate"
              value={schedule.startDate}
              disabled
            />
          </Form.Group>
        </Col>
      </Row>

    <Row className='mt-4'>

<Col md={5}>
<Row>

<Col >
<p>Repeat Every : </p>
</Col>
<Col md={2}>
<Form.Control type="text" placeholder=""  name='repeatInterval' onChange={handleChangeSchedule}/>

</Col>

<Col>
<Form.Control type="text" value="Month"  placeholder="" disabled />

</Col>
</Row>

<Row className='mt-4'>
    <Col md={5}>
<Form.Check type="radio" value="" id='monthDay' label="On day" name='monthly' placeholder="" onChange={handleChangeSchedule}/>
    
    </Col>
    <Col md={2}>
<Form.Control type="text" placeholder="" name='dayOfMonth' id='mInt' onChange={handleChangeSchedule}/>
    
    </Col>
</Row>

</Col>

</Row>

<Row className='mt-4'>
    <Col md={2}>
<Form.Check type="radio" value="" id='monthOn' label="On the" name='monthly' placeholder="" onChange={handleChangeSchedule}/>
    
    </Col>

    <Col md={3}>
    <Form.Select defaultValue="Choose..." name='weekDayOrdinal' id='monthOnVals' onChange={handleChangeSchedule}>
            <option></option>
            <option value="1">First</option>
            <option value="2">Second </option>
            <option value="3">Third</option>
            <option value="4">Fourth</option>
            <option value="5">Last</option>
           
          </Form.Select>
    </Col>
    <Col md={3}>
    <Form.Select defaultValue="Choose......" name='weekDay' id='monthOnVals1' onChange={handleChangeSchedule}>
            <option></option>
            <option value="0">Sunday</option>
            <option value="1">Monday</option>
            <option value="2">Tuesday</option>
            <option value="3">Wednesday</option>
            <option value="4">Thursday</option>
            <option value="5">Friday</option>
            <option value="6">Saturday</option>

          </Form.Select>
    </Col>

</Row>


<Row className='mt-4'>
        <Col md={5}>
        <Form.Group className="mb-3" controlId="formGroupEmail">
        <Form.Label>End Date:</Form.Label>
        <Form.Control type="date" placeholder="" name='repeatScheduleEndDate' onChange={handleChangeSchedule}/>
      </Form.Group>
        </Col>
        </Row>
    </Col>
</Row>:""
}





{/* ------------------------------ */}




{/* ----------------------------yearly---------------------- */}

{
    schedule.repeatType==="5"?
<Row>
    <Col>
    <Row>
        <Col md={12}>
          <Form.Group className="mb-3" controlId="formGroupEmail">
            <Form.Label><strong>When do you want to end the schedule?</strong></Form.Label>
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Col md={6}>
          <Form.Group className="mb-3" controlId="formGroupSelectedDate">
            <Form.Label>Selected Start Date:</Form.Label>
            <Form.Control
              type="date"
              placeholder=""
              name="selectedDate"
              value={schedule.startDate}
              disabled
            />
          </Form.Group>
        </Col>
      </Row>

    <Row className='mt-4'>

<Col md={5}>
<Row>

<Col >
<p>Repeat Every : </p>
</Col>
<Col md={2}>
<Form.Control type="text" placeholder="" name='repeatInterval' onChange={handleChangeSchedule}/>

</Col>

<Col>
<Form.Control type="text" value="Year" placeholder="" disabled />

</Col>
</Row>

<Row className='mt-4'>
    <Col md={5}>
<Form.Check type="radio" value="" label="On day" id='yearDay' name='yearly' placeholder="" onChange={handleChangeSchedule}/>
    
    </Col>
    <Col md={2}>
<Form.Control type="text" placeholder="" id='yInt' name='dayOfMonth'onChange={handleChangeSchedule}/>
    
    </Col>
    <Col md={5}>
    <Form.Select defaultValue="Choose......" name='monthRepeatYearly' id="eyInt1" onChange={handleChangeSchedule}>
            <option></option>
            <option value="1">January</option>
            <option value="2">February</option>
            <option value="3">March</option>
            <option value="4">April</option>
            <option value="5">May</option>
            <option value="6">June</option>
            <option value="7">July</option>
            <option value="8">August</option>
            <option value="9">September</option>
            <option value="10">October</option>
            <option value="11">November</option>
            <option value="12">December</option>
           

          </Form.Select>
    </Col>
</Row>

</Col>

</Row>

<Row className='mt-4'>
    <Col md={2}>
<Form.Check type="radio" value="" id='yearOn'  label="On the" name='yearly' placeholder="" onChange={handleChangeSchedule}/>
    
    </Col>

    <Col md={3}>
    <Form.Select defaultValue="Choose..." name='weekDayOrdinal' id="yearVals1" onChange={handleChangeSchedule}>
            <option></option>
            <option value="1">First</option>
            <option value="2">Second </option>
            <option value="3">Third</option>
            <option value="4">Fourth</option>
            <option value="5">Last</option>
           
          </Form.Select>
    </Col>
    <Col md={3}>
    <Form.Select defaultValue="Choose......" name='weekDay' id="yearVals2" onChange={handleChangeSchedule}>
            <option></option>
            <option value="0">Sunday</option>
            <option value="1">Monday</option>
            <option value="2">Tuesday</option>
            <option value="3">Wednesday</option>
            <option value="4">Thursday</option>
            <option value="5">Friday</option>
            <option value="6">Saturday</option>

          </Form.Select>
    </Col>
    <Col md={1}>
    <p className='text-center pt-2 fs-5'>of</p>
</Col>
    <Col md={3}>
    <Form.Select defaultValue="Choose......" name='monthRepeatYearly' id="yearVals3" onChange={handleChangeSchedule}>
            <option></option>
            <option value="1">January</option>
            <option value="2">February</option>
            <option value="3">March</option>
            <option value="4">April</option>
            <option value="5">May</option>
            <option value="6">June</option>
            <option value="7">July</option>
            <option value="8">August</option>
            <option value="9">September</option>
            <option value="10">October</option>
            <option value="11">November</option>
            <option value="12">December</option>
           

          </Form.Select>
    </Col>

</Row>

<Row className='mt-4'>
        <Col md={5}>
        <Form.Group className="mb-3" controlId="formGroupEmail">
        <Form.Label>End Date:</Form.Label>
        <Form.Control type="date" placeholder="" name='repeatScheduleEndDate' onChange={handleChangeSchedule}/>
      </Form.Group>
        </Col>
        </Row>
    </Col>
</Row>:""
}




{/* ------------------------------- */}
                    </Form>           
            </Container>
        </Modal.Body>
        <Modal.Footer className='m-auto'>
          <Button variant="" className='back-sched' onClick={handleClose}>
            Cancel
          </Button>
          <Button variant="" className='save-sched' onClick={handleScheduleSubmit}>
            Save 
          </Button>
        </Modal.Footer>
      </Modal>

                            <div class="scpane" id="sched-lt">
                                <div class="addsched">
                                    <button type="button" class="bttn bttn-primary-inverse dpopup-opener" href="#addsched-light" onClick={()=>{
                                        handleShow();
                                            setSchedule((pre)=>{
                                                return{
                                                    ...pre,
                                                    scheduleType:2
                                                }
                                            })
                                    }}>Add New Schedule</button>
                                </div>

                                <div class="table-responsive">
                                    <table class="page-table">
                                        <tr>
                                            <th><span>Schedule Name</span></th>
                                            <th><span>Start Date</span></th>
                                            <th><span>Start Time</span></th>
                                            <th><span>End Date</span></th>
                                            <th><span>End Time</span></th>
                                            <th><span>Repeat Schedule</span></th>
                                            <th><span>Color</span></th>
                                            <th style={{width: "123px"}}><span>Action</span></th>
                                        </tr>
{
    LD && LD !== "" &&  LD?.map((l,i)=>{
        return(
            <>
             <tr>
                                            <td><span>{l?.name}</span></td>
                                            <td><span>{l?.startDate}</span></td>
                                            <td><span>{l?.startTime}</span></td>
                                            <td><span>{l?.endDate}</span></td>
                                            <td><span>{l?.endTime}</span></td>
                                            <td><span>{l?.repeatType=="0"?"Do Not Repeat":l?.repeatType=="1"?"Every Week Day":l?.repeatType=="2"?"Daily":l?.repeatType=="3"?"Weekly":l?.repeatType=="4"?"Monthly":l?.repeatType=="5"?"Yearly":""}</span></td>
                                            <td><span>{l?.color=="1"?"Blue":l?.color=="2"?"Green":l?.color=="3"?"Red":l?.color=="4"?"Pink":l?.color=="5"?"Yellow":l?.color=="6"?"Aqua":l?.color=="7"?"White":""}</span></td>
                                            <td>
                                                <span>
                                                    <button data-target="#sched-edit" class="openpopup dpopup-opener sched-edit" type="button" onClick={()=>{
                                                        sessionStorage.setItem("updateId",l?.id)
                                                        const viewUrl=`https://84ro5mdxeg.execute-api.us-east-2.amazonaws.com/prod/schedule/${l?.id}`;
                                                        fetch(viewUrl)
                                                        .then((res)=>res.json())
                                                        .then((result)=>{
                                                            console.log(result.Data);
                                                            setEditSched((pre)=>{
                                                                return{
                                                                    ...pre,
                                                                    name:result.Data?.name,
                                                                    startDate:result.Data?.startDate,
                                                                    startTime:result.Data?.startTime,
                                                                    endDate:result.Data?.endDate,
                                                                    endTime:result.Data?.endTime,
                                                                    repeatType:result.Data?.repeatType,
                                                                    daysOfWeek:result.Data?.daysOfWeek,
                                                                    dayOfMonth:result.Data?.dayOfMonth,
                                                                    // months:result.Data?.months,
                                                                    monthRepeatYearly:result.Data?.monthRepeatYearly,
                                                                    weekDayOrdinal:result.Data?.weekDayOrdinal,
                                                                    weekDay:result.Data?.weekDay,
                                                                    repeatScheduleEndDate:result.Data?.repeatScheduleEndDate,
                                                                    scheduleType:result.Data?.scheduleType,
                                                                    repeatInterval:result.Data?.repeatInterval,
                                                                    color:result.Data?.color

                                                                }
                                                            })
                                                        })
                                                        handleShow1();
                                                        }}>
                                                        <i class="icon-edit"></i>
                                                    </button>

                                                    <button data-target="#sched-view" class="openpopup  dpopup-opener sched-view" type="button" onClick={()=>{
                                                        // sessionStorage.setItem("SchedId",s?.id)

                                                        const viewUrl=`https://84ro5mdxeg.execute-api.us-east-2.amazonaws.com/prod/schedule/${l?.id}`;
                                                        fetch(viewUrl)
                                                        .then((res)=>res.json())
                                                        .then((result)=>{
                                                            console.log(result.Data);
                                                            setViewSched(result.Data);
                                                        })
                                                    }}>
                                                        <i class="icon-view"></i>
                                                    </button>
                
                                                    <button data-target="#sched-trash" class="openpopup dpopup-opener sched-trash" type="button" onClick={()=>{
                                                        sessionStorage.setItem("delSId",l?.id)
                                                    }}>
                                                        <i class="icon-trash"></i>
                                                    </button>
                                                </span>
                                            </td>
                                        </tr>
            </>
        )
    })
}
                                       
                
                                  
                                    </table>
                                </div>
                            </div>




                            <div class="scpane" id="sched-p">
                                <div class="addsched">
                                    <button type="button" class="bttn bttn-primary-inverse dpopup-opener" href="#addsched-pump" onClick={()=>{
                                        handleShow();
                                            setSchedule((pre)=>{
                                                return{
                                                    ...pre,
                                                    scheduleType:3
                                                }
                                            })
                                    }}>Add New Schedule</button>
                                </div>

                                <div class="table-responsive">
                                    <table class="page-table">
                                        <tr>
                                            <th><span>Schedule Name</span></th>
                                            <th><span>Start Date</span></th>
                                            <th><span>Start Time</span></th>
                                            <th><span>End Date</span></th>
                                            <th><span>End Time</span></th>
                                            <th><span>Repeat Schedule</span></th>
                                            <th style={{width: "123px"}}><span>Action</span></th>
                                        </tr>

                                        {
                             PD && PD !== "" &&  PD?.map((p,i)=>{
                                                return(
                                                    <>
                                                      <tr>
                                            <td><span>{p?.name}</span></td>
                                            <td><span>{p?.startDate}</span></td>
                                            <td><span>{p?.startTime}</span></td>
                                            <td><span>{p?.endDate}</span></td>
                                            <td><span>{p?.endTime}</span></td>
                                            <td><span>{p?.repeatType=="0"?"Do Not Repeat":p?.repeatType=="1"?"Every Week Day":p?.repeatType=="2"?"Daily":p?.repeatType=="3"?"Weekly":p?.repeatType=="4"?"Monthly":p?.repeatType=="5"?"Yearly":""}</span></td>
                                            <td>
                                                <span>
                                                    <button data-target="#sched-edit" class="openpopup dpopup-opener sched-edit" type="button" onClick={()=>{
                                                        sessionStorage.setItem("updateId",p?.id)
                                                        const viewUrl=`https://84ro5mdxeg.execute-api.us-east-2.amazonaws.com/prod/schedule/${p?.id}`;
                                                        fetch(viewUrl)
                                                        .then((res)=>res.json())
                                                        .then((result)=>{
                                                            console.log(result.Data);
                                                            // setVESched(result.Data);
                                                            setEditSched((pre)=>{
                                                                return{
                                                                    ...pre,
                                                                    name:result.Data?.name,
                                                                    startDate:result.Data?.startDate,
                                                                    startTime:result.Data?.startTime,
                                                                    endDate:result.Data?.endDate,
                                                                    endTime:result.Data?.endTime,
                                                                    repeatType:result.Data?.repeatType,
                                                                    daysOfWeek:result.Data?.daysOfWeek,
                                                                    dayOfMonth:result.Data?.dayOfMonth,
                                                                    // months:result.Data?.months,
                                                                    monthRepeatYearly:result.Data?.monthRepeatYearly,
                                                                    weekDayOrdinal:result.Data?.weekDayOrdinal,
                                                                    weekDay:result.Data?.weekDay,
                                                                    repeatScheduleEndDate:result.Data?.repeatScheduleEndDate,
                                                                    scheduleType:result.Data?.scheduleType,
                                                                    repeatInterval:result.Data?.repeatInterval,
                                                                    color:result.Data?.color
                                                                }
                                                            })
                                                        })
                                                        handleShow1();
                                                        }}>
                                                        <i class="icon-edit"></i>
                                                    </button>

                                                    <button data-target="#sched-view" class="openpopup dpopup-opener sched-view" type="button" onClick={()=>{
                                                        // sessionStorage.setItem("SchedId",s?.id)

                                                        const viewUrl=`https://84ro5mdxeg.execute-api.us-east-2.amazonaws.com/prod/schedule/${p?.id}`;
                                                        fetch(viewUrl)
                                                        .then((res)=>res.json())
                                                        .then((result)=>{
                                                            console.log(result.Data);
                                                            setViewSched(result.Data);
                                                        })
                                                    }}>
                                                        <i class="icon-view"></i>
                                                    </button>
                
                                                    <button data-target="#sched-trash" class="openpopup dpopup-opener sched-trash" type="button" onClick={()=>{
                                                        sessionStorage.setItem("delSId",p?.id)
                                                    }}>
                                                        <i class="icon-trash"></i>
                                                    </button>
                                                </span>
                                            </td>
                                        </tr>
                                                    </>
                                                )
                                            })
                                        }

                                      
                
                                      
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* <!--History--> */}
                <div class="panels-item" id="navhist">
                    <div class="panelcard">
                        History List here...
                    </div>
                </div>
            </div>
        </div>

        <div class="navbar">
            <div class="container">
                <ul class="navbar-list tabs" style={{width:"100%"}}>
                    <li class="tabs-tab">
                        <a class="tabs-link active" href="#navcover">
                            <i class="icon-hpc-hex"></i>
                            <span>Cover</span>
                        </a>
                    </li>
                    <li class="tabs-tab">
                        <a class="tabs-link" href="#navlight">
                            <i class="icon-lighting"></i>
                            <span>Lighting</span>
                        </a>
                    </li>
                    <li class="tabs-tab">
                        <a class="tabs-link" href="#navpump">
                            <i class="icon-pumping"></i>
                            <span>Pumping</span>
                        </a>
                    </li>
                    <li class="tabs-tab">
                        <a class="tabs-link" href="#navsched">
                            <i class="icon-clock"></i>
                            <span>Scheduling</span>
                        </a>
                    </li>
                    <li class="tabs-tab">
                        <a class="tabs-link" href="#navhist">
                            <i class="icon-history"></i>
                            <span>History</span>
                        </a>
                    </li>
                </ul>
            </div>        
        </div>
    </main>



    {/* <!--Sensor Warning Popup--> */}
    <div id="sensor-failure" class="dpopup dpopup-sensorfail" style={{display: "none"}}>
        <div class="dpopup-wrapper">
            <div class="dpopup-head">
                <button class="dpopup-close bttn-close ml-auto" type="button" role="close"><i class="icon-cross"></i></button>
            </div>
            <div class="dpopup-body text-center">
                <h2 class="sensorfail-title">
                    <i class="icon-sensor"></i>
                    <span class="text--red">Sensor Failure</span>
                </h2>
                
                <div class="sensorfail-cont">
                    <i class="icon-error"></i>
                    <h3>Cover on sensor failed to start</h3>
                </div>
            </div>
            <div class="dpopup-footer"></div>
        </div>
    </div>

    {/* <!--Delete Successfully Popup--> */}
    <div id="sched-trash" class="dpopup dpopup-schedtrash " style={{display: "none"}}>
        <div class="dpopup-wrapper">
            <div class="dpopup-head">
                <button class="dpopup-close bttn-close ml-auto" type="button" role="close"><i class="icon-close"></i></button>
            </div>
            <div class="dpopup-body text-center">
                <h2 class="dpopup-title">
                    <div class="dpopup-alerttxt alert-trashwarn">
                        Delete this schedule?
                    </div>
                    {/* <div class="dpopup-alerttxt alert-trashdone" style={{display: "none"}}>
                        <span class="text-center">Schedule deleted successfully</span>
                        <i class="icon-tick-circle text--green"></i>
                    </div> */}
                </h2>

                <div class="popup-action">
                    <button class="bttn bttn-secondary-inverse bttn-md dpopup-close" role="close">Cancel</button>
                    <button class="bttn bttn-primary bttn-md dpopup-trash" onClick={()=>{
                        let id=sessionStorage.getItem("delSId")
                        const delUrl=`https://84ro5mdxeg.execute-api.us-east-2.amazonaws.com/prod/schedule/${id}`;
                                       
                       
                        fetch(delUrl,{
                            method:"DELETE",
                            headers:{
                               Accept: "application/json",
                               'Content-Type': 'application/json'
                             },
                             body:""
                        }).then((res)=>res.json())
                        .then((delRes)=>{
                            console.log(delRes);
                            if(delRes.Status===200){
                               let talert=document.getElementById("sched-trash");

                               talert.style.display="none";
                               Swal.fire({
                                   icon:"success",
                                   title:"Deleted successfully!"
                               })
                             
                            }
                        })
                    }}>Delete</button>
                </div>
            </div>
            <div class="dpopup-footer"></div>
        </div>
    </div>

    {/* <!--View Schedule Popup--> */}
    <div id="sched-view" class="dpopup dpopup-viewsc dpopup-backart" style={{display: "none"}}>
        <div class="dpopup-wrapper">
            <div class="dpopup-head">
                <button class="dpopup-close bttn-close ml-auto" type="button" role="close"><i class="icon-close"></i></button>
            </div>
            <div class="dpopup-body">
                <h2 class="dpopup-title text-center">
                    <span>View Pool Cover Schedule</span>
                </h2>

                <div class="viewscdet container-fluid">
                    <div class="row">
                        <div class="col-12">
                            <span class="viewscdet-label">Schedule Name:</span>
                            <span class="viewscdet-val" id="view-schedname">{viewSched?.name}</span>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12 col-sm-auto">
                            <span class="viewscdet-label">Start Date:</span>
                            <span class="viewscdet-val" id="view-startdate">{viewSched?.startDate}</span>
                        </div>
                        <div class="col-12 col-sm-auto">
                            <span class="viewscdet-label">Start Time:</span>
                            <span class="viewscdet-val" id="view-starttime">{viewSched?.startTime}</span>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12 col-sm-auto">
                            <span class="viewscdet-label">End Date:</span>
                            <span class="viewscdet-val" id="view-enddate">{viewSched?.endDate}</span>
                        </div>
                        <div class="col-12 col-sm-auto">
                            <span class="viewscdet-label">End Time:</span>
                            <span class="viewscdet-val" id="view-endtime">{viewSched?.endTime}</span>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12">
                            <span class="viewscdet-label">Repeat Schedule:</span>
                            <span class="viewscdet-val" id="view-schedrep">{viewSched?.repeatType}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>




    <Modal show={show1} onHide={handleClose1} centered size='xl' backdrop="static"
        keyboard={false}>
        {/* <Modal.Header closeButton>
          <Modal.Title>Modal heading</Modal.Title>
        </Modal.Header> */}
        {/* <Modal.Body>
            <Form>
                <Row>
                <Col md={6}>
                <Form.Group className="mb-3" controlId="formGroupEmail">
        <Form.Label>Start Date:</Form.Label>
        <Form.Control type="date" placeholder="" name='startDate' onChange={handleChangeEdit}/>
      </Form.Group>
                                    </Col>
                                    <Col md={6}>
                                    <Form.Group className="mb-3" controlId="formGroupEmail">
        <Form.Label>Start Time:</Form.Label>
        <Form.Control type="time" step="1" placeholder="" name='startTime' onChange={handleChangeEdit}/>
      </Form.Group>
      </Col>


                                </Row>
      <Row>
                                    <Col md={6}>
                                    <Form.Group className="mb-3" controlId="formGroupEmail">
        <Form.Label>End Date:</Form.Label>
        <Form.Control type="date" placeholder="" name='endDate' onChange={handleChangeEdit}/>
      </Form.Group>
                                    </Col>
                                    <Col md={6}>
                                    <Form.Group className="mb-3" controlId="formGroupEmail">
        <Form.Label>End Time:</Form.Label>
        <Form.Control type="time" step="1" placeholder="" name='endTime' onChange={handleChangeEdit}/>
      </Form.Group>
                                    </Col>
                                    

                                </Row>

                                <Row>
                                    <Col>
                                    <Form.Group className='mb-3' controlId="formGridState">
          <Form.Label>Repeat Schedule:</Form.Label>
          <Form.Select defaultValue="Choose..." name='repeatType'  onChange={handleChangeEdit}>
          <option></option>
            <option value="0">Do Not Repeat</option>
            <option value="1">Every Week Day </option>
            <option value="2">Daily</option>
            <option value="3">Weekly</option>
            <option value="4">Monthly</option>
            <option value="5">Yearly</option>

          </Form.Select>
        </Form.Group>
                                    </Col>
                                </Row>
            </Form>
        </Modal.Body> */}
          <Modal.Body>
            <Container fluid>
                <Form id="sEForm">
                                <Row>
                                    <Col>
                                    <Form.Group className="mb-3" controlId="formGroupEmail">
        <Form.Label>Schedule Name:</Form.Label>
        <Form.Control type="text" placeholder="" name='name' value={editSched?.name} onChange={handleChangeEdit}/>
      </Form.Group>
      <Row>
        <Col md={12}>
          <Form.Group className="mb-3" controlId="formGroupEmail">
            <Form.Label><strong>{schedule.scheduleType == "1"
            ? "When do you want the pool cover on?"
            : schedule.scheduleType == "2"
            ? "When do you want the lights on?"
            : schedule.scheduleType == "3"
            ? "When do you want the pumping on?"
            : ""}</strong></Form.Label>
          </Form.Group>
        </Col>
      </Row>
      <Row>
                                  <Col md={6}>
                                    <Form.Group className="mb-3" controlId="formGroupEmail">
        <Form.Label>Start Time:</Form.Label>
        <Form.Control type="time" placeholder="" name='startTime' value={editSched?.startTime} onChange={handleChangeEdit}/>
      </Form.Group>
                                    </Col>
                                    <Col md={6}>
                                    <Form.Group className="mb-3" controlId="formGroupEmail">
        <Form.Label>Start Date:</Form.Label>
        <Form.Control type="date" placeholder="" name='startDate' value={editSched?.startDate} onChange={handleChangeEdit}/>
      </Form.Group>
                                    </Col>
                                    


                                </Row>
                                <Row>
        <Col md={12}>
          <Form.Group className="mb-3" controlId="formGroupEmail">
            <Form.Label><strong>{schedule.scheduleType == "1"
            ? "When do you want remove the pool cover?"
            : schedule.scheduleType == "2"
            ? "When do you want turn of the lighting?"
            : schedule.scheduleType == "3"
            ? "When do you want turn off pumping?"
            : ""}</strong></Form.Label>
          </Form.Group>
        </Col>
      </Row>
      <Row>
                                   <Col md={6}>
                                    <Form.Group className="mb-3" controlId="formGroupEmail">
        <Form.Label>End Time:</Form.Label>
        <Form.Control type="time" placeholder="" name='endTime' value={editSched?.endTime} onChange={handleChangeEdit}/>
      </Form.Group>
                                    </Col>
                                    <Col md={6}>
                                    <Form.Group className="mb-3" controlId="formGroupEmail">
        <Form.Label>End Date:</Form.Label>
        <Form.Control type="date" placeholder="" name='endDate' value={editSched?.endDate} onChange={handleChangeEdit}/>
      </Form.Group>
                                    </Col>
                                   


                                </Row>
      <Row>
        <Col md={12}>
          <Form.Group className="mb-3" controlId="formGroupEmail">
            <Form.Label><strong>{schedule.scheduleType == "1"
            ? "How often do you want the pool cover to on/off?"
            : schedule.scheduleType == "2"
            ? "How often do you want the pool light to on/off?"
            : schedule.scheduleType == "3"
            ? "How often do you want the pool pump to on/off?"
            : ""}</strong></Form.Label>
          </Form.Group>
        </Col>
      </Row>

                                <Row>
                                    <Col md={6}>
                                    <Form.Group className='mb-3' controlId="formGridState">
          <Form.Label>Repeat Schedule:</Form.Label>
          <Form.Select defaultValue="Choose..." name='repeatType' value={editSched?.repeatType}  onChange={handleChangeEdit}>
            <option></option>
            <option value="0">Do Not Repeat</option>
            <option value="1">Every Week Day </option>
            <option value="2">Daily</option>
            <option value="3">Weekly</option>
            <option value="4">Monthly</option>
            <option value="5">Yearly</option>

          </Form.Select>
        </Form.Group>
                                    </Col>
                                    {editSched.scheduleType == "2" ? (
                      <Col md={6} className="clr">
                        <Form.Group className="mb-3" controlId="formGridState">
                          <Form.Label>Color:</Form.Label>
                          {/* <Form.Select
                            defaultValue="Choose..."
                            name="color"
                            value={editSched?.color}
                            onChange={handleChangeEdit}
                          >
                            <option></option>
                            <option value="1" className="clrbg clrbg1">
                              Blue
                            </option>
                            <option value="2" className="clrbg clrbg2">
                              Green
                            </option>
                            <option value="3" className="clrbg clrbg3">
                              Red
                            </option>
                            <option value="4" className="clrbg clrbg4">
                              Pink
                            </option>
                            <option value="5" className="clrbg clrbg5">
                              Yellow
                            </option>
                            <option value="6" className="clrbg clrbg6">
                              Aqua
                            </option>
                            <option value="7" className="clrbg clrbg7">
                              White
                            </option>
                     
                          </Form.Select> */}

<Select
                                        // defaultValue={colourOptions[2]}
                                        defaultValue={
                                          editSched.color == 0
                                            ? ""
                                            : "" || editSched.color == 1
                                            ? colourOptions[0]
                                            : "" || editSched.color == 2
                                            ? colourOptions[1]
                                            : "" || editSched.color == 3
                                            ? colourOptions[2]
                                            : "" || editSched.color == 4
                                            ? colourOptions[3]
                                            : "" || editSched.color == 5
                                            ? colourOptions[4]
                                            : "" || editSched.color == 6
                                            ? colourOptions[5]
                                            : "" || editSched.color == 7
                                            ? colourOptions[6]
                                            : ""
                                        }
                                        onChange={handleChange}
                                        options={colourOptions}
                                        styles={colourStyles}
                                      />
                        </Form.Group>
                      </Col>
                    ) : (
                      ""
                    )}
                                   
                                    <Row>
<Col>
</Col>
</Row>
                                </Row>
                                    </Col>
                                </Row>


                                {/* -------------------------------- Every Week Day----------------------*/}
{
    editSched.repeatType=="1"?
    <Row className='mt-4'>
    
    <Col md={5}>
    {/* <Row>

    <Col >
    <p>Repeat Every : </p>
    </Col>
    <Col md={2}>
    <Form.Control type="text" placeholder="" name='daysOfWeek'/>
    
    </Col>

    <Col>
    <Form.Control type="text" value="Week day" placeholder="" disabled />
    
    </Col>
    </Row>
    

    <Row className='mt-4'>
    <Col>
        <button type="button" class="btn btn-outline-primary" data-bs-toggle="button" autocomplete="off">M</button>
        </Col>
        <Col>
        <button type="button" class="btn btn-outline-primary" data-bs-toggle="button" autocomplete="off">T</button>
        </Col>
        <Col>
        <button type="button" class="btn btn-outline-primary" data-bs-toggle="button" autocomplete="off">W</button>
        </Col>
        <Col>
        <button type="button" class="btn btn-outline-primary" data-bs-toggle="button" autocomplete="off">T</button>
        </Col>
        <Col>
        <button type="button" class="btn btn-outline-primary" data-bs-toggle="button" autocomplete="off">F</button>
        </Col>
        <Col>
        <button type="button" class="btn btn-outline-primary" data-bs-toggle="button" autocomplete="off">S</button>
        </Col>
       
       
    </Row> */}
    <Row>
        <Col md={12}>
          <Form.Group className="mb-3" controlId="formGroupEmail">
            <Form.Label><strong>When do you want to end the schedule?</strong></Form.Label>
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Col md={6}>
          <Form.Group className="mb-3" controlId="formGroupSelectedDate">
            <Form.Label>Selected Start Date:</Form.Label>
            <Form.Control
              type="date"
              placeholder=""
              name="selectedDate"
              value={editSched?.startDate}
              disabled
            />
          </Form.Group>
        </Col>
      </Row>

    <Row className='mt-4'>
        <Col md={5}>
        <Form.Group className="mb-3" controlId="formGroupEmail">
        <Form.Label>End Date:</Form.Label>
        <Form.Control type="date" placeholder="" name='repeatScheduleEndDate' value={editSched?.repeatScheduleEndDate} onChange={handleChangeEdit}/>
      </Form.Group>
        </Col>
        </Row>
        </Col>
   
        </Row>:""
}






                                {/* ------------------------------------------------- */}






                                {/* ===============================================daily======================== */}

{
    editSched.repeatType=="2"?
    <Row className='mt-4'>
    <Col md={5}>
    {/* <Row>
    <Col>
        <p>Repeat Every : </p>
        </Col>
        <Col md={2}>
    <Form.Control type="text" placeholder="" />
        
        </Col>
        <Col>
    <Form.Control type="text" placeholder="Day" disabled />
        
        </Col>
    </Row> */}
    <Row>
        <Col md={12}>
          <Form.Group className="mb-3" controlId="formGroupEmail">
            <Form.Label><strong>When do you want to end the schedule?</strong></Form.Label>
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Col md={6}>
          <Form.Group className="mb-3" controlId="formGroupSelectedDate">
            <Form.Label>Selected Start Date:</Form.Label>
            <Form.Control
              type="date"
              placeholder=""
              name="selectedDate"
              value={editSched?.startDate}
              disabled
            />
          </Form.Group>
        </Col>
      </Row>

    <Row className='mt-4'>
        <Col md={5}>
        <Form.Group className="mb-3" controlId="formGroupEmail">
        <Form.Label>End Date:</Form.Label>
        <Form.Control type="date" placeholder="" name='repeatScheduleEndDate' value={editSched?.repeatScheduleEndDate} onChange={handleChangeEdit}/>
      </Form.Group>
        </Col>
        </Row>
    </Col>
</Row>:""
}




{/* ================================== */}


{/* --------------------------weekly------------------------------ */}

{
    editSched.repeatType=="3"?
    <Row className='mt-4'>
      <Row>
        <Col md={12}>
          <Form.Group className="mb-3" controlId="formGroupEmail">
            <Form.Label><strong>When do you want to end the schedule?</strong></Form.Label>
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Col md={6}>
          <Form.Group className="mb-3" controlId="formGroupSelectedDate">
            <Form.Label>Selected Start Date:</Form.Label>
            <Form.Control
              type="date"
              placeholder=""
              name="selectedDate"
              value={editSched?.startDate}
              disabled
            />
          </Form.Group>
        </Col>
      </Row>

<Col md={6}>
<Row>

<Col >
<p>Repeat Every : </p>
</Col>
<Col md={2}>
<Form.Control type="text" placeholder="" name='repeatInterval' value={editSched?.repeatInterval} onChange={handleChangeEdit}/>

</Col>

<Col>
<Form.Control type="text" value="Week" placeholder="" disabled />

</Col>
</Row>


<Row className='mt-4'>
    <Col xs={2} sm={1} className="mb-2">
    <button type="button" className="btn btn-outline-primary" data-bs-toggle="button" name='daysOfWeek' value="0" autocomplete="off" onClick={handlecheck1}>S</button>
    </Col>
    <Col xs={2} sm={1} className="mb-2">
    <button type="button" className="btn btn-outline-primary" data-bs-toggle="button" name='daysOfWeek' value="1" autocomplete="off" onClick={handlecheck1}>M</button>
    </Col>
    <Col xs={2} sm={1} className="mb-2">
    <button type="button" className="btn btn-outline-primary" data-bs-toggle="button" name='daysOfWeek' value="2" autocomplete="off" onClick={handlecheck1}>T</button>
    </Col>
    <Col xs={2} sm={1} className="mb-2">
    <button type="button" className="btn btn-outline-primary" data-bs-toggle="button" name='daysOfWeek' value="3" autocomplete="off" onClick={handlecheck1}>W</button>
    </Col>
    <Col xs={2} sm={1} className="mb-2">
    <button type="button" className="btn btn-outline-primary" data-bs-toggle="button" name='daysOfWeek' value="4" autocomplete="off" onClick={handlecheck1}>T</button>
    </Col>
    <Col xs={2} sm={1} className="mb-2">
    <button type="button" className="btn btn-outline-primary" data-bs-toggle="button" name='daysOfWeek' value="5" autocomplete="off" onClick={handlecheck1}>F</button>
    </Col>
    <Col xs={2} sm={1} className="mb-2">
    <button type="button" className="btn btn-outline-primary" data-bs-toggle="button" name='daysOfWeek' value="6" autocomplete="off" onClick={handlecheck1}>S</button>
    </Col>
   
   
</Row>

<Row className='mt-4'>
        <Col md={5}>
        <Form.Group className="mb-3" controlId="formGroupEmail">
        <Form.Label>End Date:</Form.Label>
        <Form.Control type="date" placeholder="" name='repeatScheduleEndDate' value={editSched?.repeatScheduleEndDate} onChange={handleChangeEdit}/>
      </Form.Group>
        </Col>
        </Row>
</Col>

</Row>:""

}




{/* ------------------------------------- */}


{/* ---------------------------------monthly---------------------------- */}
{

    editSched.repeatType=="4"?
    <Row>
    <Col>
    <Row>
        <Col md={12}>
          <Form.Group className="mb-3" controlId="formGroupEmail">
            <Form.Label><strong>When do you want to end the schedule?</strong></Form.Label>
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Col md={6}>
          <Form.Group className="mb-3" controlId="formGroupSelectedDate">
            <Form.Label>Selected Start Date:</Form.Label>
            <Form.Control
              type="date"
              placeholder=""
              name="selectedDate"
              value={editSched?.startDate}
              disabled
            />
          </Form.Group>
        </Col>
      </Row>
    <Row className='mt-4'>

<Col md={5}>
<Row>

<Col >
<p>Repeat Every : </p>
</Col>
<Col md={2}>
<Form.Control type="text" placeholder=""  name='repeatInterval' value={editSched?.repeatInterval} onChange={handleChangeEdit}/>

</Col>

<Col>
<Form.Control type="text" value="Month"  placeholder="" disabled />

</Col>
</Row>

<Row className='mt-4'>
    <Col md={5}>
<Form.Check type="radio" value="" id='emonthDay' label="On day" name='monthly' placeholder="" onChange={handleChangeEdit}/>
    
    </Col>
    <Col md={2}>
<Form.Control type="text" placeholder="" name='dayOfMonth' id='emInt' value={editSched?.dayOfMonth} onChange={handleChangeEdit}/>
    
    </Col>
</Row>

</Col>

</Row>

<Row className='mt-4'>
    <Col md={2}>
<Form.Check type="radio" value="" id='emonthOn' label="On the" name='monthly' placeholder="" onChange={handleChangeEdit}/>
    
    </Col>

    <Col md={3}>
    <Form.Select defaultValue="Choose..." name='weekDayOrdinal' id='emonthOnVals' value={editSched?.weekDayOrdinal} onChange={handleChangeEdit}>
            <option></option>
            <option value="1">First</option>
            <option value="2">Second </option>
            <option value="3">Third</option>
            <option value="4">Fourth</option>
            <option value="5">Last</option>
           
          </Form.Select>
    </Col>
    <Col md={3}>
    <Form.Select defaultValue="Choose......" name='weekDay' id='emonthOnVals1' value={editSched?.weekDay} onChange={handleChangeEdit}>
            <option></option>
            <option value="0">Sunday</option>
            <option value="1">Monday</option>
            <option value="2">Tuesday</option>
            <option value="3">Wednesday</option>
            <option value="4">Thursday</option>
            <option value="5">Friday</option>
            <option value="6">Saturday</option>

          </Form.Select>
    </Col>

</Row>

<Row className='mt-4'>
        <Col md={5}>
        <Form.Group className="mb-3" controlId="formGroupEmail">
        <Form.Label>End Date:</Form.Label>
        <Form.Control type="date" placeholder="" name='repeatScheduleEndDate' value={editSched?.repeatScheduleEndDate} onChange={handleChangeEdit}/>
      </Form.Group>
        </Col>
        </Row>
    </Col>
</Row>:""
}





{/* ------------------------------ */}




{/* ----------------------------yearly---------------------- */}

{
    editSched.repeatType=="5"?
<Row>
    <Col>
    <Row>
        <Col md={12}>
          <Form.Group className="mb-3" controlId="formGroupEmail">
            <Form.Label><strong>When do you want to end the schedule?</strong></Form.Label>
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Col md={6}>
          <Form.Group className="mb-3" controlId="formGroupSelectedDate">
            <Form.Label>Selected Start Date:</Form.Label>
            <Form.Control
              type="date"
              placeholder=""
              name="selectedDate"
              value={editSched?.startDate}
              disabled
            />
          </Form.Group>
        </Col>
      </Row>
    <Row className='mt-4'>

<Col md={5}>
<Row>

<Col >
<p>Repeat Every : </p>
</Col>
<Col md={2}>
<Form.Control type="text" placeholder="" name='repeatInterval' value={editSched?.repeatInterval} onChange={handleChangeEdit}/>

</Col>

<Col>
<Form.Control type="text" value="Year" placeholder="" disabled />

</Col>
</Row>

<Row className='mt-4'>
    <Col md={5}>
<Form.Check type="radio" value="" label="On day" id='eyearDay' name='yearly' placeholder="" onChange={handleChangeEdit}/>
    
    </Col>
    <Col md={2}>
<Form.Control type="text" placeholder="" id='eyInt' name='dayOfMonth' value={editSched?.dayOfMonth} onChange={handleChangeEdit}/>
    
    </Col>
    <Col md={5}>
    <Form.Select defaultValue="Choose......" name='monthRepeatYearly' id="eyInt1" value={editSched?.monthRepeatYearly} onChange={handleChangeEdit}>
            <option></option>
            <option value="1">January</option>
            <option value="2">February</option>
            <option value="3">March</option>
            <option value="4">April</option>
            <option value="5">May</option>
            <option value="6">June</option>
            <option value="7">July</option>
            <option value="8">August</option>
            <option value="9">September</option>
            <option value="10">October</option>
            <option value="11">November</option>
            <option value="12">December</option>
           

          </Form.Select>
    </Col>
</Row>

</Col>

</Row>

<Row className='mt-4'>
    <Col md={2}>
<Form.Check type="radio" value="" id='eyearOn'  label="On the" name='yearly' placeholder="" onChange={handleChangeEdit}/>
    
    </Col>

    <Col md={3}>
    <Form.Select defaultValue="Choose..." name='weekDayOrdinal' id="eyearVals1" value={editSched?.weekDayOrdinal} onChange={handleChangeEdit}>
            <option></option>
            <option value="1">First</option>
            <option value="2">Second </option>
            <option value="3">Third</option>
            <option value="4">Fourth</option>
            <option value="5">Last</option>
           
          </Form.Select>
    </Col>
    <Col md={3}>
    <Form.Select defaultValue="Choose......" name='weekDay' id="eyearVals2" value={editSched?.weekDay} onChange={handleChangeEdit}>
            <option></option>
            <option value="0">Sunday</option>
            <option value="1">Monday</option>
            <option value="2">Tuesday</option>
            <option value="3">Wednesday</option>
            <option value="4">Thursday</option>
            <option value="5">Friday</option>
            <option value="6">Saturday</option>

          </Form.Select>
    </Col>
    <Col md={1}>
    <p className='text-center pt-2 fs-5'>of</p>
</Col>
    <Col md={3}>
    <Form.Select defaultValue="Choose......" name='monthRepeatYearly' id="eyearVals3" value={editSched?.monthRepeatYearly} onChange={handleChangeEdit}>
            <option></option>
            <option value="1">January</option>
            <option value="2">February</option>
            <option value="3">March</option>
            <option value="4">April</option>
            <option value="5">May</option>
            <option value="6">June</option>
            <option value="7">July</option>
            <option value="8">August</option>
            <option value="9">September</option>
            <option value="10">October</option>
            <option value="11">November</option>
            <option value="12">December</option>
           

          </Form.Select>
    </Col>

</Row>

<Row className='mt-4'>
        <Col md={5}>
        <Form.Group className="mb-3" controlId="formGroupEmail">
        <Form.Label>End Date:</Form.Label>
        <Form.Control type="date" placeholder="" name='repeatScheduleEndDate' value={editSched?.repeatScheduleEndDate} onChange={handleChangeEdit}/>
      </Form.Group>
        </Col>
        </Row>
    </Col>
</Row>:""
}




{/* ------------------------------- */}
                    </Form>           
            </Container>
        </Modal.Body>
        <Modal.Footer className='m-auto'>
          <Button variant="" className="back-sched" onClick={()=>{

            handleClose1();
            setEditSched([]);
            console.log(editSched);
          }
          }>
            Cancel
          </Button>
          <Button variant="" className="save-sched" onClick={()=>{
            let update=sessionStorage.getItem("updateId");

            const Url=`https://84ro5mdxeg.execute-api.us-east-2.amazonaws.com/prod/schedule/${update}`;

            let startTime = editSched.startTime;
            let newStartTime = startTime.length === 5 ? startTime + ":00" : startTime;
            console.log(newStartTime);
            
            let endTime = editSched.endTime;
            let newEndTime = endTime.length === 5 ? endTime + ":00" : endTime;
            console.log(newEndTime);
            


            let n={
                ...editSched,
                daysOfWeek:editSched.daysOfWeek.toString(),
                repeatInterval:editSched.repeatInterval===""?0:parseInt(editSched.repeatInterval),
                weekDay:editSched.weekDay===""?0:parseInt(editSched.weekDay),
                weekDayOrdinal:editSched.weekDayOrdinal===""?0:parseInt(editSched.weekDayOrdinal),
                dayOfMonth:editSched.dayOfMonth===""?0:parseInt(editSched.dayOfMonth),
                monthRepeatYearly:editSched.monthRepeatYearly===""?0:parseInt(editSched.monthRepeatYearly),
                repeatType:editSched.repeatType===""?0:parseInt(editSched.repeatType),
                color:editSched.color===""?0:parseInt(editSched.color),
                 startTime:newStartTime,
                endTime:newEndTime,
                deviceID: UID
            }
            
            delete n.yearly;
    
console.log(n);
            fetch(Url,{
                method:"PUT",
             headers:{
                Accept: "application/json",
                'Content-Type': 'application/json'
              },
              body:JSON.stringify(n)
            }).then((res)=>res.json())
            .then((result)=>{
                console.log(result);
                if(result.Status===200){
                    Swal.fire({
                        icon:"success",
                        title:"Updated successfully!",
                        showConfirmButton:false,
                        timer:2000
                    })
                    getSchedule();
                    handleClose1();
                   
                }
            })
          }}>
            Save 
          </Button>
        </Modal.Footer>
      </Modal>


  </>
    )
}

export default Dashboard
