import React,{useState,useEffect} from 'react';
import "../Styles/User/Dashboard.css";
import "../Styles/User/Responsive.css";
import logo from "../images/hpc-logo-alt.svg";
import * as jQuery from "jquery";
import Swal from 'sweetalert2';
import {useNavigate} from "react-router-dom";



const Admin = () => {
    jQuery(document).ready(function ($) {
        console.log("Custom JS loaded!");
    
    if($(".dpopup").length > 0) {
        function closePopup(thisPop) {
            $("html").removeClass("dpopup-on");
            $(thisPop).fadeOut(200);
        }

        function openPopup(thisPop) {
            $("html").addClass("dpopup-on");
            $(thisPop).fadeIn(200);
        }

        //Close or Cancel Button
        $(".dpopup-close").on("click", function(e){
            e.preventDefault();
            closePopup($(this).parents(".dpopup"));
        });
        
        //Close on clicking outside
        $(document).mouseup(function (e){
            var container = $(".dpopup-wrapper");

            if (!container.is(e.target) && container.has(e.target).length === 0 && $("html").hasClass("dpopup-on")) {
                closePopup($(".dpopup:visible"));
            }
        });

        if($(".dpopup-opener").length > 0) {
            $(".dpopup-opener").on("click", function(e){
                e.preventDefault();
                var thisPopup = $(this).attr("data-target");
                openPopup(thisPopup);
            });
        }
    }


    if($(".passeye").length > 0) {
        $(".passeye").on("click", function(e){
            e.preventDefault();

            var targetPassEl = $(this).parents(".input-group").find("input");
            
            if(targetPassEl.attr("type") == "password") {
                targetPassEl.attr("type", "text");
            } else {
                targetPassEl.attr("type", "password");
            }

            $(this).find(".passeye-icon").toggleClass("passeye-hide");

            $(targetPassEl).focus();
        })
    }

});

const [data, setData] = useState({
    userName:"",
    loginID:"",
    password:"",
    WIFISSID: "",
    WIFIPSK: "",
    deviceID: "",
    status:1
})



const handleChange=(e)=>{
    const newdata={...data};
    newdata[e.target.name]=e.target.value;
    setData(newdata);
    console.log(newdata);
}
    

const handleSubmit=(e)=>{
    e.preventDefault();

    const postUrl=`https://fyni8jzfe6.execute-api.us-east-2.amazonaws.com/prod/admin`;

    fetch(postUrl,{
        method:"POST",
          headers:{
            Accept: "application/json",
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(data)
    }).then((res)=>res.json()).then((admin)=>{
        console.log(admin);
        if(admin.Status===200){
            Swal.fire({
                icon:"success",
                title:"Added successfully!"
            })
            window.location.reload();
        }
    })
}






const navigate=useNavigate();



const [searchInput, setSearchInput] = useState("");

const [filterVal, setFilterVal] = useState('');
const [ApiData, setApiData] = useState([]);
const [getAdmin, setGetAdmin] = useState([]);

const getAdUrl=`https://fyni8jzfe6.execute-api.us-east-2.amazonaws.com/prod/admin`;
useEffect(()=>{
fetch(getAdUrl)
.then((res)=>res.json())
.then((result)=>{
    console.log(result.Data);
    setGetAdmin(result.Data);
    setApiData(result.Data);
})
},[])

let adminuid=sessionStorage.getItem("AdminID");
const [Admin, setAdmin] = useState([]);

const adUrl=`https://fyni8jzfe6.execute-api.us-east-2.amazonaws.com/prod/admin/${adminuid}`;


const [editData, setEditData] = useState({
    userName:"",
    loginID:"",
    password:"",
    WIFISSID: "",
    WIFIPSK: "",
    deviceID: "",
    status:1
})


const handleEditChange=(e)=>{
    const editNew={...editData};
    editNew[e.target.name]=e.target.value;
    setEditData(editNew);
    console.log(editNew);
}



// useEffect(()=>{

//         fetch(adUrl)
//         .then((res)=>res.json())
//         .then((adminDet)=>{
//             console.log(adminDet);
//             setAdmin(adminDet?.Data);

           
//             // let n=[adminDet.Data]
//         })
    
// },[])



// let n=Admin;



const handleFilter = (value) => {
        // e.preventDefault();
        if (value === '') {
            setGetAdmin(ApiData)
        } else {
            const Result = ApiData.filter(item => item.userName.includes(value))
            setGetAdmin(Result)
        }
        setFilterVal(value);

    }

  return (
   <>
      <header class="sitehead">
        <div class="container container-hpc">
            <div class="head-row">
                <div class="head-left">
                    <a class="hpc-logo" href="./index.html" title="Hex Pool Cover">
                        <img src={logo} alt="Logo" width="44.8" height="64.7" />
                    </a>
                </div>

                <div class="head-right">
                    <div class="head-alerts">
                        <a href="#sensor-failure" data-target="#sensor-failure" title="Sensor Failure" class="dpopup-opener">
                            <i class="icon-warning"></i>
                        </a>
                    </div>

                    <div class="head-notify">
                        <a class="headbell" href="#notify-open" title="Notifications">
                            <i class="icon-bell"></i>
                            <span class="headbell-count">12</span>
                        </a>

                        <div class="notify">
                            <div class="notify-head">
                                <div class="notif-head--title">You have 4 new notifications</div>

                                <a class="notify-close" href="#close-notifications" title="Close"> <i class="icon-cross"></i> </a>
                            </div>

                            <div class="notify-body">
                                <div class="notify-item">
                                    <i class="icon-info"></i>

                                    <div class="notify-cont">
                                        <div class="notify-text">Please change the pool filter.</div>
                                        <div class="notify-datetime">30 min. ago</div>
                                    </div>

                                    <button type="button" class="notify-item--close"><i class="icon-close-circle"></i></button>
                                </div>
                                <div class="notify-item">
                                    <i class="icon-info"></i>

                                    <div class="notify-cont">
                                        <div class="notify-text">Please change the pool filter.</div>
                                        <div class="notify-datetime">30 min. ago</div>
                                    </div>

                                    <button type="button" class="notify-item--close"><i class="icon-close-circle"></i></button>
                                </div>
                                <div class="notify-item">
                                    <i class="icon-info"></i>

                                    <div class="notify-cont">
                                        <div class="notify-text">Please change the pool filter.</div>
                                        <div class="notify-datetime">30 min. ago</div>
                                    </div>

                                    <button type="button" class="notify-item--close"><i class="icon-close-circle"></i></button>
                                </div>
                                <div class="notify-item">
                                    <i class="icon-info"></i>

                                    <div class="notify-cont">
                                        <div class="notify-text">Please change the pool filter.</div>
                                        <div class="notify-datetime">30 min. ago</div>
                                    </div>

                                    <button type="button" class="notify-item--close"><i class="icon-close-circle"></i></button>
                                </div>
                                <div class="notify-item">
                                    <i class="icon-info"></i>

                                    <div class="notify-cont">
                                        <div class="notify-text">Please change the pool filter.</div>
                                        <div class="notify-datetime">30 min. ago</div>
                                    </div>

                                    <button type="button" class="notify-item--close"><i class="icon-close-circle"></i></button>
                                </div>
                                <div class="notify-item">
                                    <i class="icon-info"></i>

                                    <div class="notify-cont">
                                        <div class="notify-text">Please change the pool filter.</div>
                                        <div class="notify-datetime">30 min. ago</div>
                                    </div>

                                    <button type="button" class="notify-item--close"><i class="icon-close-circle"></i></button>
                                </div>
                                <div class="notify-item">
                                    <i class="icon-info"></i>

                                    <div class="notify-cont">
                                        <div class="notify-text">Please change the pool filter.</div>
                                        <div class="notify-datetime">30 min. ago</div>
                                    </div>

                                    <button type="button" class="notify-item--close"><i class="icon-close-circle"></i></button>
                                </div>
                                <div class="notify-item">
                                    <i class="icon-info"></i>

                                    <div class="notify-cont">
                                        <div class="notify-text">Please change the pool filter.</div>
                                        <div class="notify-datetime">30 min. ago</div>
                                    </div>

                                    <button type="button" class="notify-item--close"><i class="icon-close-circle"></i></button>
                                </div>
                                <div class="notify-item">
                                    <i class="icon-info"></i>

                                    <div class="notify-cont">
                                        <div class="notify-text">Please change the pool filter.</div>
                                        <div class="notify-datetime">30 min. ago</div>
                                    </div>

                                    <button type="button" class="notify-item--close"><i class="icon-close-circle"></i></button>
                                </div>
                                <div class="notify-item">
                                    <i class="icon-info"></i>

                                    <div class="notify-cont">
                                        <div class="notify-text">Please change the pool filter.</div>
                                        <div class="notify-datetime">30 min. ago</div>
                                    </div>

                                    <button type="button" class="notify-item--close"><i class="icon-close-circle"></i></button>
                                </div>
                                <div class="notify-item">
                                    <i class="icon-info"></i>

                                    <div class="notify-cont">
                                        <div class="notify-text">Please change the pool filter.</div>
                                        <div class="notify-datetime">30 min. ago</div>
                                    </div>

                                    <button type="button" class="notify-item--close"><i class="icon-close-circle"></i></button>
                                </div>
                            </div>

                            <div class="notify-footer">
                                <button type="button" class="bttn-link notify-clear">clear all notifications</button>
                            </div>
                        </div>
                    </div>

                    <div class="head-user">
                        <i class="icon-hpc-hex"></i>
                        
                        <ul class="menudrop">
                            <li onClick={()=>{
                                navigate("/")
                                sessionStorage.removeItem("AdminID");
                                sessionStorage.removeItem("delAdmin");
                            }}>
                                <a class="bttn bttn-primary" title="Logout">Logout</a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </header>
    {/* <!-- Header ENDS--> */}

    <main class="main">
        <div class="container">
            <div class="page-head">
                <div class="searchbox">
                    <input type="text" class="input-control input-search" placeholder="search user" value={filterVal} onChange={(e) => setFilterVal(e.target.value)}/>
                    <button type="button" class="bttn bttn-primary" onClick={() => handleFilter(filterVal)}>Search</button>
                </div>

                <a href="#add-user" data-target="#add-user" title="Add New User" class="bttn bttn-primary bttn-header dpopup-opener">
                    Add New User
                </a>
            </div>

            <div class="page-body page-card">
                <div class="table-responsive">
                    <table class="page-table">
                        <tr>
                            <th style={{width: "148px"}}><span>ID</span></th>
                            <th><span>Username</span></th>
                            <th><span>Login ID</span></th>
                            <th><span>Password</span></th>
                            <th><span>Device ID</span></th>
                            <th><span>WIFI Password</span></th>
                            <th><span>WIFI ID</span></th>
                            <th style={{width: "106px"}}><span>Action</span></th>
                        </tr>
{
    // searchInput.length > 1?filteredResults.map((admins) => {
    //         return(
    //             <>
    //              <tr>
    //                         <td><span>{admins?.id}</span></td>
    //                         <td><span>{admins?.userName}</span></td>
    //                         <td><span>{admins?.loginID}</span></td>
    //                         <td><span>{admins?.password}</span></td>
    //                         <td>
    //                             <span>
    //                                 <button data-target="#edit-user" class="openpopup sched-edit dpopup-opener" type="button" onClick={()=>{
    //                                     sessionStorage.setItem("AdminID",admins.id)
    //                                 }}>
    //                                     <i class="icon-edit"></i>
    //                                 </button>

    //                                 <button data-target="#trash-alert" class="openpopup sched-trash dpopup-opener" type="button" onClick={()=>{
    //                                     sessionStorage.setItem("delAdmin",admins?.id)
    //                                 }}>
    //                                     <i class="icon-trash"></i>
    //                                 </button>
    //                             </span>
    //                         </td>
    //                     </tr>
    //             </>
    //         )
    //     }):
    getAdmin?.map((admins,i)=>{
        return(
            <>
              <tr>
                            <td><span>{admins?.id}</span></td>
                            <td><span>{admins?.userName}</span></td>
                            <td><span>{admins?.loginID}</span></td>
                            <td><span>{admins?.password}</span></td>
                            <td><span>{admins?.deviceID?admins?.deviceID:"-"}</span></td>
                            <td><span>{admins?.wifipsk?admins?.wifipsk:"-"}</span></td>
                            <td><span>{admins?.wifissid?admins?.wifissid:"-"}</span></td>
                            <td>
                                <span>
                                    <button data-target="#edit-user" class="openpopup sched-edit dpopup-opener" type="button" onClick={()=>{
                                        sessionStorage.setItem("AdminID",admins?.id)

                                        fetch(`https://fyni8jzfe6.execute-api.us-east-2.amazonaws.com/prod/admin/${admins?.id}`)
                                        .then((res)=>res.json())
                                        .then((result)=>{
                                            console.log(result.Data);

                                            setEditData((pre)=>{
                                                return{
                                                    ...pre,
                                                    userName:result?.Data.userName,
                                                    loginID:result?.Data.loginID,
                                                    password:result?.Data.password,
                                                    deviceID:result?.Data.deviceID,
                                                    wifipsk:result?.Data.wifipsk,
                                                    wifissid:result?.Data.wifissid
                                                }
                                            })

                                        })
                                    }}>
                                        <i class="icon-edit"></i>
                                    </button>

                                    <button data-target="#trash-alert" class="openpopup sched-trash dpopup-opener" type="button"  onClick={()=>{
                                        sessionStorage.setItem("delAdmin",admins?.id)
                                    }}>
                                        <i class="icon-trash"></i>
                                    </button>
                                </span>
                            </td>
                        </tr>
            </>
        )
    })
}
                      

                        {/* <tr>
                            <td><span>2</span></td>
                            <td><span>Hritik</span></td>
                            <td><span>684254</span></td>
                            <td><span>abc</span></td>
                            <td>
                                <span>
                                    <button data-target="#edit-user" class="openpopup sched-edit dpopup-opener" type="button">
                                        <i class="icon-edit"></i>
                                    </button>

                                    <button data-target="#trash-alert" class="openpopup sched-trash dpopup-opener" type="button">
                                        <i class="icon-trash"></i>
                                    </button>
                                </span>
                            </td>
                        </tr> */}
                    </table>
                </div>
            </div>
        </div>
    </main>

    
    {/* <!--Sensor Warning Popup--> */}
    <div id="sensor-failure" class="dpopup dpopup-sensorfail" style={{display: "none"}}>
        <div class="dpopup-wrapper">
            <div class="dpopup-head">
                <button class="dpopup-close bttn-close ml-auto" type="button" role="close"><i class="icon-cross"></i></button>
            </div>
            <div class="dpopup-body text-center">
                <h2 class="sensorfail-title">
                    <i class="icon-sensor"></i>
                    <span class="text--red">Sensor Failure</span>
                </h2>
                
                <div class="sensorfail-cont">
                    <i class="icon-error"></i>
                    <h3>Cover on sensor failed to start</h3>
                </div>
            </div>
            <div class="dpopup-footer"></div>
        </div>
    </div>

    {/* <!--Add User Popup--> */}
    <div id="add-user" class="dpopup dpopup-adduser" style={{display: "none"}}>
        <div class="dpopup-wrapper">
            <div class="dpopup-head">
                <button class="dpopup-close bttn-close ml-auto" type="button" role="close"><i class="icon-cross"></i></button>
            </div>
            <div class="dpopup-body text-center">
                <h2 class="dpopup-title">
                    <div class="dpopup-alerttxt alert-adduser">
                        Add New User
                    </div>

                    <div class="dpopup-alerttxt alert-trashdone alert-useradded" style={{display: "none"}}>
                        <span class="text-center">User added successfully</span>
                        <i class="icon-tick-circle text--green"></i>
                    </div>
                </h2>

                <form name="adduser" onSubmit={handleSubmit}>
                    <div class="userform form-inline">
                        <div class="input-group">
                            <label for="add-username">Username</label>
                            <input required id="add-username" type="text" name="userName" class="input-control" onChange={handleChange}/>
                        </div>
                        <div class="input-group">
                            <label for="add-loginid">Login ID</label>
                            <input required id="add-loginid" type="text" name="loginID" class="input-control" onChange={handleChange}/>
                        </div>
                        <div class="input-group">
                            <label for="add-pass">Password</label>
                            <input required id="add-pass" type="password" name="password" class="input-control" onChange={handleChange}/>
                            <button type="button" class="passeye">
                                <i class="passeye-icon icon-eye"></i>
                                <i class="passeye-icon icon-alt-eye passeye-hide"></i>
                            </button>
                        </div>
                        <div class="input-group">
                                <label for="signup-user">Device ID</label>
                                <input class="input-control" id="signup-deviceid" type="text" name="deviceID" onChange={handleChange}/>
                            </div>
                            <div class="input-group">
                                <label for="signup-user">WIFI ID</label>
                                <input class="input-control" id="signup-wifi_ssid" type="text" name="wifissid" onChange={handleChange}/>
                            </div>
                            <div class="input-group">
                                <label for="signup-user">WIFI Password</label>
                                <input class="input-control" id="signup-wifi_psk" type="password" name="wifipsk" onChange={handleChange}/>
                                <button type="button" class="passeye">
                                    <i class="passeye-icon icon-eye"></i>
                                    <i class="passeye-icon icon-alt-eye passeye-hide"></i>
                                </button>
                            </div>
                    </div>

                    <div class="popup-action">
                        <button type="button" class="bttn bttn-secondary-inverse bttn-md dpopup-close" role="close">Cancel</button>
                        <button type="submit" class="bttn bttn-primary bttn-md bttn-adduser">Add</button>
                    </div>
                </form>
            </div>
        </div>
    </div>

    {/* <!--Edit User Popup--> */}
    <div id="edit-user" class="dpopup dpopup-adduser" style={{display: "none"}}>
        <div class="dpopup-wrapper">
            <div class="dpopup-head">
                <button class="dpopup-close bttn-close ml-auto" type="button" role="close"><i class="icon-cross"></i></button>
            </div>
            <div class="dpopup-body text-center">
                <h2 class="dpopup-title">
                    <div class="dpopup-alerttxt alert-adduser">
                        Edit User Details
                    </div>

                    <div class="dpopup-alerttxt alert-trashdone alert-useradded" style={{display: "none"}}>
                        <span class="text-center">Details edited successfully</span>
                        <i class="icon-tick-circle text--green"></i>
                    </div>
                </h2>

                <form name="edituser">
                    <div class="userform form-inline">
                        <div class="input-group">
                            <label for="edit-username">Username</label>
                            <input required  type="text" name="userName" class="input-control" value={editData?.userName} onChange={(e)=>handleEditChange(e)}/>
                        </div>
                        <div class="input-group">
                            <label for="edit-loginid">Login ID</label>
                            <input required  type="text" name="loginID" class="input-control" value={editData?.loginID} onChange={(e)=>handleEditChange(e)}/>
                        </div>
                        <div class="input-group">
                            <label for="edit-pass">Password</label>
                            <input required  type="password" name="password" class="input-control" value={editData?.password} onChange={(e)=>handleEditChange(e)}/>
                            <button type="button" class="passeye">
                                <i class="passeye-icon icon-eye"></i>
                                <i class="passeye-icon icon-alt-eye passeye-hide"></i>
                            </button>
                        </div>
                        <div class="input-group">
                                <label for="signup-user">Device ID</label>
                                <input class="input-control" id="signup-deviceid" type="text" name="deviceID" value={editData?.deviceID} onChange={(e)=>handleEditChange(e)}/>
                            </div>
                            <div class="input-group">
                                <label for="signup-user">WIFI ID</label>
                                <input class="input-control" id="signup-wifi_ssid" type="text" name="wifissid" value={editData?.wifissid} onChange={(e)=>handleEditChange(e)}/>
                            </div>
                            <div class="input-group">
                                <label for="signup-user">WIFI Password</label>
                                <input class="input-control" id="signup-wifi_psk" type="password" name="wifipsk" value={editData?.wifipsk} onChange={(e)=>handleEditChange(e)}/>
                                <button type="button" class="passeye">
                                    <i class="passeye-icon icon-eye"></i>
                                    <i class="passeye-icon icon-alt-eye passeye-hide"></i>
                                </button>
                            </div>
                    </div>

                    <div class="popup-action">
                        <button type="button" class="bttn bttn-secondary-inverse bttn-md dpopup-close" role="close">Cancel</button>
                        <button type="submit" class="bttn bttn-primary bttn-md bttn-adduser" onClick={(e)=>{
                            e.preventDefault();
                            let id=sessionStorage.getItem("AdminID");
                            const upUrl=`https://fyni8jzfe6.execute-api.us-east-2.amazonaws.com/prod/admin/${id}`;

                            fetch(upUrl,{
                                method:"PUT",
                             headers:{
                                Accept: "application/json",
                                'Content-Type': 'application/json'
                              },
                              body:JSON.stringify(editData)
                            }).then((res)=>res.json())
                            .then((up)=>{
                                console.log(up);
                                if(up.Status===200){
                                    let upid=document.getElementById("edit-user");
                                    upid.style.display="none";
                                    Swal.fire({
                                        icon:"success",
                                        title:"Updated successfully!"
                                    })

                                   
                                }
                            })
                        }}>Save</button>
                    </div>
                </form>
            </div>
        </div>
    </div>

    {/* <!--Delete Successfully Popup--> */}
    <div id="trash-alert" class="dpopup dpopup-schedtrash" style={{display: "none"}}>
        <div class="dpopup-wrapper">
            <div class="dpopup-head">
                <button class="dpopup-close bttn-close ml-auto" type="button" role="close"><i class="icon-cross"></i></button>
            </div>
            <div class="dpopup-body text-center">
                <h2 class="dpopup-title">
                    <div class="dpopup-alerttxt alert-trashwarn">
                        Delete this user?
                    </div>
                    <div class="dpopup-alerttxt alert-trashdone" style={{display: "none"}}>
                        <span class="text-center">User deleted successfully</span>
                        <i class="icon-tick-circle text--green"></i>
                    </div>
                </h2>

                <div class="popup-action">
                    <button class="bttn bttn-secondary-inverse bttn-md dpopup-close" role="close">Cancel</button>
                    <button class="bttn bttn-primary bttn-md dpopup-trash" onClick={()=>{

                        let id=sessionStorage.getItem("delAdmin")
                         const delUrl=`https://fyni8jzfe6.execute-api.us-east-2.amazonaws.com/prod/admin/${id}`;
                                        
                        
                         fetch(delUrl,{
                             method:"DELETE",
                             headers:{
                                Accept: "application/json",
                                'Content-Type': 'application/json'
                              },
                              body:""
                         }).then((res)=>res.json())
                         .then((delRes)=>{
                             console.log(delRes);
                             if(delRes.Status===200){
                                let talert=document.getElementById("trash-alert");

                                talert.style.display="none";
                                Swal.fire({
                                    icon:"success",
                                    title:"Deleted successfully!"
                                })

                                setTimeout(() => {
                                    
                                    window.location.reload();
                                }, 2000);
                             }
                         })
                    }}>Delete</button>
                </div>
            </div>
            <div class="dpopup-footer"></div>
        </div>
    </div>
   </>
  )
}

export default Admin
