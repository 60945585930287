import logo from './logo.svg';
import './App.css';
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Dashboard from './User/Dashboard';
import Admin from './Admin/Admin';
import Login from './User/Login';

function App() {
  return (
   <>
   <Router>
    <Routes>
      <Route path='/' element={<Login/>}/>
      <Route path='/dashboard' element={<Dashboard/>}/>
      <Route path='/admin' element={<Admin/>}/>
    </Routes>
   </Router>
   </>
  );
}

export default App;
